<template>
  <form :class="{ 'was-validated': no_error }" @submit.prevent="handelRegister">
    <div class="mt-4">
      <GlobalInput type="text" label="الاسم بالكامل" v-model="name" />
    </div>

    <div class="mt-4">
      <GlobalInput type="email" label="البريد الإلكتروني" v-model="email" />
    </div>

    <div class="input-box">
      <div class="mt-4">
        <GlobalInput :type="fieldType" label="كلمة المرور" v-model="password" />
      </div>
      <div class="mt-4">
        <GlobalInput
          :type="fieldType"
          label="أعد كتابة كلمة المرور"
          v-model="password_confirmation"
        />
        <div class="position-relative">
          <button @click.prevent="showPassword()" class="show_icon">
            <box-icon v-if="is_show_eye" name="hide"></box-icon>
            <box-icon v-else name="show"></box-icon>
          </button>
        </div>
      </div>
      <!-- <input-password-helper
        :css_class="'mt-4'"
        v-bind:label="' كلمة المرور'"
        @input-data="getPasswordInput"
      /> -->

      <!-- <input-password-helper
        :css_class="'mt-4'"
        v-bind:label="'أعد كتابة كلمة المرور'"
        @input-data="getRepeatPasswordInput"
      /> -->
    </div>
    <display-error-helper v-bind:errors="errors" />
    <button class="btn-block w-100 btn btn-info text-white mt-4" type="submit">
      إنشاء حساب
    </button>
  </form>
</template>

<script>
import DisplayErrorHelper from "@/components/Helper/DisplayErrorHelper";
import { mapActions } from "vuex";

import store from "@/store";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
export default {
  name: "RegisterComponent",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      errors: [],
      no_error: null,
      fieldType: "password",
      is_show_eye: false,
    };
  },
  components: { DisplayErrorHelper, GlobalInput },
  methods: {
    ...mapActions("auth", ["register"]),
    // getPasswordInput(value) {
    //   this.password = value;
    // },
    // getRepeatPasswordInput(value) {
    //   this.password_confirmation = value;
    // },
    showPassword() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
      this.is_show_eye = !this.is_show_eye;
    },
    async handelRegister() {
      this.$Progress.start();
      //start register
      let parameters = {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        full_name: this.name,
      };
      this.errors = [];
      this.no_error = null;
      await this.register(parameters)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === false) {
              if (response.data.status === "validation_error") {
                this.errors = response.data.data.errors;
              } else {
                this.no_error = true;
              }
              if (response.data.status === "already_registered") {
                this.errors = [];
                this.$swal.fire({
                  icon: "error",
                  title: response.data.message,
                });
                this.$root.$emit("switchNavbarSignTab", "login");
              }
            } else if (response.data.success === true) {
              this.$root.$emit("showAuthModel", false);
              this.$root.$emit("switchNavbarSignTab", "login");
              store.commit("auth/UPDATE_USER", response.data.data.user);
              store.commit("auth/SET_TOKEN", response.data.data.token);
              store.commit("auth/SET_IS_LOGIN", true);
            }
          }
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    validate() {
      const error = {};
      this.errors = [];
      if (this.password.trim() === "") {
        error.password = "password error";
      }
      if (this.password_confirmation.trim() === "") {
        error.password_confirmation = "repeat password error";
      }
      if (this.password_confirmation !== this.password) {
        error.password_confirmation = "repeat password and password not equal";
      }
      this.errors = error;

      return this.errors.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.show_icon {
  position: absolute;
  top: -20px;
  right: 40%;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 35px;
  border: 1px solid #aaa;
  color: #fff;
  &:focus {
    outline: none;
  }
}
@media (max-width: 768px) {
  .show_icon {
    top: -15px;
    height: 30px;
  }
}
</style>
