<template>
  <b-card no-body class="table-card">
    <b-table
      id="global-table"
      :tbody-transition-props="transProps"
      primary-key="id"
      striped
      hover
      responsive
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      :items="items"
      :fields="fields"
      :sort-direction="sortDirection"
    >
      <template
        v-for="dataTableSlot in Object.keys($scopedSlots)"
        v-slot:[dataTableSlot]="slotScope"
      >
        <slot :name="dataTableSlot" v-bind="slotScope" />
      </template>

      <template #cell(indexed)="itemObj">
        <b>{{ getRealIndex(itemObj) + 1 }}</b>
      </template>

      <!-- Actions Delete And Edit is Global -->
      <!-- DeleteRecord -->
      <template #cell(options)="itemObj">
        <div class="option">
          <button
            class="btn btn-danger"
            style="font-size: 13px"
            @click="deleteRecord(itemObj)"
          >
            حذف
          </button>
          <button
            v-if="showButton && itemObj.item.id"
            class="btn btn-primary"
            style="font-size: 13px"
            @click="$emit('replayComment', itemObj.item)"
          >
            رد
          </button>
        </div>
      </template>
      <template #cell(dropdownIconToggle)="itemObj">
        <div class="btn-action">
          <slot name="dropdownIcon" :itemObj="itemObj" />
          <!-- <slot name="otherActions" :itemObj="itemObj" /> -->
        </div>
      </template>
      <template #cell(showComment)="itemObj">
        <div class="btn-action">
          <slot name="showComment" :itemObj="itemObj" />
        </div>
      </template>
      <template #cell(endMeeting)="itemObj">
        <slot name="endMeeting" :itemObj="itemObj" />
      </template>
      <!-- Table Loader -->

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>

      <!-- Table Loader -->
    </b-table>
  </b-card>
</template>

<script>
import deleteRequest from "@/api/delete_record";
export default {
  name: "GlobalTable",
  props: {
    fields: {
      type: Array,
      default: () => [] || {},
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    deleteUrl: {
      type: String,
      default: () => "",
    },
    data: {
      type: Array,
      default: () => [],
      required: true,
    },

    isBusy: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      transProps: {
        // Transition name
        name: "flip-list",
      },
      items: [],
      totalRows: 1,
      sortDirection: "asc",
    };
  },
  watch: {
    data() {
      this.getTableItems();
    },
  },
  created() {
    this.getTableItems();
  },
  methods: {
    // List table
    getTableItems() {
      this.items = [...this.data];
    },
    // to display read index not viewd index
    getRealIndex(itemObj) {
      const findRealId = this.items.find((el) => el.id === itemObj.item.id);
      const indexArray = this.items.indexOf(findRealId);
      return indexArray;
    },
    deleteRecord(itemObj) {
      this.$swal
        .fire({
          title: "هل تريد الحذف",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "تأكيد",
          cancelButtonText: "إلغاء",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteRequest
              .deleteRecord(this.deleteUrl, itemObj.item.id)
              .then(() => {
                this.items.splice(this.getRealIndex(itemObj), 1);
              })
              .catch(() => {
                this.$swal.fire({
                  title: "لا يمكن حذف تعليق الاخرين ",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "موافق",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.option {
  display: flex;
  align-items: center;
  gap: 10px;
}
#perPageSelect {
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  cursor: pointer;
}
.dark-theme #perPageSelect {
  background-color: #2f2f2f !important;
  color: #fff !important;
}
.dark-theme .table-card {
  border: 1px solid #2f2f2f;
}
.page-link:focus {
  box-shadow: none !important;
}
</style>
