<template>
  <div>
    <div class="mx-auto col-lg-3 my-5">
      <forget-password-component />
    </div>
  </div>
</template>

<script>
import ForgetPasswordComponent from "@/components/Auth/ForgetPasswordComponent";

export default {
  name: "ForgetPasswordView",
  components: { ForgetPasswordComponent },
};
</script>

<style scoped></style>
