<template>
  <div>
    <div class="mx-auto col-lg-3 my-5">
      <register-component />
    </div>
  </div>
</template>

<script>
import RegisterComponent from "@/components/Auth/RegisterComponent";

export default {
  name: "RegisterView",
  components: { RegisterComponent },
};
</script>

<style scoped></style>
