<template>
  <div>
    <VueModal
      v-model="showModal"
      modalStyle="max-width:400px;min-height: 252px; border-radius:15px"
      modalClass="play_takes_popup"
      :title="showModalTitle"
    >
      <div class="become-instructor">
        <GlobalInput
          type="text"
          label=" نبذة مختصرة"
          v-model="form.description"
        />

        <GlobalInput
          type="text"
          label="رابط السيرة الذاتية"
          v-model="form.certificate"
        />
        <GlobalInput
          type="text"
          label=" رابط الهوية الوطنية"
          v-model="form.identity_scan"
        />
        <GlobalInput
          type="text"
          label="اسم البنك "
          v-model="form.account_type"
        />
        <GlobalInput type="text" label="الآيبان" v-model="form.iban" />
        <!-- <input type="file" @change="handleFileChange" /> -->
        <GlobalInput type="text" label=" السويفت كود" v-model="form.swift" />

        <div class="text-end">
          <button class="btn btn-submit" @click="submitBecomeInstructor">
            تقديم
          </button>
        </div>
      </div>
    </VueModal>
    <button class="btn-become" @click="openBecomeInstructorModal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: #818894"
      >
        <path
          d="M6 22h13a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h1zm6-17.001c1.647 0 3 1.351 3 3C15 9.647 13.647 11 12 11S9 9.647 9 7.999c0-1.649 1.353-3 3-3zM6 17.25c0-2.219 2.705-4.5 6-4.5s6 2.281 6 4.5V18H6v-.75z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueModal from "@kouts/vue-modal";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import instructor_request from "@/api/instractor_request";
export default {
  name: "become-instructor",
  components: {
    VueModal,
    GlobalInput,
  },
  data() {
    return {
      showModal: false,
      showModalTitle: "انضم  كمدرب",
      form: {
        iban: "",
        swift: "",
        identity_scan: "",
        account_type: "",
        certificate: null,
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    openBecomeInstructorModal() {
      this.showModal = true;
    },
    handleFileChange(event) {
      // Access the selected file from the event
      const selectedFile = event.target.files[0];
      this.form.certificate = selectedFile;

      // Perform any additional actions you need
    },
    formData() {
      const fd = new FormData();
      fd.append("iban", this.form.iban);
      fd.append("swift", this.form.swift);
      fd.append("identity_scan", this.form.identity_scan);
      fd.append("account_type", this.form.account_type);
      fd.append("certificate", this.form.certificate);
      fd.append("description", this.form.description);
      fd.append("account_id", this.user.id);
      fd.append("occupations", 522);
      return fd;
    },
    async submitBecomeInstructor() {
      // const payload = {
      //   iban: this.form.iban,
      //   swift: this.form.swift,
      //   identity_scan: this.form.identity_scan,
      //   account_type: this.form.account_type,
      //   certificate: this.form.certificate,
      //   description: this.form.description,
      //   account_id: this.user.id,
      //   // occupations: "[1,2,3]",
      // };
      await instructor_request.becomeInstructor(this.formData()).then((res) => {
        if (res.data.success == true) {
          this.$swal({
            icon: "success",
            title: `${res.data.message}`,
          });
        } else if (res.data.success == false) {
          this.$swal({
            icon: "error",
            title: `${res.data.message}`,
          });
          console.log(res);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-become {
  inset: 0;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.become-instructor {
  padding: 10px;

  .btn-submit {
    background-color: #36037d;
    color: #fff;
    padding: 8px 30px;
    border-radius: 4px;
    margin-top: 20px;
  }
}
</style>
