import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import Sweetalert2 from "vue-sweetalert2";
import { BootstrapVue } from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@kouts/vue-modal/dist/vue-modal.css";
import { FormRatingPlugin } from "bootstrap-vue";
import { VBModal } from "bootstrap-vue";
import VueSocialSharing from "vue-social-sharing";
/*START Coursestore main style*/
import "./assets/css/main.css";
import "./assets/js/main.js";
/*END Coursestore main style ********/
import "boxicons";
import loader from "vue-ui-preloader";
import GlobalTable from "@/components/Helper/GlobalTable.vue";
const VueProgressBarOptions = {
  color: "#000",
  failedColor: "#000",
  thickness: "5px",
  transition: {
    speed: "0.1s",
    opacity: "0.3s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};
Vue.component("v-select", vSelect);
Vue.use(loader);
Vue.use(VueProgressBar, VueProgressBarOptions);
Vue.use(VueSocialSharing);
Vue.use("b-modal", VBModal);
Vue.component("GlobalTable", GlobalTable);
const options = {
  confirmButtonColor: "#1ecad3",
  cancelButtonColor: "#d90b0a",
};
Vue.use(Sweetalert2, options);
Vue.use(BootstrapVue);
Vue.use(FormRatingPlugin);
