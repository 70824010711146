import axios from "axios";

import Auth from "@/helpers/auth_helper";
import store from "@/store";
import Vue from "vue";

export default {
  async getUser(payload) {
    return await axios.post("/panel/user", payload);
  },
  async resetPassword(token, payload) {
    //axios.defaults.headers.common["Content-Type"] = "application/json";
    //axios.defaults.headers.common["Accept"] = "application/json";
    return await axios.post(`/reset-password/${token}`, payload);
  },
  async login(payload) {
    return await axios.post("/login", payload);
  },
  async changePassword(payload) {
    return await axios.post("/change-password", payload);
  },
  async register(payload) {
    return await axios.post("/register/step/web", payload);
  },
  async registrationCourse(payload) {
    return await axios.post("/attendance-register", payload);
  },
  async updateProfile(payload) {
    return await axios.put("/panel/profile-setting", payload);
  },
  async getCountries() {
    return await axios.get("/countries");
  },
  async sendForgetCode(payload) {
    return await axios.post("/forget-password", { email: payload });
  },
  async handelLoginWithFacebook() {
    //axios.defaults.headers.common["Content-Type"] = "application/json";
    return await axios.post("/facebook/", {});
  },
  async handelLoginWithGoogle() {
    //axios.defaults.headers.common["Content-Type"] = "application/json";
    return await axios.post("/google/", {});
  },
  async handelGoogleCallback() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = {
      driver: "google",
      code: urlParams.get("code"),
    };

    return await axios.post("get/driver", data).then(async (response) => {
      return await axios
        .post("/google/callback", response.data.data)
        .then((responses) => {
          this.handelCallBack(responses);
        })
        .finally(() => {
          return false;
        });
    });
  },
  async handelFacebookCallback() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = {
      driver: "facebook",
      code: urlParams.get("code"),
    };
    await axios.post("get/driver", data).then((response) => {
      return axios
        .post("/facebook/callback", response.data.data)
        .then((responses) => {
          this.handelCallBack(responses);
        })
        .finally(() => {
          return false;
        });
    });
  },
  handelCallBack(responses) {
    if (responses.status === 200) {
      if (responses.data.success === true) {
        Vue.swal.fire({
          icon: "success",
          title: responses.data.message,
        });
        Auth.setAUthData(responses.data.data.token);
        return store
          .dispatch("auth/getUser", responses.data.data.token)
          .then(() => {
            return true;
          });
      } else if (responses.data.success === false) {
        Vue.swal.fire({
          icon: "error",
          title: responses.data.message,
        });
        return false;
      }
    } else {
      Vue.swal.fire({
        icon: "error",
        title: responses.data.message,
      });
      return false;
    }
  },
};
