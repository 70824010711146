import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import auth_route from "@/router/auth_route";
// import HomeView from "../views/HomeView.vue";
// import PostView from "@/views/Posts/PostView";
import auth_helper from "@/helpers/auth_helper";
import NotfoundView from "@/views/Errors/NotfoundView";
import InstructorsRoute from "@/router/instructor_route";
import course_route from "@/router/course_route";
import bundle_route from "@/router/bundle-route";
import VerifyView from "@/views/Payment/VerifyView";
import SpacialMarketer from "@/views/SpacialMarketer";
import GlobalMarketer from "@/views/GlobalMarketer";
// import TakesView from "@/views/Takes/TakesView";
import PageView from "@/views/Page/PageView";
// import SearchView from "@/views/Page/SearchView";
import panel_route from "@/router/panel_route";
import ChatComponent from "@/components/Chat/ChatComponent.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "404",
    component: NotfoundView,
  },
  {
    path: "/chat",
    name: "chat",
    component: ChatComponent,
  },
  {
    path: "/verify/*",
    name: "verify",
    component: VerifyView,
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: () => import("../views/Subscription/index.vue"),
  },
  {
    path: "/hassan",
    name: "hassan",
    // component: HomeView,
    component: () => import("../views/HassanComponent.vue"),
  },
  {
    path: "/live/:id/:type",
    name: "live",
    // component: HomeView,
    component: () => import("../views/JitsiLive.vue"),
  },
  {
    path: "/Consultations",
    name: "Consultations",
    component: () => import("@/views/Consultations/ConsultationsViews.vue"),
  },
  {
    path: "/agora-live/:id",
    name: "AgoraLive",
    component: () => import("../views/AgoraLive.vue"),
  },
  {
    path: "/courses-live",
    name: "CoursesLive",
    component: () => import("../views/CoursesLiveView.vue"),
  },
  {
    path: "/takes",
    name: "takes",
    component: () => import("@/views/Takes/TakesView"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/post",
    name: "post",
    component: () => import("@/views/Posts/PostView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/search",
    name: "search",
    // component: SearchView,
    component: () => import("@/views/Page/SearchView"),
    query: { keyword: "" },
  },
  {
    path: "/page/:link",
    name: "page",
    component: PageView,
  },
  {
    path: "/c/:code",
    name: "SpacialMarketer",
    component: SpacialMarketer,
  },
  {
    path: "/g/:code",
    name: "GlobalMarketer",
    component: GlobalMarketer,
  },
];
/*eslint no-unused-vars: ["error", { "vars": "local" }]*/
/*global some_unused_var */

auth_route.forEach((value) => {
  routes.push(value);
});
InstructorsRoute.forEach((value) => {
  routes.push(value);
});

course_route.forEach((value) => {
  routes.push(value);
});
bundle_route.forEach((value) => {
  routes.push(value);
});
panel_route.forEach((value) => {
  routes.push(value);
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  store,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title ?? "كورس ستور";
  store.dispatch("site/getSiteInfo").then(() => {});
  store.commit("auth/SET_TOKEN", auth_helper.getApiToken());
  if (
    to.meta.guest !== undefined &&
    to.meta.guest === true &&
    auth_helper.isLogin() === true
  ) {
    if (to.name !== "home") {
      next({
        path: "home",
        replace: true,
      });
    }
  } else if (
    to.meta.auth !== undefined &&
    to.meta.auth === true &&
    auth_helper.isLogin() === false
  ) {
    router.replace({ name: "login" });
  } else {
    next();
  }
});

export default router;
