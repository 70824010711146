var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-item position-relative dropdown align-items-center d-flex",attrs:{"id":"navbar-categories"}},[_vm._m(0),_c('div',{staticClass:"dropdown-menu mgalat position-absolute w-100 cat-dropdown-menu-main",attrs:{"aria-labelledby":"dropdownMenuButton2"},on:{"click":function($event){$event.stopPropagation();}}},[(Object.keys(_vm.categories).length > 0 && _vm.showCourseCategoryList)?_c('ul',{staticClass:"cat-dropdown-menu",staticStyle:{"overflow":"auto","max-height":"345px"}},_vm._l((_vm.categories),function(category,index){return _c('li',{key:index,staticClass:"py-3"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.showCoursesByCategory(category)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"cat-dropdown-menu-icon mr-10",attrs:{"src":category.icon,"alt":category.title}}),_c('span',{staticClass:"text-grey ms-2"},[_vm._v(" "+_vm._s(category.title)+" ")])])])])}),0):_vm._e(),(!_vm.showCourseCategoryList)?_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.closeSubCourseCategory()}}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center m-2 rounded bg-cs-primary"},[_c('h1',{staticClass:"fs-12 m-2 text-white"},[_vm._v(" "+_vm._s(_vm.selectCategoryTitle)+" ")]),_c('span',[_c('img',{staticClass:"cursor-pointer w-100",attrs:{"src":require("../../assets/icon/cs-exit.svg")},on:{"click":function($event){$event.preventDefault();return _vm.closeSubCourseCategory()}}})])]),_c('div',{staticClass:"text-center"},[_c('small-spring-loading',{attrs:{"is-loading":_vm.isLoading}})],1),(
          _vm.courseCategoryList[this.selectCategoryId] !== undefined &&
          _vm.courseCategoryList[this.selectCategoryId].length > 0
        )?_c('ul',{staticClass:"p-0 my-0 mx-0",staticStyle:{"overflow":"auto","max-height":"300px"}},_vm._l((_vm.courseCategoryList[
            this.selectCategoryId
          ]),function(course,course_index){return _c('li',{key:course_index,staticClass:"rounded bg-white shadow-sm m-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center gap-2"},[_c('router-link',{attrs:{"to":{ name: 'course', params: { slug: course.slug } }}},[_c('div',{staticClass:"image-list rounded",style:({ 'background-image': `url(${course.image})` })})]),_c('router-link',{staticClass:"fw-bold text-start mx-1 text-truncate",attrs:{"to":{ name: 'course', params: { slug: course.slug } }}},[_c('h2',{staticClass:"fs-12"},[_vm._v(" "+_vm._s(_vm._f("truncate")(course.title,15, " ..."))+" ")]),_c('h2',{staticClass:"fs-10"},[_vm._v(" "+_vm._s(course.teacher.full_name)+" ")])])],1),_c('svg',{staticStyle:{"color":"#818894"},attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"}})])])])}),0):_vm._e()]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link-cat fs-sm-12",attrs:{"id":"dropdownMenuButton2","aria-expanded":"false","data-bs-toggle":"dropdown","aria-current":"page","href":"#"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between gap-1",staticStyle:{"display":"inline"}},[_c('img',{staticClass:"img-grid-icon",attrs:{"src":require("../../assets/js/icon-basicui-grid.svg"),"alt":"img-grid-icon"}}),_c('img',{staticClass:"img-grid-icon-dark",attrs:{"src":require("../../assets/js/grid-dark.svg"),"alt":"img-grid-icon"}}),_c('span',{staticClass:"hide-mobile"},[_vm._v("المجالات")]),_c('img',{staticClass:"icon-dwon px-1 hide-mobile",attrs:{"src":require("../../assets/js/icon-arrow-chevron-down.svg"),"alt":"icon-dwon"}})])])
}]

export { render, staticRenderFns }