<template>
  <div
    class="custom-inputs"
    :class="{ 'custom-inputs--active': focusStates || value }"
  >
    <label
      class="custom-inputs__lable"
      :class="{ 'custom-inputs__lable--active': focusStates || value }"
      :for="`input_${_uid}`"
    >
      {{ label }}
    </label>
    <input
      class="custom-inputs__input"
      :id="`input_${_uid}`"
      :name="`input_${_uid}`"
      :type="type"
      :value="value"
      :autocomplete="type"
      @blur="blurHandler"
      @focus="focusHandler"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "custom-inputs",
  props: {
    label: {
      default: "",
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },

  data() {
    return {
      focusStates: false,
      inputValue: this.value,
    };
  },

  computed: {
    checkText() {
      return this.inputValue ? true : false;
    },
  },

  methods: {
    blurHandler() {
      this.focusStates = this.checkText;
    },
    focusHandler() {
      this.focusStates = true;
    },
    handleInput(e) {
      this.inputValue = e.target.value;
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss">
.custom-inputs {
  display: flex;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #eae3e3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.6rem;
  margin-bottom: 1.6rem;
  &--active {
    border-color: #4dbebd;
    color: #4dbebd;
  }

  &__lable {
    position: absolute;
    padding: 0 0.7rem;
    color: #656161 !important;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    right: 1rem;
    font-weight: 500;

    &--active {
      transform: translate(6px, -30px);
      font-size: 0.8rem;
      background-color: #bdbdbd !important;
      border-radius: 5px;
      color: #000 !important;
      @media (max-width: 768px) {
        transform: translate(6px, -20px);
      }
    }
  }

  i {
    margin: 0 1rem;
    color: inherit;
    font-size: 1.4rem;
    color: #ef4650;
    &--active {
      color: #4dbebd;
    }
  }

  &__input {
    border: none;
    font-size: 0.9rem;
    width: 100%;
    &:focus {
      outline: none !important;
    }
  }
}

html:lang(ar) {
  .custom-input {
    &__lable {
      right: 3rem;
      left: auto;
    }
  }
}
</style>
