import { render, staticRenderFns } from "./UserProfilePurchases.vue?vue&type=template&id=21bc0965&scoped=true&"
import script from "./UserProfilePurchases.vue?vue&type=script&lang=js&"
export * from "./UserProfilePurchases.vue?vue&type=script&lang=js&"
import style0 from "./UserProfilePurchases.vue?vue&type=style&index=0&id=21bc0965&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bc0965",
  null
  
)

export default component.exports