export default {
  HttpToken: "YnJvd3Nlcjp1aE45SUNjcU1qcVlyV09PTFluWGFETU8yQkNhNmhReWk=",
  API_URL:
    process.env.NODE_ENV === "production"
      ? "https://coursestore.com/api/development"
      : "https://demo.coursestore.com/api/development",
  VERIFY_URL:
    process.env.NODE_ENV === "production"
      ? "https://coursestore.com/verify/"
      : "https://demo.coursestore.com/verify/",
  USERNAME:
    process.env.NODE_ENV === "production" ? "" : "cs152@coursestore.com",
  PASSWORD: process.env.NODE_ENV === "production" ? "" : "1234567899",
  AGORA_CHAT_APP_KEY: "c2a8b753e9cd404090d75fde4201eb7b",
  AUTH_USERNAME: "browser",
  AUTH_PASSWORD: "uhN9ICcqMjqYrWOOLYnXaDMO2BCa6hQyi",
};
