import jQuery from "jquery";
global.$ = jQuery;
jQuery(document).ready(function () {
  jQuery(".hero-card").hover(
    function () {
      jQuery(".hero-img-1").css("width", "20%");
      jQuery(".hero-img-1 p").css("opacity", "0");
    },
    function () {
      jQuery(".hero-img-1").css("width", "100%");
      jQuery(".hero-img-1 p").css("opacity", "1");
    }
  );
});

/*function changeTheme(elem) {
  let boolval = jQuery(elem).is(":checked");
  changecurrentTheme(boolval);
}*/

let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;

if (dark) {
  changecurrentTheme(true);
  jQuery("nav .checkbox-label:first").trigger("click");
} else {
  changecurrentTheme(false);
}

function changecurrentTheme(boolval) {
  jQuery("footer .logo-footer").attr(
    "src",
    "/assets/images/main/logo-mobile-black.png"
  );
  if (boolval) {
    if (window.matchMedia("(max-width: 768px)").matches) {
      jQuery("footer .logo-footer").attr(
        "src",
        "/assets/images/main/logo-mobile-white.png"
      );
    }
    jQuery("html").addClass("dark-theme");
    jQuery("#free-videos .card .view").css("background", "rgba(0,0,0,0.2)");
    jQuery("#how-to-book .card .view").css("background", "rgba(0,0,0,0.2)");
    jQuery("#how-to-live-course .card .view").css(
      "background",
      "rgba(0,0,0,0.2)"
    );
    jQuery("#before-footer .card .view").css("background", "rgba(0,0,0,0.2)");
    jQuery("nav .checkbox-label .ball").css("transform", "translateX(24px)");
    jQuery("nav .checkbox-label .sun").hide(200); //.addClass('d-none');
    jQuery("nav .checkbox-label .moon").show(200); //.removeClass('d-none');
    jQuery(".cpurple").attr("src", "/assets/images/main/cgrey.png");
    jQuery(".cblue").attr("src", "/assets/images/main/cgrey.png");
  } else {
    jQuery("html").removeClass("dark-theme");
    jQuery("#free-videos .card .view").css("background", "rgba(0,0,0,0.5)");
    jQuery("#how-to-book .card .view").css("background", "rgba(0,0,0,0.5)");
    jQuery("#how-to-live-course .card .view").css(
      "background",
      "rgba(0,0,0,0.5)"
    );
    jQuery("#before-footer .card .view").css("background", "rgba(0,0,0,0.5)");
    jQuery("nav .checkbox-label .ball").css("transform", "translateX(0)");
    jQuery("nav .checkbox-label .moon").hide(200); //.addClass('d-none');
    jQuery("nav .checkbox-label .sun").show(200); //.removeClass('d-none');
    jQuery(".cpurple").attr("src", "/assets/images/main/cpurple.png");
    jQuery(".cblue").attr("src", "/assets/images/main/cblue.png");
  }
}

jQuery(".hover-effect").click(function () {
  jQuery("#promoVideo").modal("toggle");
});

/*function closeSearch() {
  jQuery(".mobile-search").addClass("d-none");
  jQuery(".mobile-nav").removeClass("d-none");
}*/

/*function openSearch() {
  jQuery(".mobile-search").removeClass("d-none");
  jQuery(".mobile-nav").addClass("d-none");
}*/

jQuery(".toggle-notifications").click(function () {
  jQuery(".dropdown-menu.notifications").toggle();
});
jQuery(".toggle-cart").click(function () {
  jQuery(".dropdown-menu.cart").toggle();
});
jQuery(".toggle-mgalat").click(function () {
  jQuery(".dropdown-menu.mgalat").toggle();
});

(function ($) {
  $(document).ready(function () {
    $(".input-range").each(function () {
      var value = $(this).attr("data-slider-value");
      var separator = value.indexOf(",");
      if (separator !== -1) {
        value = value.split(",");
        value.forEach(function (item, i, arr) {
          arr[i] = parseFloat(item);
        });
      } else {
        value = parseFloat(value);
      }
      $(this).slider({
        formatter: function (value) {
          console.log(value);
          return "$" + value;
        },
        min: parseFloat($(this).attr("data-slider-min")),
        max: parseFloat($(this).attr("data-slider-max")),
        range: $(this).attr("data-slider-range"),
        value: value,
        tooltip_split: $(this).attr("data-slider-tooltip_split"),
        tooltip: $(this).attr("data-slider-tooltip"),
      });
    });
  });
})(jQuery);
