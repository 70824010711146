<template>
  <div>
    <VueModal
      v-model="showModal"
      modalStyle="max-width:600px;height: 452px;"
      modalClass="play_takes_popup"
      :title="showModalTitle"
    >
      <iframe
        :src="videoUrl"
        style="border: 0; width: 600px; height: 405px"
        allow="encrypted-media"
        allowfullscreen
      ></iframe>
    </VueModal>
    <div v-show="isLoading" class="container text-center py-4">
      <small-spring-loading :is-loading="isLoading"></small-spring-loading>
    </div>
    <div
      class="my-2"
      v-show="paymentData.status !== null && paymentData.status === true"
      id="success"
    >
      <div class="container mt-5 pt-5 col-12">
        <div
          class="payment-success text-center py-3 my-3 mx-auto rounded shadow-sm position-relative"
        >
          <div
            style="width: 100px; height: 100px; margin-top: -66px !important"
            class="m-auto payment-success rounded-circle border border-5 border-light"
          >
            <img
              src="../../assets/icon/cs-true.svg"
              alt="success"
              style="width: 80px"
            />
          </div>

          <div class="mt-4">
            <h1 class="text-white fw-bold">شكراً</h1>
            <p class="text-white fw-bold">تمت عملية الدفع بنجاح ...</p>
          </div>
        </div>
        <div class="text-center">
          <router-link
            :to="{
              name: 'panel',
            }"
          >
            <button
              type="button"
              class="btn btn-coursestore mx-auto px-4 py-2 text-center"
            >
              لوحة التحكم
            </button>
          </router-link>
          <div
            class="row gap-2 p-sm-2"
            v-show="Object.keys(paymentData.details.webinars).length > 0"
          >
            <div
              class="col-2-4"
              v-for="(item, index) in paymentData.details.webinars"
              :key="index"
            >
              <div v-if="item.order_type === 'meeting'">
                <router-link
                  class="btn btn-coursestore mx-auto px-4 py-2 text-center mx-4 d-inline-block"
                  :to="{
                    name: 'Consultant',
                    query: { tab: 'consultation' },
                  }"
                  >قائمة الإستشارات</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div
          class="row gap-2 p-sm-2"
          v-show="Object.keys(paymentData.details.webinars).length > 0"
        >
          <div
            class="col-2-4"
            v-for="(item, index) in paymentData.details.webinars"
            :key="index"
          >
            <router-link
              v-if="item.order_type === 'webinar'"
              :to="{ name: 'course', params: { slug: item.slug } }"
            >
              <div class="card card-parent p-0 height-270">
                <div class="over"></div>
                <div
                  class="card-image"
                  :style="{ 'background-image': `url('${item.image} ')` }"
                ></div>
                <div class="card-body p-0">
                  <div class="view position-relative h-100">
                    <div class="card--content">
                      <div class="my-4">
                        <div
                          class="rating"
                          v-show="item.rate > 0"
                          style="text-align: right"
                          :style="{ width: `${item.rate}` }"
                        >
                          <p v-if="item.reviews_count > 0">
                            ({{ item.rate | truncate(3) }})
                          </p>
                          <span>★</span>
                          <p>({{ item.reviews_count }})</p>
                        </div>

                        <div class="text-white title text-start">
                          {{ item.title }}
                        </div>
                        <p class="name">
                          {{ item.teacher.full_name }}
                        </p>
                        <div class="text-grey">
                          <duration-component
                            :duration="item.duration"
                          ></duration-component>
                        </div>
                      </div>
                    </div>
                    <div
                      class="position-absolute hover-effect center-element"
                      v-if="item.has_video_demo"
                    >
                      <button
                        class="hover-effect btn p-0"
                        @click.prevent="doShowModal(item)"
                      >
                        <img
                          width="50"
                          style="visibility: visible"
                          src="../../assets/icon/cs-play.svg"
                          class="text-white text-center bg-white rounded bg-opacity-25"
                        />
                      </button>
                    </div>
                    <!-- <img :src="item.image" class="img-fluid" alt="" /> -->
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- <div
            v-for="(item, index) in paymentData.details.webinars"
            :key="index"
            class="col-12 col-lg-4 my-1 position-relative border-radius"
          >
            <router-link :to="{ name: 'course', params: { slug: item.slug } }">
              <div
                class="details position-absolute end-0 start-0 px-4 h-100 img-mask"
              >
                <div
                  class="m-2 d-flex justify-content-end gap-1 align-items-center fs-12"
                >
                  <div class="text-white">{{ item.duration }}</div>
                </div>
                <div class="w-100 text-center my-2">
                  <button
                    @click.prevent="doShowModal(item)"
                    class="hover-effect btn p-0"
                    style="width: 50px"
                  >
                    <img
                      width="40"
                      style="visibility: visible"
                      src="../../assets/icon/cs-play.svg"
                      alt="play"
                      class="text-white text-center bg-white rounded bg-opacity-25"
                    />
                  </button>
                </div>
                <h1 class="fs-12 fw-bold text-white">{{ item.title }}</h1>
                <div class="d-flex justify-content-between align-items-center">
                  <h1 class="fs-12 name">{{ item.teacher.full_name }}</h1>
                  <div class="ms-auto text-grey fs-10">
                    <div class="d-flex">
                      <div class="star">
                        <div
                          class="rating"
                          v-show="item.rate > 0"
                          :style="{ width: `${item.rate}%` }"
                        >
                          <span>★★★★★</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img :src="item.image" class="img-fluid" alt="course store" />
              </div>
            </router-link>
          </div> -->
        </div>
        <div
          class="row gap-2 p-sm-2"
          v-show="Object.keys(paymentData.details.webinars).length > 0"
        >
          <div
            class="col-2-4"
            v-for="(item, index) in paymentData.details.webinars"
            :key="index"
          >
            <div v-if="item.order_type === 'subscribe'">
              <h2>{{ item.title }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="paymentData.status !== null && paymentData.status !== true"
      id="fail"
    >
      <div class="container mt-5 pt-5 col-12">
        <div
          class="payment-success text-center py-3 my-3 mx-auto rounded shadow-sm position-relative"
        >
          <div
            style="width: 100px; height: 100px; margin-top: -66px !important"
            class="m-auto payment-fail rounded-circle border border-5 border-light"
          >
            <img
              src="../../assets/icon/cs-close.svg"
              style="width: 50px; margin-top: 1.2rem"
              alt="fail"
            />
          </div>

          <div class="mt-4">
            <h1 class="text-white fw-bold">فشل !!</h1>
            <p class="text-white fw-bold">
              فشلت عملية الدفع.. يرجى التأكد من صحة البيانات المدخلة أو استخدام
              بوابة الدفع المناسبة
            </p>
          </div>
        </div>
      </div>
      <div
        class="field-payment my-3 d-flex justify-content-between align-items-center"
      >
        <router-link :to="{ name: 'support' }">
          <button class="py-2 px-5 fw-bold rounded w-100">
            <img src="../../assets/icon/cs-support.svg" alt="support" />
            <span class="mx-2">الدعم</span>
          </button>
        </router-link>
        <router-link to="/">
          <button class="py-2 px-5 fw-bold rounded w-100">
            <img src="../../assets/icon/cs-home.svg" alt="home" />
            <span class="mx-2">الرئيسية</span>
          </button>
        </router-link>
        <router-link to="/">
          <button class="py-2 px-5 fw-bold rounded w-100">
            <img src="../../assets/icon/cs-cart-alt.svg" alt="cart" />
            <span class="mx-2">العربة</span>
          </button>
        </router-link>
      </div>
      <div class="container">
        <div
          class="row gap-2 p-sm-2"
          v-show="Object.keys(paymentData.details.webinars).length > 0"
        >
          <div
            class="col-2-4"
            v-for="(item, index) in paymentData.details.webinars"
            :key="index"
          >
            <router-link
              v-if="item.order_type === 'webinar'"
              :to="{ name: 'course', params: { slug: item.slug } }"
            >
              <div class="card card-parent p-0 height-270">
                <div class="over"></div>
                <div
                  class="card-image"
                  :style="{ 'background-image': `url('${item.image} ')` }"
                ></div>
                <div class="card-body p-0">
                  <div class="view position-relative h-100">
                    <div class="card--content">
                      <div class="my-4">
                        <div
                          class="rating"
                          v-show="item.rate > 0"
                          style="text-align: right"
                          :style="{ width: `${item.rate}` }"
                        >
                          <p v-if="item.reviews_count > 0">
                            ({{ item.rate | truncate(3) }})
                          </p>
                          <span>★</span>
                          <p>({{ item.reviews_count }})</p>
                        </div>

                        <div class="text-white title text-start">
                          {{ item.title }}
                        </div>
                        <p class="name">
                          {{ item.teacher.full_name }}
                        </p>
                        <btn-pay-now-component
                          :webinar_id="item.id"
                          :has_bought="item.auth_has_bought"
                        ></btn-pay-now-component>
                        <div class="text-grey">
                          <duration-component
                            :duration="item.duration"
                          ></duration-component>
                        </div>
                      </div>
                    </div>
                    <div
                      class="position-absolute hover-effect center-element"
                      v-if="item.has_video_demo"
                    >
                      <button
                        class="hover-effect btn p-0"
                        @click.prevent="doShowModal(item)"
                      >
                        <img
                          width="50"
                          style="visibility: visible"
                          src="../../assets/icon/cs-play.svg"
                          class="text-white text-center bg-white rounded bg-opacity-25"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cart_request from "@/api/cart_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import course_request from "@/api/course_request";
import VueModal from "@kouts/vue-modal";
import btnPayNowComponent from "@/components/Courses/BtnPayNowComponent";

export default {
  name: "VerifyView",
  components: { SmallSpringLoading, VueModal, btnPayNowComponent },
  data() {
    return {
      isLoading: true,
      paymentData: { status: null, details: { webinars: {} } },
      paymentMessage: null,
      rate: 40,
      showModal: false,
      showModalTitle: "",
      videoUrl: "",
    };
  },
  created() {
    this.handelVerify();
  },
  methods: {
    doShowModal: async function (course) {
      // console.log(take);
      course_request.getPromoVideoById({ id: course.id }).then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            this.showModalTitle = course.title;
            this.showModal = true;
            this.videoUrl =
              "https://player.vdocipher.com/v2/?otp=" +
              response.data.data.otp +
              "&playbackInfo=" +
              response.data.data.playbackInfo +
              "&primaryColor=#410098";
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.data.message,
            });
          }
        }
      });
    },
    async handelVerify() {
      await cart_request
        .verify(this.$route.params.pathMatch, window.location.search)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              this.paymentData = response.data.data;
              this.paymentData.status = response.data.data.status;
            } else if (response.data.success === false) {
              this.paymentMessage = response.data.message;
              this.paymentData.status = response.data.data.status;
            }
          } else {
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .p-sm-2 {
    padding: 8px !important;
  }
}
.details {
  z-index: 22 !important;
  border-radius: 1rem !important;
}
.height-270 {
  border-radius: 5px;
  overflow: hidden;
}
.card-image {
  position: absolute;
  inset: 0;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.over {
  border-radius: 5px;
}
.card--content {
  position: absolute;
  bottom: 20px;
  right: 0;
  z-index: 2;
  padding: 10px;
}
.payment-success {
  background-color: #1ecad3;
}
.payment-fail {
  background-color: #f2320d;
}
.name {
  color: var(--nav-active-1) !important;
}
.img-mask::after {
  content: "";
  position: absolute;
  background: #000;
  opacity: 0.3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin: auto !important;
}
.row > * {
  padding: 0 !important;
}
.border-radius {
  border-radius: 15px !important;
  overflow: hidden;
}
.field-payment {
  margin: 20px auto !important;
  justify-content: space-around !important;
}
.hover-effect {
  z-index: 22;
  position: relative;
}
@media (max-width: 768px) {
  .field-payment {
    flex-direction: column !important;
    gap: 20px;
    a {
      width: 100% !important;
    }
  }
}
</style>
