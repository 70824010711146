<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="pricing-plan">
        <div class="pricing-plan-head">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: #818894"
          >
            <path
              d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"
            ></path>
          </svg>
          <p class="text-light-dark-mode">خطط الأسعار</p>
        </div>
        <div class="chapter-content">
          <div class="input-groups">
            <p class="title">اللغة</p>
            <b-form-select
              v-model="fileGroup.locale"
              :options="lang"
            ></b-form-select>
          </div>

          <div class="mt-3">
            <GlobalInput
              type="text"
              label="العنوان"
              v-model="fileGroup.title"
            />
          </div>
          <div class="mt-3">
            <GlobalInput
              type="text"
              label="عنوان قصير"
              v-model="fileGroup.sub_title"
            />
          </div>
          <div class="mt-3">
            <GlobalInput
              type="number"
              label=" capacity "
              v-model="fileGroup.capacity"
            />
          </div>
          <div class="mt-3">
            <GlobalInput
              type="number"
              label=" الخصم "
              v-model="fileGroup.discount"
            />
          </div>

          <div class="row">
            <div class="col-md-6">
              <p>تاريخ البدء</p>
              <v-date-picker
                v-model="fileGroup.start_date"
                mode="date"
                :format="startDateFormat"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-6">
              <p>تاريخ الإنتهاء</p>
              <v-date-picker
                v-model="fileGroup.end_date"
                mode="date"
                :format="endDateFormat"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center gap-3">
          <b-button
            class="btn btn-coursestore-primary mt-4"
            @click="addPricePlan()"
            >حفظ
            <small-spring-loading
              :is-loading="loadingStore"
            ></small-spring-loading>
          </b-button>
          <!-- <b-button class="btn btn-coursestore-primary mt-4" @click="updateFaqs()"
              >تعديل
              <small-spring-loading
                :is-loading="loadingUpdateStore"
              ></small-spring-loading>
            </b-button> -->
          <!-- <b-button class="btn btn-coursestore-primary mt-4" @click="deleteFaqs()"
              >حذف
              <small-spring-loading
                :is-loading="loadingDeleteStore"
              ></small-spring-loading>
            </b-button> -->
        </div>
      </div>
    </div>
    <div class="price-plan" v-if="Object.keys(pricePlans).length > 0">
      <div v-for="(planPriceItem, index) in pricePlans" :key="index">
        <div
          v-for="(price, index) in planPriceItem"
          :key="index"
          class="plan-box mb-4"
        >
          <div>
            <p class="plan-head">{{ price.title }}</p>
            <span class="plan-date">{{ convertDate(price.created_at) }}</span>
          </div>
          <div class="end-and-start">
            <span>تاريخ البدء:{{ convertDate(price.start_date) }}</span> -
            <span>تاريخ الأنتهاء:{{ convertDate(price.end_date) }}</span>
          </div>
          <div class="delete-plan">
            <button
              style="background-color: transparent; box-shadow: none"
              @click="deletePlan(price.id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="fill: #818894"
              >
                <path
                  d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                ></path>
                <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import upload_courses_request from "@/api/upload_courses_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
// import Vue from "vue";
export default {
  components: {
    GlobalInput,
    SmallSpringLoading,
  },
  props: {
    chapterId: { type: [Number, null] },
  },
  name: "pricing-plan",
  data() {
    return {
      lang: ["ar"],

      fileGroup: {
        locale: "",
        title: "",
        sub_title: "",
        capacity: 0,
        discount: 0,
        start_date: new Date(),
        end_date: new Date(),
      },
      webinar_id: this.$route.query.webinar_id,
      loadingPricing: false,
      loadingStore: false,
      loadingUpdateStore: false,
      loadingDeleteStore: false,
      pricePlanId: Number,
      pricePlans: [],
    };
  },
  computed: {
    startDateFormat() {
      const year = this.fileGroup.start_date.getFullYear();
      const month = String(this.fileGroup.start_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(this.fileGroup.start_date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    endDateFormat() {
      const year = this.fileGroup.end_date.getFullYear();
      const month = String(this.fileGroup.end_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(this.fileGroup.end_date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },

  mounted() {
    this.getAllPricingPlan()
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          this.pricePlans = res.data.data;
        }
      })
      .finally(() => {
        this.loadingPricing = false;
      });
  },
  methods: {
    convertDate(createdAt) {
      var date = new Date(createdAt * 1000);
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, "0");
      var day = String(date.getDate()).padStart(2, "0");

      // Format as "YYYY-MM-DD"
      var formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    formData() {
      const formData = new FormData();
      formData.append("webinar_id", this.webinar_id);

      formData.append("locale", this.fileGroup.locale);
      formData.append("title", this.fileGroup.title);
      formData.append("sub_title", this.fileGroup.sub_title);
      formData.append("start_date", this.startDateFormat);
      formData.append("end_date", this.endDateFormat);
      formData.append("discount", this.fileGroup.discount);
      formData.append("capacity", this.fileGroup.capacity);

      return formData;
    },
    async getAllPricingPlan() {
      this.loadingPricing = true;
      if (this.webinar_id) {
        return await upload_courses_request.getAllPricingPlan(this.webinar_id);
      }
      this.loadingPricing = false;
    },
    async addPricePlan() {
      this.loadingStore = true;
      await upload_courses_request
        .storePricePlans(this.formData())
        .then((res) => {
          if (res.status === 200 && res.data.success === true) {
            this.pricePlans.push(res.data.data);

            this.$swal({
              icon: "success",
              title: `${res.data.message}`,
            });
          }
          // location.reload();
        });
      this.loadingStore = false;
    },

    // async updateFaqs() {
    //   if (this.pricePlanId) {
    //     this.loadingUpdateStore = true;
    //     await upload_courses_request
    //       .updateFaqs(this.pricePlanId, this.formData())
    //       .then((res) => {
    //         this.$swal({
    //           icon: "success",
    //           title: `${res.data.message}`,
    //         });
    //       })
    //       .catch((error) => {
    //         this.$swal({
    //           icon: "error",
    //           title: "خطاء فى التعديل",
    //         });
    //       });
    //     this.loadingUpdateStore = false;
    //   } else {
    //     this.$swal({
    //       icon: "error",
    //       title: "يرجى اضافة درس نصي",
    //     });
    //   }
    // },

    async deletePlan(id) {
      if (this.pricePlanId) {
        this.loadingDeleteStore = true;
        await upload_courses_request.deletePlan(id).then(() => {
          this.$swal({
            title: "  تم الحذف",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
      }
      this.loadingDeleteStore = false;
    },
  },
  watch: {
    pricePlanId(newPricePlanId) {
      if (newPricePlanId) {
        // this.fetchPricePlanDetails(newPricePlanId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-plan {
  background-color: #fff;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  .pricing-plan-head {
    display: flex;
    gap: 5px;
    padding: 5px;
    p {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }
  }
  .chapter-content {
    width: 100%;
    input,
    select,
    b-textarea {
      width: 100%;
      border: 1px solid #ddd;
      padding: 5px;
      outline: none;
      border-radius: 4px;
    }
  }
  .title {
    display: block;
  }

  // radio button
  .button-checkbox .button-label {
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    padding: 5px 13px !important;
    cursor: pointer;
  }
  .button-checkbox input:checked + .button-label {
    background-color: #1ecad3;
    color: #fff;
  }

  .button-checkbox input:not(:checked) + .button-label {
    background-color: #fff;
    color: #000;
  }
}
.plan-box {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
.plan-head {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
}
.plan-date {
  font-size: 11px;
  color: #818894;
}
.end-and-start {
  font-size: 14px;
  color: #818894;
}
</style>
