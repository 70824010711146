<template>
  <div>
    <div
      class="d-sm-block d-md-none text-center mb-3 container"
      v-if="device === 'web'"
    >
      <p class="footer-grey fs-6 pt-2">FOLLOW US</p>

      <div class="d-flex justify-content-center mx-5 social-link-footer">
        <div class="me-3" v-for="(so, index) in social" :key="index">
          <a :href="so.link" target="_blank">
            <img :src="getImageUrl(index)" :alt="so.title" />
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="device === 'mobile'"
      class="justify-content-between mx-2 mx-md-0 d-none d-lg-flex"
    >
      <div class="me-3" v-for="(so, index) in social" :key="index">
        <a :href="so.link" target="_blank">
          <img :src="getImageUrl(index)" :alt="so.title" />
        </a>
      </div>
      <!-- <span class="footer-grey fs-6">FOLLOW US</span> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../config";

export default {
  name: "SocialMediaComponent",
  props: {
    device: String,
  },
  computed: {
    config() {
      return config;
    },
    ...mapGetters("site", ["social"]),
  },
  methods: {
    ...mapActions("site", ["getSiteInfo"]),
    getImageUrl(path) {
      return require(`../../assets/icon/cs-${path.toLowerCase()}.svg`);
      // return require(`../../assets/icon/cs-${path.toLowerCase()}.svg`);
    },
  },
  created() {
    if (this.social === undefined || this.social === "") {
      this.getSiteInfo();
    }
  },
  // ../../assets/icon/cs-${path.toLowerCase()}.svg
};
</script>

<style scoped></style>
