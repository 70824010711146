<template>
  <div class="scroll-table-mobile mb-5">
    <small-spring-loading :is-loading="Loading"></small-spring-loading>

    <div class="tabs-breadcrumb d-flex align-items-center align-content-center">
      <div
        class="breadcrumb-item me-0 me-md-5 ms-0 py-3 d-flex align-items-center align-content-center"
      >
        <a
          href="#"
          class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
        >
          <h6 class="head fs-5 text-light-dark-mode mb-0">كورساتى</h6>
        </a>
        <img
          src="../../assets/icon/arrow-left.svg"
          alt="arrow-left"
          class="mx-2"
        />
      </div>

      <div
        class="profile-tab flex-fill d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-content-center gap-4">
          <a
            v-if="this.user !== null && user.role_name === 'teacher'"
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myActivates' }"
            @click.prevent="activeTabe = 'myActivates'"
          >
            كورساتى
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{
              active:
                activeTabe === 'myCourses' ||
                (user.role_name !== 'teacher' && activeTabe === 'myCourses'),
            }"
            @click.prevent="activeTabe = 'myCourses'"
          >
            مشترياتى
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myComments' }"
            @click.prevent="activeTabe = 'myComments'"
          >
            تعليقاتى
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myCommentsOnCourse' }"
            @click.prevent="activeTabe = 'myCommentsOnCourse'"
            v-if="user.role_name === 'teacher'"
          >
            تعليقات كورسي
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'favCourses' }"
            @click.prevent="activeTabe = 'favCourses'"
          >
            المفضلة
          </a>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div v-if="activeTabe === 'myActivates' && user.role_name === 'teacher'">
        <div class="box">
          <div class="row">
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center gap-2">
                <div>
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>
                    {{ user.status.courses }}
                  </span>
                  <p>الكورسات</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center gap-2">
                <img src="../../assets/icon-profile.svg" alt="" />
                <div class="box_info d-flex flex-column">
                  <span>{{ user.status.followers }}</span>
                  <p>المتابعون</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center gap-2">
                <img src="../../assets/icon-profile.svg" alt="" />
                <div class="box_info d-flex flex-column">
                  <span>{{ user.status.following }}</span>
                  <p>متابع</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <small-spring-loading :is-loading="isLoadData"></small-spring-loading>
          <div
            v-for="(instructor, index) in instructor_details.webinars"
            :key="index"
            class="mt-4"
          >
            <div class="instructor-box">
              <div class="instructor-details d-flex gap-3">
                <router-link
                  :to="{ name: 'course', params: { slug: instructor.slug } }"
                >
                  <div class="instructor-avatar">
                    <img :src="instructor.image" alt="instructor" />
                  </div>
                </router-link>
                <div class="webinar-info">
                  <h3 class="webinar-title">{{ instructor.title }}</h3>
                  <p class="webinar-rate">{{ instructor.students_count }}</p>
                  <p class="webinar-price">{{ instructor.price_string }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          activeTabe === 'myCourses' ||
          (user.role_name !== 'teacher' && activeTabe === 'myActivates')
        "
      >
        <p class="text-light-dark-mode">مشترياتى</p>
        <table class="table">
          <tbody>
            <tr
              v-for="(purchase, index) in all_purchases"
              :key="index"
              class="cart card-course rounded-3 align-middle"
            >
              <td>
                <div
                  class="d-flex justify-content-start gap-3 align-items-center"
                >
                  <div>
                    <router-link
                      class="shamel-bold"
                      :to="{ name: 'course', params: { slug: purchase.slug } }"
                      ><img width="90" alt="purchase" :src="purchase.image" />
                    </router-link>
                  </div>
                  <div class="align-items-center text-light-dark-mode">
                    <h6 class="">
                      <router-link
                        class="text-decoration-none font-size-12"
                        :to="{
                          name: 'course',
                          params: { slug: purchase.slug },
                        }"
                      >
                        {{ purchase.title }}
                      </router-link>
                    </h6>
                    <div class="price small fw-bold text-gray-dark-mode">
                      <span>{{ purchase.price_string }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <h6 class="fs-12 text-light-dark-mode font-size-12">
                  المجالات
                </h6>
                <p class="text-muted fs-10">{{ purchase.category.title }}</p>
              </td>
              <td>
                <h6 class="fs-12 text-light-dark-mode">المدة</h6>
                <p class="text-muted fs-10 duration-format">
                  {{ purchase.duration | formatDurations }}
                </p>
              </td>

              <td>
                <h6 class="fs-12 text-light-dark-mode">المدرب</h6>
                <p class="text-muted fs-10">{{ purchase.teacher.full_name }}</p>
              </td>
              <td>
                <h6 class="fs-12 text-light-dark-mode">تاريخ الشراء</h6>
                <p class="text-muted fs-10">{{ purchase.purchased_at }}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          v-if="hasMoreData"
          @click="loadMorePurchase()"
          class="btn btn-coursestore mx-auto d-block mt-3"
        >
          المزيد
        </button>
      </div>
      <div v-if="activeTabe === 'myComments'" class="mb-4">
        <p class="text-light-dark-mode">فرز التعليقات</p>
        <form action="#">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <label for="datepicker-sm">من</label>
              <b-form-datepicker
                v-model="form.from"
                id="datepicker-sm"
                size="sm"
                locale="ar"
                class="mb-2"
              ></b-form-datepicker>
            </div>
            <div class="col-lg-3">
              <label for="">الى</label>
              <div class="border-1">
                <b-form-datepicker
                  v-model="form.to"
                  size="sm"
                  locale="ar"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="">كورس</label>
                <input v-model="form.webinar" type="text" class="shadow-none" />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <button class="btn btn-blue" @click="getMyComments()">
                  عرض النتائج
                </button>
              </div>
            </div>
          </div>
        </form>
        <GlobalTable
          :data.sync="myComments"
          :fields="myfields"
          delete-url="/panel/comments"
          :show-button="false"
        >
          <template #cell(user)="data">
            <img
              :src="data.item.user.avatar"
              class="user-avatar"
              alt="avatar"
            />
            <span>{{ data.item.user.full_name }}</span>
          </template>
          <template v-slot:showComment="objItem">
            <button
              class="btn btn-danger"
              @click="showOriginalProduct(objItem.itemObj.item.comment)"
            >
              معاينة
            </button>
          </template>
        </GlobalTable>
        <VueModal
          v-model="showCommentModal"
          modalStyle="max-width:550px;min-height: 50px; padding: 20px; border-radius: 10px"
          modalClass="play_course_popup d"
          title="التعليق "
        >
          {{ commentPath }}
        </VueModal>
        <div class="text-center mt-4" v-if="!myComments">
          <p class="text-light-dark-mode">لم تقم بأي تعليق!</p>
          <span class="text-light-dark-mode"
            >تعليقك سيساعدك على تحسين جودة التدرب</span
          >
        </div>
      </div>
      <div v-if="activeTabe === 'myCommentsOnCourse'">
        <p class="text-light-dark-mode">تعليقات الكورس</p>
        <GlobalTable
          :data.sync="classComment"
          :fields="classCommentFields"
          delete-url="/panel/comments"
          @replayComment="replayComment"
        >
          <template #cell(user)="data">
            <img
              :src="data.item.user.avatar"
              class="user-avatar"
              alt="avatar"
            />
            <span>{{ data.item.user.full_name }}</span>
          </template>
          <template v-slot:showComment="objItem">
            <button
              class="btn btn-danger"
              @click="showOriginalProduct(objItem.itemObj.item.comment)"
            >
              معاينة
            </button>
          </template>
          <!-- <template v-slot:dropdownIcon="">
            <img src="@/assets/dots.png" class="img-dots" alt="" />
          </template> -->
        </GlobalTable>
        <div>
          <button
            :disabled="disable_load_more"
            v-if="showLoadMoreButton"
            class="btn btn-coursestore mx-auto d-block mt-3"
            @click.prevent="loadMoreComments"
          >
            <span class="text-white">المزيد</span>
          </button>
        </div>
        <!-- preview Comment modal -->
        <VueModal
          v-model="showCommentModal"
          modalStyle="max-width:550px;min-height: 50px; padding: 20px; border-radius: 10px"
          modalClass="play_course_popup d"
          title="التعليق "
        >
          {{ commentPath }}
        </VueModal>
        <!-- preview files to replay modal -->
        <VueModal
          v-model="showReplayModal"
          modalStyle="max-width:550px;min-height: 50px; padding: 20px; border-radius: 10px"
          modalClass="play_course_popup d"
          title="الرد على التعليق "
        >
          <p class="comment-user">
            {{ myClassComment }}
          </p>

          <form @submit.prevent="submitForm">
            <label for="UserReplay" class="d-block mb-2">رد على التعليق</label>
            <textarea
              v-model="replay"
              name=""
              id="UserReplay"
              cols="20"
              rows="4"
            ></textarea>
            <button type="submit" class="btn btn-primary">حفظ</button>
          </form>
        </VueModal>

        <div class="text-center mt-4" v-if="!classComment">
          <p class="text-light-dark-mode">لم تقم بأي تعليق!</p>
          <span class="text-light-dark-mode"
            >تعليقك سيساعدك على تحسين جودة التدرب</span
          >
        </div>
      </div>
      <div v-if="activeTabe === 'favCourses'">
        <p class="text-light-dark-mode">الكورسات المفضلة</p>

        <table class="table">
          <tbody>
            <tr
              v-for="(favorite, index) in all_favorites"
              :key="index"
              class="cart shadow-sm rounded-3 align-middle"
            >
              <td>
                <div
                  class="d-flex justify-content-start gap-3 align-items-center"
                >
                  <div>
                    <router-link
                      class="shamel-bold"
                      :to="{
                        name: 'course',
                        params: { slug: favorite.webinar.slug },
                      }"
                      ><img
                        width="90"
                        alt="image"
                        :src="favorite.webinar.image"
                      />
                    </router-link>
                  </div>
                  <div class="align-items-center">
                    <h6 class="text-light-dark-mode">
                      <router-link
                        class="text-decoration-none font-size-12"
                        :to="{
                          name: 'course',
                          params: { id: favorite.webinar.id },
                        }"
                      >
                        {{ favorite.webinar.title }}
                      </router-link>
                    </h6>
                    <div class="price small fw-bold text-gray-dark-mode">
                      <span>{{ favorite.webinar.price_string }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <h6 class="fs-12 text-light-dark-mode">المجالات</h6>
                <p class="fs-10 text-gray-dark-mode">
                  {{ favorite.webinar.category.title }}
                </p>
              </td>
              <td>
                <h6 class="fs-12 text-light-dark-mode">المدة</h6>
                <p class="fs-10 text-gray-dark-mode">
                  {{ favorite.webinar.duration | formatDurations }}
                </p>
              </td>

              <td>
                <h6 class="fs-12 text-light-dark-mode">المدرب</h6>
                <p class="fs-10 text-gray-dark-mode">
                  {{ favorite.webinar.teacher.full_name }}
                </p>
              </td>
              <td>
                <h6 class="fs-12 text-light-dark-mode">تاريخ الشراء</h6>
                <p class="text-gray-dark-mode fs-10">
                  {{ favorite.webinar.purchased_at }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="my-3">
          <button
            :disabled="disable_load_more"
            v-if="load_more_fav"
            class="btn btn-coursestore mx-auto d-block"
            @click.prevent="getFavorites({ limit: 10 })"
          >
            <span class="text-white">المزيد</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import filters from "@/helpers/filters_helper";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import axios from "axios";
import VueModal from "@kouts/vue-modal";

import webinars_request from "@/api/webinars_request";
export default {
  name: "UserProfilePurchases",
  components: { SmallSpringLoading, VueModal },
  data() {
    return {
      showReplayModal: false,
      Loading: false,
      showLoadMore: true,
      disable_load_more: false,
      activeTabe: "myActivates",
      lastDate: "",
      firstDate: "",
      classComment: [],
      myComments: {},
      showCommentModal: false,
      commentPath: "",
      myClassComment: "",
      commentId: "",
      replay: "",
      newOffset: 1,
      limit: 10,
      classCommentFields: [
        {
          key: "indexed",
          label: "#",
          sortable: true,
        },
        {
          key: "user",
          label: "مشترك",
          sortable: true,
        },
        {
          key: "webinar.title",
          label: "كورس",
          sortable: true,
        },
        {
          key: "showComment",
          label: "تعليق",
          sortable: true,
        },
        {
          key: "status",
          label: "الحالة",
          sortable: true,
        },
        {
          key: "create_at",
          label: "التاريخ",
          sortable: true,
        },
        {
          key: "options",
          label: "اختيارات",
          sortable: true,
        },
      ],
      myfields: [
        {
          key: "indexed",
          label: "#",
          sortable: true,
        },
        {
          key: "user",
          label: "مشترك",
          sortable: true,
        },
        {
          key: "webinar.title",
          label: "كورس",
          sortable: true,
        },
        {
          key: "showComment",
          label: "تعليق",
          sortable: true,
        },
        {
          key: "status",
          label: "الحالة",
          sortable: true,
        },
        {
          key: "create_at",
          label: "التاريخ",
          sortable: true,
        },
        {
          key: "options",
          label: "اختيارات",
          sortable: true,
        },
      ],
      form: {
        from: "",
        to: "",
        webinar: null,
        new_comments: "yes",
        user: null,
      },
      all_purchases: [],
      hasMoreData: true,
    };
  },
  computed: {
    ...mapGetters("webinars", ["offset", "load_more_fav", "all_favorites"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("instructors", ["instructor_details", "isLoadData"]),
    showLoadMoreButton() {
      return (
        this.myComments.length > 0 && this.myComments.length % this.limit === 0
      );
    },
  },
  methods: {
    ...mapActions("instructors", ["GetInstructor"]),
    // ...mapActions("webinars", ["getPurchases"]),
    ...mapActions("webinars", ["getFavorites"]),
    ...mapActions("auth", ["getUser"]),
    showOriginalProduct(comment) {
      this.commentPath = comment;
      this.showCommentModal = true;
    },
    replayComment(val) {
      this.myClassComment = val.comment;
      this.commentId = val.id;
      this.showReplayModal = true;
    },
    async commentReplay() {
      const payload = {
        reply: this.replay,
      };
      this.Loading = true;
      await axios
        .post(`/panel/comments/${this.commentId}/reply`, payload)
        .then((res) => {
          this.Loading = false;
          this.showReplayModal = false;
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.replay = "";
        });
    },
    submitForm() {
      this.commentReplay();
    },
    async getMyComments() {
      const payload = {
        from: this.from,
        to: this.to,
        limit: this.limit,
        offset: this.newOffset,
      };
      await axios.get("/panel/comments", { params: payload }).then((res) => {
        this.Loading = true;
        this.classComment = res.data.data.class_comment;
        this.myComments = res.data.data.my_comment.webinar;
        this.newOffset += this.limit;
        this.Loading = false;
      });
    },
    loadMoreComments() {
      this.getMyComments();
    },

    async getAllPurchase() {
      this.pageNumber = 0;
      await webinars_request.getPurchases().then((res) => {
        this.all_purchases = res.data.data.webinars;
      });
    },
    loadMorePurchase() {
      this.pageNumber++; // Increment the page number
      webinars_request.getPurchases(5, this.pageNumber).then((res) => {
        const newPurchases = res.data.data.webinars;
        if (newPurchases.length <= 0) {
          this.hasMoreData = false; // Hide the loadMore button
        }
        this.all_purchases = this.all_purchases.concat(newPurchases);
      });
    },
  },
  created() {
    this.getMyComments();
    this.getUser().then(() => {
      this.GetInstructor(this.user.id);
    });
    if (this.all_purchases.length <= 0) {
      this.Loading = true;
      this.getAllPurchase({ limit: 10 }).finally(() => {
        this.Loading = false;
        this.disable_load_more = false;
      });
    }
    // this.getAllPurchase({ limit: 20 });
    if (this.all_favorites.length <= 0) {
      this.Loading = true;
      this.getFavorites({ limit: 10 }).finally(() => {
        this.Loading = false;
        this.disable_load_more = false;
      });
    }
  },
  filters: {
    formatDurations(value) {
      return filters.formatDurations(value);
    },
  },
};
</script>

<style scoped lang="scss">
form {
  padding: 25px 10px;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: var(--bs-border-radius) !important;
}
.dark-theme form {
  box-shadow: rgb(183 180 180 / 31%) 0 5px 15px 0;
}
.btn-blue {
  background: var(--unnamed-color-410099) 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  width: 174px;
  height: 40px;
  margin-top: 19px;
}
.btn-blue:hover {
  background-color: #36037d;
  color: #fff;
}
.b-form-datepicker {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #ccc !important;
}
#datepicker-sm {
  box-shadow: none !important;
}
input {
  width: 100%;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.45rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  outline: none !important;
}
label {
  color: #36037d;
  font-weight: bold;
  font-size: 12px;
}
.dark-theme label {
  color: #fff;
}
.dark-theme .box_info span {
  color: gray;
}
.dark-theme .box_info p {
  color: #fff;
}
.dark-theme .table .cart {
  background-color: #363636;
  border-bottom: 0;
  line-height: 20px;
}
.dark-theme .title-content-tab {
  color: #707070;
}
.box {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: 8px;
  padding: 30px;
}

.dark-theme .box {
  background-color: #1c1c1c;
}
.box_info span,
.box_info p {
  font-size: 16px;
  font-weight: bold;
  color: #36037d;
}
.dark-theme .box_info span {
  color: gray;
}
.dark-theme .box_info p {
  color: #fff;
}
@media (max-width: 768px) {
  .box {
    padding: 10px;
  }
  .box-result {
    margin-bottom: 10px;
  }
  .box_info span,
  .box_info p {
    font-size: 12px;
  }
}
.instructor-box {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  .instructor-avatar {
    width: 250px;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 4px;
    }
  }
}
.dark-theme .instructor-box {
  background-color: #363636;
  color: #fff;
  .webinar-price {
    color: #fff;
  }
}
.webinar-info {
  flex-direction: column;
  .webinar-title {
    font-size: 14px;
  }
  .webinar-rate {
    background-color: #36037d;
    color: #fff;
    padding: 5px;
    display: inline-block;
    border-radius: 10px;
    font-size: 12px;
  }
  .webinar-price {
    color: #36037d;
    font-size: 14px;
    font-weight: bold;
  }
}
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
}
.img-dots {
  width: 15px;
  height: 15px;
}
.comment-user {
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 8px;
}

form {
  padding: 0;
  box-shadow: unset;
  border-radius: 0 !important;
}
textarea {
  border: 1px solid #ddd;
  resize: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  padding: 20px;
}
.dark-theme form {
  box-shadow: unset;
}
.dark-theme .comment-user,
.dark-theme textarea {
  background-color: #363636;
}
.dark-theme textarea {
  color: #fff !important;
}
</style>
