// import CoursesIndex from "@/views/Courses/CoursesIndex";
// import CourseView from "@/views/Courses/CourseView";

export default [
  {
    path: "/classes",
    name: "classes",
    component: () => import("@/views/Courses/CoursesIndex"),
    meta: {
      title: "الكورسات",
    },
  },
  {
    path: "/course/:slug/:file?",
    name: "course",
    component: () => import("@/views/Courses/CourseView"),
  },
  {
    path: "/offers",
    name: "Offers",
    component: () => import("@/views/Page/CoursesOffers.vue"),
  },
  {
    path: "/in-person-courses",
    name: "InPersonCourses",
    component: () => import("@/views/Courses/InPersonCourses.vue"),
  },
  {
    path: "/on-person-lesson-course/:slug",
    name: "OnInPersonCourses",
    component: () => import("@/views/Courses/OnInPersonCourse.vue"),
  },
  {
    path: "/course-tags/:slug",
    name: "CoursesTags",
    component: () => import("@/views/Courses/TagsView.vue"),
  },
];
