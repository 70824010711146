<template>
  <div>
    <div class="user-profile-left-section">
      <div class="row">
        <div class="col-12 col-lg-4">
          <profile-welcome-component></profile-welcome-component>
          <!-- continue section -->
        </div>
        <div class="col-12 col-lg-8">
          <div class="row">
            <div class="col-12 col-lg-6 text-white mb-2 pl-0">
              <div
                v-if="this.user !== null && user.role_name === 'teacher'"
                class="d-flex h-100 justify-content-between align-items-center bg-cs-secondary rounded-3 px-2 py-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  style="fill: #fff"
                >
                  <path
                    d="M18 17c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H6c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12zM4 19h16v2H4z"
                  ></path>
                </svg>
                <div class="flex-fill">
                  <div class="text-white fs-12 fw-bold">رصيد الحساب</div>
                  <small-spring-loading
                    :is-loading="islastWatchLoading"
                  ></small-spring-loading>
                  <h1
                    class="text-white fs-2 mt-1"
                    v-if="payout_status.readyPayout !== undefined"
                  >
                    {{ payout_status.readyPayout }}
                  </h1>
                </div>
                <button
                  @click="showModal()"
                  class="btn btn-coursestore rounded-0 px-3 fs-10 shadow rounded-1"
                >
                  سحب
                </button>
              </div>
            </div>
            <div class="col-12 col-lg-6 text-white mb-2" v-if="subscribed">
              <div
                class="d-flex h-100 justify-content-between align-items-center bg-cs-secondary rounded-3 px-2 py-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  style="fill: #fff"
                >
                  <path
                    d="M18 17c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H6c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12zM4 19h16v2H4z"
                  ></path>
                </svg>
                <div class="flex-fill">
                  <div class="text-white fs-12 fw-bold">الاشتركات</div>
                  <div class="text-white fs-10 fw-normal mt-1">اشتراك شهري</div>
                </div>
                <button
                  class="btn btn-coursestore rounded-0 px-3 fs-10 shadow rounded-1"
                >
                  إلغاء
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div
        class="row"
        v-if="this.user !== null && user.role_name === 'teacher'"
      >
        <div class="col-lg-3 col-md-6 padding-left-0">
          <router-link :to="{ name: 'financial' }">
            <div class="box-result box d-flex align-items-center">
              <div class="img-result">
                <img src="../../assets/icon-profile.svg" alt="" />
              </div>
              <div class="box_info d-flex flex-column">
                <!-- <small-spring-loading
                  :is-loading="loading_sales"
                ></small-spring-loading> -->
                <span v-if="sales_details.sales_status !== undefined">{{
                  sales_details.total_sales
                }}</span>
                <p>إجمالى المبيعات</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-md-6 padding-left-0">
          <router-link :to="{ name: 'purchases' }">
            <div class="box-result box d-flex align-items-center">
              <div class="img-result">
                <img src="../../assets/icon-profile.svg" alt="" />
              </div>
              <div class="box_info d-flex flex-column">
                <span>{{ instructor_details.courses_count }}</span>
                <p>كورساتى</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-md-6 padding-left-0">
          <router-link :to="{ name: 'Consultant' }">
            <div class="box-result box d-flex align-items-center">
              <div class="img-result">
                <img src="../../assets/icon-profile.svg" alt="" />
              </div>
              <div class="box_info d-flex flex-column">
                <span>{{ instructor_details.appointments_count }}</span>
                <p>استشاره مغلقة</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-md-6 padding-left-0">
          <div class="box-result box d-flex align-items-center">
            <div class="img-result">
              <img src="../../assets/icon-profile.svg" alt="" />
            </div>
            <div class="box_info d-flex flex-column">
              <span>{{ instructor_details.reviews_count }}</span>
              <p>التعليقات</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="this.user !== null && user.role_name === 'user'">
        <!-- {{ instructor_details }} -->
        <div class="col-lg-3 col-md-6 padding-left-0">
          <router-link :to="{ name: 'purchases' }">
            <div class="box-result box d-flex align-items-center">
              <div class="img-result">
                <img src="../../assets/icon-profile.svg" alt="" />
              </div>
              <div class="box_info d-flex flex-column">
                <!-- <small-spring-loading
                  :is-loading="loading_sales"
                ></small-spring-loading> -->
                <span>{{ instructor_details.courses_count }}</span>
                <p>كورساتى</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-md-6 padding-left-0">
          <router-link :to="{ name: 'Consultant' }">
            <div class="box-result box d-flex align-items-center">
              <div class="img-result">
                <img src="../../assets/icon-profile.svg" alt="" />
              </div>
              <div class="box_info d-flex flex-column">
                <span>{{ instructor_details.appointments_count }}</span>
                <p>استشاراتى</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-md-6 padding-left-0">
          <router-link :to="{ name: 'support' }">
            <div class="box-result box d-flex align-items-center">
              <div class="img-result">
                <img src="../../assets/icon-profile.svg" alt="" />
              </div>
              <div class="box_info d-flex flex-column">
                <span>{{ instructor_details.public_message }}</span>
                <p>رسائل الدعم</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-md-6 padding-left-0">
          <div class="box-result box d-flex align-items-center">
            <div class="img-result">
              <img src="../../assets/icon-profile.svg" alt="" />
            </div>
            <div class="box_info d-flex flex-column">
              <span>{{ instructor_details.reviews_count }}</span>
              <p>التعليقات</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-profile-left-section">
      <div class="">
        <div class="row">
          <div class="col-12 col-lg-4 pl-0">
            <div class="courses-with-title">
              <div
                class="d-flex justify-content-between align-items-center mt-4 mb-1"
              >
                <!-- continue section -->
                <div class="mt-2">
                  <!-- <h1 class="h6 text-light-dark-mode">الكورسات المكتملة</h1> -->
                  <h1 class="h6 text-light-dark-mode">متابعة المشاهدة</h1>
                  <p v-if="!lastWatch" class="text-gray-dark-mode">
                    لا توجد كورسات
                  </p>
                </div>
                <router-link
                  class="fw-bold text-light-dark-mode text-decoration-none"
                  :to="{ name: 'purchases' }"
                >
                  المزيد
                </router-link>
                <!-- <a
                  href="#"
                  class="fw-bold text-light-dark-mode text-decoration-none"
                  >المزيد</a
                > -->
              </div>

              <div class="profile-content-course-item-list">
                <small-spring-loading
                  :is-loading="islastWatchLoading"
                ></small-spring-loading>
                <router-link
                  v-for="(watchLastView, index) in lastWatch"
                  :key="index"
                  :to="`course/${watchLastView.webinar.slug}/${watchLastView.file.id}`"
                >
                  <div
                    class="box-follow mb-3 d-flex justify-content-between align-items-center my-2 bg-white p-3 rounded gap-3"
                  >
                    <div>
                      <img
                        height="50"
                        width="50"
                        :src="watchLastView.webinar.teacher.avatar"
                        alt="text"
                        class="rounded-2"
                      />
                    </div>
                    <div class="flex-fill">
                      <h1 class="fs-12 m-0">
                        {{ watchLastView.webinar.title }}
                      </h1>
                      <span class="fs-10"
                        >{{ watchLastView.webinar.teacher.full_name }}
                      </span>
                      <div class="progress" style="height: 10px">
                        <div
                          class="progress-bar rounded bg-cs-assent"
                          role="progressbar"
                          :style="{
                            width: `${watchLastView.webinar.progress}` + '%',
                          }"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 30 30"
                        style="fill: var(--bg-cs-primary)"
                      >
                        <path d="M7 6v12l10-6z"></path>
                      </svg>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <profile-my-courses-component></profile-my-courses-component>
          </div>
          <div class="col-12 col-lg-4">
            <div class="courses-with-title">
              <div
                class="mb-px d-flex align-items-center justify-content-between"
              >
                <h1 class="h6 text-light-dark-mode">الكورسات المكتملة</h1>
                <router-link
                  class="fw-bold text-light-dark-mode text-decoration-none"
                  :to="{ name: 'purchases' }"
                >
                  المزيد
                </router-link>
                <small-spring-loading
                  :is-loading="complateCoursesLoading"
                ></small-spring-loading>
              </div>
              <div class="profile-content-course-item-list row">
                <div
                  class="box-follow col-12 d-flex justify-content-between align-items-center my-2 bg-white p-3 rounded gap-3"
                  v-for="completeCourse in completeCourses"
                  :key="completeCourse.id"
                >
                  <img
                    height="50"
                    :src="completeCourse.image"
                    alt="text"
                    class="rounded-2"
                  />
                  <div class="flex-fill">
                    <h1 class="fs-12 m-0">
                      {{ completeCourse.title }}
                    </h1>
                    <span class="fs-10">
                      {{ completeCourse.teacher.full_name }}</span
                    >
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="fill: var(--bg-cs-secoundry)"
                    >
                      <path
                        d="M2.047 14.667a.992.992 0 0 0 .466.607l1.909 1.104v2.199a1 1 0 0 0 1 1h2.199l1.104 1.91a1.002 1.002 0 0 0 1.366.366L12 20.75l1.91 1.104a1.002 1.002 0 0 0 1.366-.366l1.103-1.909h2.199a1 1 0 0 0 1-1V16.38l1.909-1.104a.999.999 0 0 0 .366-1.366L20.75 12l1.104-1.909a1 1 0 0 0-.366-1.366l-1.909-1.104V5.422a1 1 0 0 0-1-1H16.38l-1.103-1.909a1.004 1.004 0 0 0-.607-.466.994.994 0 0 0-.759.1L12 3.25l-1.909-1.104a.998.998 0 0 0-1.366.365l-1.104 1.91H5.422a1 1 0 0 0-1 1V7.62L2.513 8.725a1.001 1.001 0 0 0-.365 1.366L3.251 12l-1.104 1.909a1.003 1.003 0 0 0-.1.758z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-gray-dark-mode" v-if="!completeCourses">
              لا توجد كورسات مكتملة
            </p>
          </div>
          <div class="col-12">
            <div class="row">
              <!-- <div class="col-lg-6">
                <div class="courses-with-title">
                  <div
                    class="d-flex justify-content-between align-items-center mt-4 mb-1"
                  >
                    <h1 class="h6 text-light-dark-mode">استشارات</h1>
                  </div>
                  <p class="text-gray-dark-mode">لا توجد استشارات متاحة الآن</p>
                  <div class="profile-content-course-item-list">
                    <div
                      class="my-1 d-flex justify-content-between align-items-center my-2 bg-white p-3 shadow-sm rounded gap-3"
                    >
                      <img
                        height="50"
                        src="assets/images/my_courses/1.png"
                        alt="text"
                        class="rounded-2"
                      />
                      <div class="flex-fill">
                        <h1 class="fs-12 m-0">العنوان الأول</h1>
                      </div>

                      <div>
                        <button
                          class="btn btn-coursestore-primary px-3 py-1 rounded-0 fs-12"
                        >
                          احجز
                        </button>
                      </div>
                    </div>
                    <div
                      class="my-1 d-flex justify-content-between align-items-center my-2 bg-white p-3 shadow-sm rounded gap-3"
                    >
                      <img
                        height="50"
                        src="assets/images/my_courses/3.png"
                        alt="text"
                        class="rounded-2"
                      />
                      <div class="flex-fill">
                        <h1 class="fs-12 m-0">العنوان الأول</h1>
                      </div>
                      <div>
                        <button
                          class="btn btn-coursestore-primary px-3 py-1 rounded-0 fs-12"
                        >
                          احجز
                        </button>
                      </div>
                    </div>
                    <div
                      class="my-1 d-flex justify-content-between align-items-center my-2 bg-white p-3 shadow-sm rounded gap-3"
                    >
                      <img
                        height="50"
                        src="assets/images/my_courses/3.png"
                        alt="text"
                        class="rounded-2"
                      />
                      <div class="flex-fill">
                        <h1 class="fs-12 m-0">العنوان الأول</h1>
                      </div>
                      <div>
                        <button
                          class="btn btn-coursestore-primary px-3 py-1 rounded-0 fs-12"
                        >
                          احجز
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div
                class="col-lg-6 mt-5 mb-2"
                v-if="this.user !== null && user.role_name === 'user'"
              >
                <h2 class="h6 text-gray-dark-mode">تذاكر الدعم</h2>
                <p class="text-gray-dark-mode" v-if="!tickets">
                  لا توجد تذاكر دعم متاحة الآن
                </p>
                <table class="table">
                  <thead>
                    <tr>
                      <th>الاسم</th>
                      <th>الموضوع</th>
                      <th>الحالة</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="ticket in tickets"
                      :key="ticket.id"
                      class="cart shadow-sm rounded-3 align-middle"
                    >
                      <td>
                        <div
                          class="d-flex justify-content-start gap-3 align-items-center"
                        >
                          <div>
                            <a href="#"
                              ><img
                                :src="ticket.user.avatar"
                                :alt="ticket.user.full_name"
                                height="60"
                            /></a>
                          </div>
                          <div class="align-items-center">
                            <h1 class="fs-12">
                              <a href="#" class="shamel-bold"
                                >{{ ticket.user.full_name }}
                              </a>
                            </h1>
                            <p class="fs-10 text-muted">مدرب</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6 class="fs-12">{{ ticket.title }}</h6>
                        <p class="text-muted fs-12">{{ ticket.created_at }}</p>
                      </td>
                      <td>
                        <h6 class="fs-12">منتظر</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h2 class="h6 text-gray-dark-mode" v-if="myBundles.length > 0">
                باقاتى
              </h2>
              <div v-for="item in myBundles" :key="item.id" class="mt-4 mb-3">
                <div class="field-slider prerequisite">
                  <div
                    v-swiper:myBundleOptionsSwiper="myBundleOption"
                    class="swiper mx-0"
                  >
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="course in item.courses"
                        :key="course.id"
                      >
                        <my-bundle-courses
                          :coursesOnBundle="course"
                        ></my-bundle-courses>
                        <small-spring-loading
                          :is-loading="loading"
                        ></small-spring-loading>
                      </div>
                    </div>
                  </div>
                  <div
                    class="swiper-button-prev-myBundles"
                    slot="button-prev-soon"
                  >
                    <img
                      src="../../assets/icon/slider-arrow/arrows-left.svg"
                      alt=""
                    />
                  </div>
                  <div
                    class="swiper-button-next-myBundles"
                    slot="button-next-soon"
                  >
                    <img
                      src="../../assets/icon/slider-arrow/arrows-right.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueModal
      v-model="showModalRequest"
      modalStyle="max-width:350px;min-height: 200px; padding: 20px; border-radius: 10px"
      modalClass="play_course_popup amount-pull"
      title="تأكيد السحب "
    >
      <div class="pull-mony">
        <p class="message-popup">
          يرجى التأكد من هذه المعلومات لطلب السحب سوف يتم إرسال الاموال على
          الحساب التالى
        </p>
      </div>
      <div class="amount d-flex align-items-center justify-content-between">
        <h6>جاهز للسحب</h6>
        <p>{{ payout_status.readyPayout }}</p>
      </div>
      <div class="box-input-amount mb-2">
        <label for="amount" class="d-block">المبلغ:</label>
        <input
          v-model="amount"
          type="number"
          id="amount"
          class="w-100"
          required
        />
      </div>

      <div
        class="account-type d-flex align-items-center justify-content-between"
      >
        <h6>نوع الحساب</h6>
        <p>بيت التمويل الكويتى</p>
      </div>
      <div
        class="account-number d-flex align-items-center justify-content-between"
      >
        <h6>رقم الحساب</h6>
        <p>sdf</p>
      </div>
      <div
        class="account-iban d-flex align-items-center justify-content-between"
      >
        <h6>Iban</h6>
        <p>{{ current_payout.iban }}</p>
      </div>

      <div
        class="amout-btn d-flex align-items-center justify-content-between px-4"
      >
        <button class="btn btn-primary" @click="requestPayout()">
          طلب السحب
        </button>
        <button class="btn btn-danger" @click="closeModal()">إغلاق</button>
      </div>
    </VueModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProfileWelcomeComponent from "@/components/Profile/Box/ProfileWelcomeComponent";
import ProfileMyCoursesComponent from "@/components/Profile/Box/ProfileMyCoursesComponent";
import MyBundleCourses from "@/components/Courses/MyBundleCourses.vue";
import VueModal from "@kouts/vue-modal";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import lastWatchRequest from "@/api/last_watch_request";
import complateCoursesRequest from "@/api/complate_courses_request";
import supportTicketsRequest from "@/api/support_tickets_request";
import axios from "axios";

// import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
export default {
  name: "ProfileIndexView",
  components: {
    ProfileMyCoursesComponent,
    ProfileWelcomeComponent,
    SmallSpringLoading,
    MyBundleCourses,
    VueModal,
    // SmallSpringLoading,
  },
  data() {
    return {
      amount: "",
      showModalRequest: false,
      lastWatch: [],
      completeCourses: [],
      islastWatchLoading: false,
      complateCoursesLoading: false,
      ticketsLoading: false,
      tickets: [],
      subscribed: false,
      myBundleOption: {
        slidesOffsetAfter: 8,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
          releaseOnEdges: true,
          invert: false,
        },
        spaceBetween: 0,
        slidesPerView: "auto",
        breakpoints: {
          640: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next-myBundles",
          prevEl: ".swiper-button-prev-myBundles",
        },
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("financial", [
      "payout_list",
      "sales_details",
      "payout_status",
      "current_payout",
    ]),
    ...mapGetters("instructors", ["instructor_details"]),
    ...mapGetters("bundles", ["myBundles", "loading"]),
  },
  methods: {
    ...mapActions("bundles", ["getMyBundle"]),
    ...mapActions("auth", ["getUser"]),
    ...mapActions("financial", ["getAllSales", "getAllPayout"]),
    ...mapActions("instructors", ["GetInstructor"]),
    showModal() {
      this.showModalRequest = true;
    },
    closeModal() {
      this.showModalRequest = false;
    },

    getLast1Watch() {
      this.islastWatchLoading = true;
      lastWatchRequest.lastWatch().then((res) => {
        this.lastWatch = res.data.data;
        this.islastWatchLoading = false;
      });
    },
    getComplateCourses() {
      this.complateCoursesLoading = true;
      complateCoursesRequest.completeCourses().then((res) => {
        this.completeCourses = res.data.data.webinars;
        this.complateCoursesLoading = false;
      });
    },
    async requestPayout() {
      const payload = {
        amount: this.amount,
      };
      this.Loading = true;
      await axios.post("/panel/financial/payout", payload).then((res) => {
        if (this.amount !== "") {
          this.$swal.fire({
            confirmButtonText: "موافق",
            title: res.data.title,
            text: res.data.message,
          });
          this.Loading = false;
          this.showModalRequest = false;
        }
      });
    },
    getSubscribe() {
      axios.get("/subscribe").then((res) => {
        this.subscribed = res.data.data.subscribed;
      });
    },
    getSupportTickts() {
      this.ticketsLoading = true;
      supportTicketsRequest.getSupportTickets().then((res) => {
        this.tickets = res.data.data;
        this.ticketsLoading = false;
      });
    },
    async _getAllPayout() {
      let _this = this;
      _this.btn_disabled = true;
      await this.getAllPayout({}).finally(() => {
        _this.btn_disabled = false;
      });
    },
  },
  created() {
    this.getMyBundle();
    if (this.payout_list.length <= 0) {
      this._getAllPayout();
    }
    this.getLast1Watch();
    this.getComplateCourses();
    this.getAllSales();
    this.getSubscribe();
    this.getSupportTickts();
    this.getUser().then(() => {
      if (this.user !== undefined) {
        this.GetInstructor(this.user.id);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.swiper-button-next-myBundles,
.swiper-button-prev-myBundles {
  top: 35% !important;
}
a {
  text-decoration: none;
}
.dark-theme .text-light-dark-mode {
  color: #fff;
}
.box {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: 8px;
  padding: 15px 30px;
}

.dark-theme .box {
  background-color: #1c1c1c;
}
.box_info span,
.box_info p {
  font-size: 12px !important;
  font-weight: bold;
  color: #36037d;
}
.dark-theme .box_info span {
  color: gray;
}
.dark-theme .box_info p {
  color: #fff;
}
.img-result {
  width: 100px;
  text-align: end;
}
.dark-theme .bg-white {
  background-color: #1c1c1c !important;
  color: #fff;
}
.box-follow {
  box-shadow: rgb(0 0 0 / 15%) 0 5px 15px 0;
}
.dark-theme .box-follow {
  box-shadow: rgb(255 255 255 / 15%) 0 5px 15px 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.padding-left-0:not(:last-child) {
  padding-left: 0;
  margin-bottom: 10px;
}
.mb-px {
  margin-top: 40px !important;
}
@media (max-width: 768px) {
  .box {
    padding: 10px 5px;
  }
  .img-result {
    width: auto;
    margin-left: 10px;
    text-align: end;
  }
}
@media (max-width: 992px) {
  .padding-left-0,
  .pl-0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
}

.field-slider {
  position: relative;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
}
.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next-myBundles {
  margin-top: 0;
  position: absolute;
  top: 50%;
  right: auto;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
  width: 35px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  z-index: 1;
}
.swiper-button-next-myBundles img,
.swiper-button-prev-myBundles img {
  width: 100%;
  height: 100%;
}
.swiper-button-prev-myBundles {
  position: absolute;
  top: 50%;
  left: auto;
  right: 8px;
  z-index: 1;
  transform: translateY(-50%) rotate(180deg);
  width: 35px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 0;
}
</style>
