import comment_request from "@/api/comment_request";
import course_bundle_request from "@/api/course_bundle_request";

export default {
  namespaced: true,
  state: () => ({
    comments: [],
    showLoadMore: false,
  }),
  getters: {
    comments: (state) => {
      return state.comments;
    },
    showLoadMore: (state) => {
      return state.showLoadMore;
    },
  },
  mutations: {
    SET_COMMENTS(state, payload) {
      if (payload) {
        payload.forEach((item) => {
          state.comments.push(item);
        });
      }
    },
    RESET_COMMENTS: (state) => {
      state.comments = [];
    },
    SET_SHOWLOADMORE(state, payload) {
      state.showLoadMore = payload;
    },
  },
  actions: {
    async getComments({ commit, state }, payload) {
      let _limit = payload.limit;
      let _state = state;
      await comment_request.getComments(payload).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          if (response.data.data.length > 0) {
            commit("SET_COMMENTS", response.data.data);
            if (Object.keys(_state.comments).length >= _limit) {
              commit("SET_SHOWLOADMORE", true);
            }
          } else {
            commit("SET_SHOWLOADMORE", false);
          }
        }
      });
    },
    async getBundleComments({ commit, state }, payload) {
      let _limit = payload.limit;
      let _state = state;
      await course_bundle_request.getBundleComments(payload).then((res) => {
        if (res.status === 200 && res.data.success === true) {
          if (res.data.data.length > 0) {
            commit("SET_COMMENTS", res.data.data);
            if (Object.keys(_state.comments).length >= _limit) {
              commit("SET_SHOWLOADMORE", true);
            }
          } else {
            commit("SET_SHOWLOADMORE", false);
          }
        }
      });
    },
    reset_comments({ commit }) {
      commit("RESET_COMMENTS");
      commit("SET_SHOWLOADMORE", false);
    },
  },
};
