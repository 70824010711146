import course_bundle_request from "@/api/course_bundle_request";

export default {
  namespaced: true,
  state: {
    getAllBundles: [],
    bundleCourses: [],
    myBundleCourses: [],
    loading: false,
    bundleSuggests: [],
  },
  getters: {
    allBundles(state) {
      return state.getAllBundles;
    },
    getCoursesOnBundle(state) {
      return state.bundleCourses;
    },
    myBundles(state) {
      return state.myBundleCourses;
    },
    bundleSuggests(state) {
      return state.bundleSuggests;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    RESET_BUNDLES(state) {
      state.getAllBundles = [];
    },
    SET_BUNDLES(state, payload) {
      state.getAllBundles = payload;
    },
    SET_BUNDLES_LOAD_MORE(state, payload) {
      state.getAllBundles.push(...payload);
    },
    SET_MY_BUNDLE_COURSES(state, payload) {
      state.myBundleCourses = payload;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_BUNDLES_SUGGESTS(state, payload) {
      state.bundleSuggests = payload;
    },
  },

  actions: {
    async getBundles({ commit }, payload) {
      commit("SET_LOADING", true);
      await course_bundle_request
        .geBundles(
          payload.limit,
          payload.offset,
          payload.instructor_id,
          payload.category_id
        )
        .then((res) => {
          commit("SET_BUNDLES", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_LOADING", false);
        });
    },
    async getBundlesLoadMor({ commit }, payload) {
      commit("SET_LOADING", true);
      await course_bundle_request
        .geBundles(
          payload.limit,
          payload.offset,
          payload.instructor_id,
          payload.category_id
        )
        .then((res) => {
          commit("SET_BUNDLES_LOAD_MORE", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_LOADING", false);
        });
    },

    resetBundle({ commit }) {
      commit("RESET_BUNDLES");
    },

    async getMyBundle({ commit }) {
      await course_bundle_request.getMyBundle().then((res) => {
        commit("SET_MY_BUNDLE_COURSES", res.data.data);
      });
    },

    async getBundleSuggests({ commit }, { id, limit }) {
      await course_bundle_request.getBundleSuggests(id, limit).then((res) => {
        commit("SET_BUNDLES_SUGGESTS", res.data.data);
      });
    },
  },
};
