import financial_request from "@/api/financial_request";

export default {
  namespaced: true,
  state: () => ({
    sales_list: [],
    sales_details: {},
    sales_offset: 0,
    loading_sales: true,
    summary_list: [],
    summary_offset: 0,
    loading_summary: true,
    payout_list: [],
    current_payout: {},
    payout_status: {},
    payout_offset: 0,
    loading_payout: true,
    btnShowLoadMore: true,
  }),
  getters: {
    btnShowLoadMore: (state) => {
      return state.btnShowLoadMore;
    },
    sales_list: (state) => {
      return state.sales_list;
    },
    sales_offset: (state) => {
      return state.sales_offset;
    },
    loading_sales: (state) => {
      return state.loading_sales;
    },
    sales_details: (state) => {
      return state.sales_details;
    },
    summary_list: (state) => {
      return state.summary_list;
    },
    summary_offset: (state) => {
      return state.summary_offset;
    },
    loading_summary: (state) => {
      return state.loading_summary;
    },
    payout_list: (state) => {
      return state.payout_list;
    },
    payout_offset: (state) => {
      return state.payout_offset;
    },
    loading_payout: (state) => {
      return state.loading_payout;
    },
    current_payout: (state) => {
      return state.current_payout;
    },
    payout_status: (state) => {
      return state.payout_status;
    },
  },
  mutations: {
    SET_SALES_LIST: (state, payload) => {
      state.sales_list.push(payload);
    },
    SET_SALES_OFFSET: (state, payload) => {
      state.sales_offset = payload;
    },
    SET_LOADING_SALES: (state, payload) => {
      state.loading_sales = payload;
    },
    SET_SALES_DETAILS: (state, payload) => {
      state.sales_details = payload;
    },
    SET_SUMMARY_LIST: (state, payload) => {
      state.summary_list.push(payload);
    },
    SET_SUMMARY_OFFSET: (state, payload) => {
      state.summary_offset = payload;
    },
    SET_LOADING_SUMMARY: (state, payload) => {
      state.loading_summary = payload;
    },
    SET_PAYOUT_LIST: (state, payload) => {
      state.payout_list.push(payload);
    },
    SET_PAYOUT_OFFSET: (state, payload) => {
      state.payout_offset = payload;
    },
    SET_LOADING_PAYOUT: (state, payload) => {
      state.loading_payout = payload;
    },
    SET_CURRENT_PAYOUT: (state, payload) => {
      state.current_payout = payload;
    },
    SET_PAYOUT_STATUS: (state, payload) => {
      state.payout_status = payload;
    },
    SHOW_MORE: (state, payload) => {
      state.btnShowLoadMore = payload;
    },
  },
  actions: {
    upgradeOffset({ commit }, payload) {
      let limit = 10;
      //console.log("upgradeOffset", payload);
      // upgrade offset
      if (payload.offset <= 0) {
        commit(payload.commit, limit);
      } else {
        commit(payload.commit, limit + payload.offset);
      }
    },
    async getAllSales({ commit, state, dispatch }) {
      commit("SET_LOADING_SALES", true);
      await financial_request
        .fetchAllSales(state.sales_offset)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            // set sales details
            commit("SET_SALES_DETAILS", {
              students_count: response.data.data.students_count,
              webinars_count: response.data.data.webinars_count,
              meetings_count: response.data.data.meetings_count,
              total_sales: response.data.data.total_sales,
              class_sales: response.data.data.class_sales,
              meeting_sales: response.data.data.meeting_sales,
              sales_status: response.data.data.sales_status,
            });
            let list = response.data.data.sales;
            list.forEach((item) => {
              commit("SET_SALES_LIST", item);
            });
            if (list.length > 0) {
              dispatch("upgradeOffset", {
                offset: state.sales_offset,
                commit: "SET_SALES_OFFSET",
              });
            }
          }
        })
        .finally(() => {
          commit("SET_LOADING_SALES", false);
        });
    },
    async getAllSummary({ commit, state, dispatch }) {
      commit("SET_LOADING_SUMMARY", true);
      await financial_request
        .fetchAllSummary(state.summary_offset)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            let list = response.data.data.history;
            list.forEach((item) => {
              commit("SET_SUMMARY_LIST", item);
            });
            if (list.length > 0) {
              dispatch("upgradeOffset", {
                offset: state.summary_offset,
                commit: "SET_SUMMARY_OFFSET",
              });
            }
          }
        })
        .finally(() => {
          commit("SET_LOADING_SUMMARY", false);
        });
    },
    async getAllPayout({ commit, state, dispatch }) {
      commit("SET_LOADING_PAYOUT", true);

      await financial_request
        .fetchAllPayout(state.payout_offset)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_CURRENT_PAYOUT", response.data.data.current_payout);
            commit("SET_PAYOUT_STATUS", response.data.data.payout_status);
            let list = response.data.data.payouts;
            if (list.length > 0) {
              if (list.length >= 10) {
                commit("SHOW_MORE", true);
              } else {
                commit("SHOW_MORE", false);
              }
              list.forEach((item) => {
                commit("SET_PAYOUT_LIST", item);
              });
              dispatch("upgradeOffset", {
                offset: state.payout_offset,
                commit: "SET_PAYOUT_OFFSET",
              });
            } else {
              commit("SHOW_MORE", false);
            }
          }
        })
        .finally(() => {
          commit("SET_LOADING_PAYOUT", false);
        });
    },
  },
};
