<template>
  <div v-if="coursesOnBundle">
    <VueModal
      v-model="showModal"
      modalStyle="max-width:600px;height: 452px;"
      modalClass="play_course_popup"
      :title="showModalTitle"
    >
      <iframe
        :src="videoUrl"
        style="border: 0; width: 600px; height: 405px"
        allow="encrypted-media"
        allowfullscreen
      ></iframe>
    </VueModal>
    <div class="myBundle">
      <div
        class="card-image h-100 position-relative"
        :style="{
          'background-image': `url('${coursesOnBundle.image} ')`,
        }"
      >
        <div class="over"></div>
        <div class="position-absolute hover-effect center-element">
          <button
            class="hover-effect btn p-0"
            @click.prevent="doShowModal(coursesOnBundle)"
          >
            <img
              width="50"
              style="visibility: visible"
              src="../../assets/icon/cs-play.svg"
              @click.prevent="doShowModal(coursesOnBundle)"
              :alt="coursesOnBundle.title"
              class="text-white text-center bg-white rounded bg-opacity-25"
            />
          </button>
        </div>
        <div class="video-details d-flex justify-content-between w-100">
          <div class="video-time">
            <duration-component
              :duration="coursesOnBundle.duration"
            ></duration-component>
          </div>
          <div class="vide-count">
            {{ coursesOnBundle.videos_count }}
          </div>
        </div>
        <div class="progress" style="height: 10px">
          <div
            class="progress-bar rounded bg-cs-assent"
            role="progressbar"
            :style="{
              width: `${coursesOnBundle.progress}` + '%',
            }"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
    <router-link
      class="mb-1 title text-start text-decoration-none"
      :to="{
        name: 'course',
        params: { slug: coursesOnBundle.slug },
      }"
    >
      <p class="course-title text-start mb-1">
        {{ coursesOnBundle.title }}
      </p>
      <p class="teacher">
        {{ coursesOnBundle.teacher.full_name }}
      </p>
    </router-link>
  </div>
</template>

<script>
import DurationComponent from "@/components/Courses/DurationComponent";
import course_request from "@/api/course_request";
import VueModal from "@kouts/vue-modal";

export default {
  props: ["coursesOnBundle"],
  data() {
    return {
      videoUrl: "",
      showModal: false,
      showModalTitle: "",
    };
  },
  components: {
    VueModal,
    DurationComponent,
  },
  methods: {
    doShowModal: async function (course) {
      course_request.getPromoVideoById({ id: course.id }).then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            this.showModalTitle = course.title;
            this.showModal = true;
            this.videoUrl =
              "https://player.vdocipher.com/v2/?otp=" +
              response.data.data.otp +
              "&playbackInfo=" +
              response.data.data.playbackInfo +
              "&primaryColor=#410098";
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.data.message,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.myBundle {
  height: 200px;
  .over {
    z-index: 1;
  }
  .card-image {
    background-size: cover;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    z-index: 22;
    .progress {
      height: 8px !important;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      border-radius: 8px;
      z-index: 22;
    }
    .center-element {
      z-index: 22;
    }
    .video-details {
      z-index: 22;
      position: absolute;
      color: #fff;
      bottom: 20px;
      padding: 0 10px;
      font-size: 12px;
    }
  }
}

.course-title {
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  color: #818894;
}
.dark-theme .course-title {
  color: #fff;
}
.teacher {
  font-size: 12px;
  color: #707070;
}
</style>
