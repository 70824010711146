import webinars_request from "@/api/webinars_request";

export default {
  namespaced: true,
  state: () => ({
    my_purchases: [],
    all_purchases: [],
    all_favorites: [],
    offset: 0,
    fav_offset: 0,
    load_more_fav: true,
  }),
  getters: {
    my_purchases: (state) => {
      return state.my_purchases;
    },
    all_purchases: (state) => {
      return state.my_purchases;
    },
    all_favorites: (state) => {
      return state.all_favorites;
    },
    offset: (state) => {
      return state.offset;
    },
    fav_offset: (state) => {
      return state.fav_offset;
    },
    load_more_fav: (state) => {
      return state.load_more_fav;
    },
  },
  mutations: {
    SET_MY_PURCHASES: (state, payload) => {
      state.my_purchases = payload;
    },
    SET_PURCHASES: (state, payload) => {
      payload.forEach((element) => state.all_purchases.push(element));
    },
    SET_FAVORITES: (state, payload) => {
      payload.forEach((element) => state.all_favorites.push(element));
    },
    SET_OFFSET: (state, payload) => {
      state.offset = payload;
    },
    SET_FAV_OFFSET: (state) => {
      state.fav_offset++;
    },
    SET_Load_MORE_FAV: (state, payload) => {
      state.load_more_fav = payload;
    },
  },
  actions: {
    async getMyPurchases({ commit }, payload) {
      await webinars_request.getMyPurchases(payload.limit).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          commit("SET_MY_PURCHASES", response.data.data.webinars);
        }
      });
    },
    async getPurchases({ commit, state }, payload) {
      let _offset = payload.limit * state.offset;
      await webinars_request
        .getPurchases(payload.limit, _offset)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_PURCHASES", response.data.data.webinars);
            commit("SET_OFFSET", state.offset++);
          }
        });
    },
    async getFavorites({ commit, state }, payload) {
      let _offset = payload.limit * state.fav_offset;
      await webinars_request
        .getFavorites(payload.limit, _offset)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_FAVORITES", response.data.data.favorites);
            if (response.data.data.favorites.length > 0) {
              commit("SET_FAV_OFFSET");
            } else {
              commit("SET_Load_MORE_FAV", false);
            }
          }
        });
    },
  },
};
