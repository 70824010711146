import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth_module from "../store/modules/auth_module.js";
import site_module from "./modules/config_module";
import cart_module from "@/store/modules/cart_module";
import notification_module from "@/store/modules/notification_module";
import course_module from "@/store/modules/course_module";
import home_module from "@/store/modules/home_module";
import instractor_module from "./modules/instractor_module.js";
import subscribe_module from "@/store/modules/subscribe_module";
import search_module from "@/store/modules/search_module";
import page_module from "@/store/modules/page_module";
import webinars_module from "@/store/modules/webinars_module";
import comment_module from "@/store/modules/comment_module";
import review_module from "@/store/modules/review_module";
import financial_module from "@/store/modules/financial_module";
import course_note_module from "@/store/modules/course_note_module";
import certificates_module from "@/store/modules/certificates_module";
import bundles_module from "@/store/modules/bundle_module";
import banners_module from "@/store/modules/banners_module.js";
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: auth_module,
    site: site_module,
    instructors: instractor_module,
    cart: cart_module,
    notification: notification_module,
    course: course_module,
    home: home_module,
    subscribe: subscribe_module,
    search: search_module,
    page: page_module,
    webinars: webinars_module,
    comment: comment_module,
    review: review_module,
    financial: financial_module,
    course_note: course_note_module,
    certificates: certificates_module,
    bundles: bundles_module,
    banners: banners_module,
  },
});
