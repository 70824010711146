var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.coursesOnBundle)?_c('div',[_c('VueModal',{attrs:{"modalStyle":"max-width:600px;height: 452px;","modalClass":"play_course_popup","title":_vm.showModalTitle},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('iframe',{staticStyle:{"border":"0","width":"600px","height":"405px"},attrs:{"src":_vm.videoUrl,"allow":"encrypted-media","allowfullscreen":""}})]),_c('div',{staticClass:"myBundle"},[_c('div',{staticClass:"card-image h-100 position-relative",style:({
        'background-image': `url('${_vm.coursesOnBundle.image} ')`,
      })},[_c('div',{staticClass:"over"}),_c('div',{staticClass:"position-absolute hover-effect center-element"},[_c('button',{staticClass:"hover-effect btn p-0",on:{"click":function($event){$event.preventDefault();return _vm.doShowModal(_vm.coursesOnBundle)}}},[_c('img',{staticClass:"text-white text-center bg-white rounded bg-opacity-25",staticStyle:{"visibility":"visible"},attrs:{"width":"50","src":require("../../assets/icon/cs-play.svg"),"alt":_vm.coursesOnBundle.title},on:{"click":function($event){$event.preventDefault();return _vm.doShowModal(_vm.coursesOnBundle)}}})])]),_c('div',{staticClass:"video-details d-flex justify-content-between w-100"},[_c('div',{staticClass:"video-time"},[_c('duration-component',{attrs:{"duration":_vm.coursesOnBundle.duration}})],1),_c('div',{staticClass:"vide-count"},[_vm._v(" "+_vm._s(_vm.coursesOnBundle.videos_count)+" ")])]),_c('div',{staticClass:"progress",staticStyle:{"height":"10px"}},[_c('div',{staticClass:"progress-bar rounded bg-cs-assent",style:({
            width: `${_vm.coursesOnBundle.progress}` + '%',
          }),attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}})])])]),_c('router-link',{staticClass:"mb-1 title text-start text-decoration-none",attrs:{"to":{
      name: 'course',
      params: { slug: _vm.coursesOnBundle.slug },
    }}},[_c('p',{staticClass:"course-title text-start mb-1"},[_vm._v(" "+_vm._s(_vm.coursesOnBundle.title)+" ")]),_c('p',{staticClass:"teacher"},[_vm._v(" "+_vm._s(_vm.coursesOnBundle.teacher.full_name)+" ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }