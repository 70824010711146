<template>
  <div class="text-lesson">
    <div class="text-lesson-head">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: #818894"
      >
        <path
          d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"
        ></path>
      </svg>
      <p class="text-light-dark-mode">الأسئلة</p>
    </div>
    <div class="chapter-content">
      <div class="input-groups">
        <p class="title text-light-dark-mode">اللغة</p>
        <b-form-select
          v-model="fileGroup.locale"
          :options="lang"
        ></b-form-select>
      </div>

      <div class="mt-3">
        <GlobalInput type="text" label="السؤال" v-model="fileGroup.title" />
      </div>

      <div class="mt-3">
        <p class="text-light-dark-mode">المحتوى:</p>
        <b-textarea v-model="fileGroup.answer" />
      </div>
    </div>
    <div class="d-flex align-items-center gap-3">
      <b-button class="btn btn-coursestore-primary mt-4" @click="storeFaq()"
        >حفظ
        <small-spring-loading :is-loading="loadingStore"></small-spring-loading>
      </b-button>
      <b-button class="btn btn-coursestore-primary mt-4" @click="updateFaqs()"
        >تعديل
        <small-spring-loading
          :is-loading="loadingUpdateStore"
        ></small-spring-loading>
      </b-button>
      <b-button class="btn btn-coursestore-primary mt-4" @click="deleteFaqs()"
        >حذف
        <small-spring-loading
          :is-loading="loadingDeleteStore"
        ></small-spring-loading>
      </b-button>
    </div>
  </div>
</template>

<script>
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import upload_courses_request from "@/api/upload_courses_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
// import Vue from "vue";
export default {
  components: {
    GlobalInput,
    SmallSpringLoading,
  },
  props: {
    chapterId: { type: [Number, null] },
  },
  name: "text-lesson",
  data() {
    return {
      lang: ["ar"],

      fileGroup: {
        locale: "",
        title: "",
        answer: "",
      },
      loadingStore: false,
      loadingUpdateStore: false,
      loadingDeleteStore: false,
      faqId: Number,
    };
  },
  methods: {
    formData() {
      const formData = new FormData();
      formData.append("webinar_id", this.$route.query.webinar_id);

      formData.append("locale", this.fileGroup.locale);
      formData.append("title", this.fileGroup.title);
      formData.append("answer", this.fileGroup.answer);

      return formData;
    },

    async storeFaq() {
      this.loadingStore = true;
      await upload_courses_request.storeFaqs(this.formData()).then((res) => {
        this.faqId = res.data.data.id;
        this.$swal({
          icon: "success",
          title: `${res.data.message}`,
        });
      });
      this.loadingStore = false;
    },

    async updateFaqs() {
      if (this.faqId) {
        this.loadingUpdateStore = true;
        await upload_courses_request
          .updateFaqs(this.faqId, this.formData())
          .then((res) => {
            this.$swal({
              icon: "success",
              title: `${res.data.message}`,
            });
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              title: "خطاء فى التعديل",
            });
          });
        this.loadingUpdateStore = false;
      } else {
        this.$swal({
          icon: "error",
          title: "يرجى اضافة درس نصي",
        });
      }
    },

    async deleteFaqs() {
      if (this.faqId) {
        this.loadingDeleteStore = true;
        await upload_courses_request.deleteFaqs(this.faqId).then(() => {
          this.$swal({
            title: "هل تريد الحذف",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "تأكيد",
            cancelButtonText: "إلغاء",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
          });
        });
      }
      this.loadingDeleteStore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-lesson {
  background-color: #fff;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  .text-lesson-head {
    display: flex;
    gap: 5px;
    padding: 5px;
    p {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }
  }
  .chapter-content {
    max-width: 800px;
    input,
    select,
    b-textarea {
      width: 100%;
      border: 1px solid #ddd;
      padding: 5px;
      outline: none;
      border-radius: 4px;
    }
  }
  .title {
    display: block;
  }

  // radio button
  .button-checkbox .button-label {
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    padding: 5px 13px !important;
    cursor: pointer;
  }
  .button-checkbox input:checked + .button-label {
    background-color: #1ecad3;
    color: #fff;
  }

  .button-checkbox input:not(:checked) + .button-label {
    background-color: #fff;
    color: #000;
  }
}
.dark-theme .text-lesson {
  background-color: #2f2f2f;
}
</style>
