<template>
  <div class="scroll-table-mobile mb-5">
    <!-- <small-spring-loading :is-loading="Loading"></small-spring-loading> -->

    <div class="tabs-breadcrumb d-flex align-items-center align-content-center">
      <div
        class="breadcrumb-item me-0 me-md-5 ms-0 py-3 d-flex align-items-center align-content-center"
      >
        <a
          href="#"
          class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
        >
          <h6 class="head fs-5 text-light-dark-mode mb-0">الدعم</h6>
        </a>
        <img
          src="../../assets/icon/arrow-left.svg"
          alt="arrow-left"
          class="mx-2"
        />
      </div>

      <div
        class="profile-tab flex-fill d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-content-center gap-4">
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myCertificateStatistics' }"
            @click.prevent="activeTabe = 'myCertificateStatistics'"
          >
            جديد
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'results' }"
            @click.prevent="activeTabe = 'results'"
          >
            ملخص الدعم
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myCertificate' }"
            @click.prevent="activeTabe = 'myCertificate'"
          >
            فرز الرسائل
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'messageBox' }"
            @click.prevent="activeTabe = 'messageBox'"
          >
            سجل الرسائل
          </a>
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <div v-if="activeTabe === 'myCertificateStatistics'">
        <p class="text-light-dark-mode">رسالة دعم جديدة</p>

        <form>
          <div class="form-support">
            <b-form-group class="">
              <label for="pName">الموضوع</label>
              <b-form-input
                v-model="form.topic"
                id="pName"
                required
                class="shadow-none input-dark-mode"
              />
            </b-form-group>
            <b-form-group class="mt-3">
              <label for="pName">النوع</label>
              <v-select
                v-model="form.type"
                dir="rtl"
                :label="'title'"
                :options="departments"
                class="mt-3 input-dark-mode"
                :menu-props="{ maxHeight: '400' }"
              />
            </b-form-group>
            <b-form-group class="mt-3">
              <label for="pName">الرسالة</label>
              <b-form-textarea
                id="textarea"
                v-model="form.message"
                placeholder=""
                rows="3"
                max-rows="6"
                class="shadow-none p-4 input-dark-mode"
              ></b-form-textarea>
            </b-form-group>
            <div class="btn-submit">
              <button
                @click.prevent="sendMessage()"
                class="btn"
                style="color: #fff"
              >
                إرسال رسالة
              </button>
            </div>
            <!-- Add File -->
            <div class="mt-3">
              <!-- <label for="pName">إضافة ملف</label>
              <div class="d-flex align-items-center gap-3">
                <div class="custom-file">
                  <img
                    class="img-input-file"
                    src="../../assets/uploadimage.png"
                    alt=""
                  />
                  <input type="file" id="file" />
                </div>

                <div class="btn-submit">
                  <button
                    @click="sendMessage()"
                    class="btn"
                    style="color: #fff"
                  >
                    إرسال رسالة
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </form>
      </div>
      <div v-if="activeTabe === 'results'" class="mb-4">
        <p class="text-light-dark-mode">ملخص الدعم</p>

        <div class="box">
          <div class="row">
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center gap-2">
                <div class="img-result">
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>0</span>
                  <p>فتح المحادثات المفتوحة</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center gap-2">
                <div class="img-result">
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>0</span>
                  <p>المحادثات المغلقة</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center gap-2">
                <div class="img-result">
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>0</span>
                  <p>كل المحادثات</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTabe === 'myCertificate'">
        <p class="text-light-dark-mode">فلترةالنتائج</p>
        <form action="#">
          <div class="row align-items-center">
            <div class="col-lg-2">
              <label for="datepicker-sm">من</label>
              <b-form-datepicker
                id="datepicker-sm"
                size="sm"
                locale="ar"
                class="mb-2"
              ></b-form-datepicker>
            </div>
            <div class="col-lg-2">
              <label for="">الى</label>
              <div class="border-1">
                <b-form-datepicker
                  id="datepicker-sm"
                  size="sm"
                  locale="ar"
                  class="mb-2"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-lg-2">
              <label>المدربون</label>
              <b-form-select
                v-model="selected"
                :options="options"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-lg-2">
              <label>كورسات</label>
              <b-form-select
                v-model="selected"
                :options="options"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-lg-2">
              <label>الحالة</label>
              <b-form-select
                v-model="selected"
                :options="options"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <button class="btn btn-blue">عرض النتائج</button>
              </div>
            </div>
          </div>
        </form>
        <div>
          <button
            :disabled="disable_load_more"
            class="btn btn-coursestore mx-auto d-block"
            @click.prevent="loadMore"
          >
            <span class="text-white">المزيد</span>
          </button>
          <div class="text-center mt-4">
            <p class="text-light-dark-mode">انت لا تمتلك اى رسائل</p>
            <!-- <span class="text-gray-dark-mode">
              تستطيع الحصول على شهادات عن طريق الانضمام الى احد الكورسات
            </span> -->
          </div>
        </div>
      </div>
      <div v-if="activeTabe === 'messageBox'">
        <div class="text-center mt-4">
          <h3 class="message-support">اختر محادثة</h3>
          <span class="text-gray-dark-mode sub-message-support">
            الرجاء اختيار محادثة مع الدعم لمشاهدة سجل الرسائل
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supportTicketsRequest from "@/api/support_tickets_request";
export default {
  name: "profileConsult",
  data() {
    return {
      form: {
        topic: "",
        type: "",
        message: "",
        department_id: 3,
      },
      activeTabe: "myCertificateStatistics",
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      departments: [],
    };
  },
  methods: {
    sendMessage() {
      const payload = {
        title: this.form.topic,
        type: this.form.type,
        message: this.form.message,
        department_id: this.form.department_id,
      };
      supportTicketsRequest.sendSupportTickets(payload).then((res) => {
        console.log(res);
      });
    },
    supportOptions() {
      supportTicketsRequest.getSupportDepartments().then((res) => {
        this.departments = res.data;
      });
    },
  },
  created() {
    this.supportOptions();
  },
};
</script>

<style scoped>
.custom-file {
  height: 40px;
  border-radius: 8px;
  position: relative;
  border: 1px solid #ccc;
}
.custom-file input[type="file"] {
  opacity: 0;
  height: 100%;
  padding: 0;
}
.img-input-file {
  width: 50px;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #ddd;
  padding: 10px;
  border-radius: 8px;
  display: inline;
}
form {
  padding: 25px 10px;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: var(--bs-border-radius) !important;
}
.dark-theme form {
  box-shadow: rgb(183 180 180 / 31%) 0 5px 15px 0;
}
.btn-blue {
  background: #410099 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  width: 174px;
  height: 40px;
  margin-top: 19px;
}
.btn-blue:hover {
  background-color: #36037d;
  color: #fff;
}
.b-form-datepicker {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #ccc !important;
}
#datepicker-sm {
  box-shadow: none !important;
}
input {
  width: 100%;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.45rem 0.5rem;
  font-size: 0.875rem;
  outline: none !important;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
}
select {
  width: 100%;
  padding: 6px;
  margin: 0 !important;
  outline: none;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #6c757d !important;
}
label {
  color: #36037d;
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}
.btn,
button {
  box-shadow: none !important;
}
.dark-theme label {
  color: #fff;
}
.title-content-tab {
  color: #36037d;
  font-weight: 600;
}
.box {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: 8px;
  padding: 30px;
}
.dark-theme .box {
  background-color: #1c1c1c;
}
.box_info span,
.box_info p {
  font-size: 16px;
  font-weight: bold;
  color: #36037d;
}
.dark-theme .box_info span {
  color: gray;
}
.dark-theme .box_info p {
  color: #fff;
}
.form-support {
  max-width: 800px;
  margin: auto;
}
.btn-submit {
  background: #410099 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #fff !important;
  font-size: 12px;
  display: inline-block;
  margin-top: 20px;
}
textarea {
  overflow-y: unset !important;
}
.btn-submit button {
  padding: 8px 20px;
}
.dark-theme .btn-submit {
  background-color: #000 !important;
}
.message-support {
  font-size: 20px;
  font-weight: 600;
  color: #36037d;
}
.sub-message-support {
  color: #707070;
}
.dark-theme .message-support {
  color: #fff;
}
.dark-theme .input-dark-mode {
  background-color: #1c1c1c;
}
.dark-theme v-select {
  background-color: #1c1c1c !important;
}
.img-result {
  width: 100px;
  text-align: end;
}
</style>
