<template>
  <div :class="css_class">
    <div class="input-box">
      <label class="form-label" for="exampleInputPassword1">{{ label }}</label>
      <input
        v-if="!is_show_eye"
        id="exampleInputPassword1"
        v-model="input_value"
        type="password"
        @keyup="sendData"
      />
    </div>
    <div class="input-box">
      <input
        v-if="is_show_eye"
        id="exampleInputPassword1"
        v-model="input_value"
        type="text"
        @keyup="sendData"
      />
    </div>
    <div class="position-relative">
      <button
        class="position-absolute showPasswordBtn"
        type="button"
        @click.prevent="ToggleShow"
      >
        <box-icon v-if="is_show_eye" name="hide"></box-icon>
        <box-icon v-else name="show"></box-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputPasswordHelper",
  props: {
    label: String,
    value: null,
    css_class: String,
  },
  data() {
    return {
      is_show_eye: false,
      input_value: this.value,
    };
  },

  methods: {
    ToggleShow() {
      this.is_show_eye = !this.is_show_eye;
    },
    sendData() {
      this.$emit("input-data", this.input_value);
    },
  },
};
</script>

<style scoped>
#exampleInputPassword1 {
  display: flex;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #eae3e3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.6rem;
  margin-bottom: 1.6rem;
  width: 100%;
}
.showPasswordBtn {
  top: -75px;
  left: 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 35px;
  justify-content: center;
}
input[type="password"] {
  font-family: Helvetica, Arial, sans-serif !important;
  width: 100% !important;
}
</style>
