// import InstructorsIndex from "@/views/Instructors/InstructorsIndex";
// import InstarctorDetails from "@/views/Instructors/InstarctorDetails";
export default [
  {
    path: "/instructors",
    name: "instructors",
    // component: InstructorsIndex,
    component: () => import("@/views/Instructors/InstructorsIndex"),
    meta: {
      title: "المدربين",
    },
  },
  {
    path: "/instructor/:id",
    name: "instructor",
    // component: InstructorDetails,
    component: () => import("@/views/Instructors/InstructorDetails.vue"),
  },
];
