import { render, staticRenderFns } from "./SmallSpringLoading.vue?vue&type=template&id=3cf7ad22&scoped=true&"
import script from "./SmallSpringLoading.vue?vue&type=script&lang=js&"
export * from "./SmallSpringLoading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf7ad22",
  null
  
)

export default component.exports