<template>
  <div class="payments">
    <!-- <hr class="" /> -->
    <div class="img">
      <img
        src="../../assets/images/payment/Kent.png"
        height="30"
        alt="Kent"
        title="Kent"
      />
      <img
        src="../../assets/images/payment/Visa.png"
        height="30"
        alt="Visa"
        title="Visa"
      />
      <img
        src="../../assets/images/payment/Mastercard.png"
        height="30"
        alt="Mastercard"
        title="Mastercard"
      />
      <img
        src="../../assets/images/payment/Paypal.png"
        height="30"
        alt="Paypal"
        title="Paypal"
      />
      <img
        src="../../assets/images/payment/Sadad.png"
        height="30"
        alt="Sadad"
        title="Sadad"
      />
      <img
        src="../../assets/images/payment/NAPS.png"
        height="30"
        alt="NAPS"
        title="NAPS"
      />
      <img
        src="../../assets/images/payment/ApplePay.png"
        height="30"
        alt="NAPS"
        title="NAPS"
      />
      <img
        src="../../assets/images/payment/Fawry.png"
        height="30"
        alt="NAPS"
        title="NAPS"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailablePaymentsComponent",
  components: {},

  data() {
    return {
      active_payment: [
        {
          image: "Kent.png",
          title: "Kent",
        },
        {
          image: "Visa.png",
          title: "Visa",
        },
        {
          image: "Mastercard.png",
          title: "Mastercard",
        },
        {
          image: "Paypal.png",
          title: "Paypal",
        },
        {
          image: "Sadad.png",
          title: "Sadad",
        },
        {
          image: "NAPS.png",
          title: "NAPS",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.payments {
  .img {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      justify-content: center;
    }
    img {
      margin-inline: 5px;
      margin-bottom: 10px;
    }
  }
}
</style>
