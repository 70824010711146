<template>
  <div class="text-lesson">
    <div class="text-lesson-head">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: #818894"
      >
        <path
          d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"
        ></path>
      </svg>
      <p>إضافة درس نصي جديد</p>
    </div>
    <div class="chapter-content">
      <div class="input-groups">
        <p class="title">اللغة</p>
        <b-form-select
          v-model="fileGroup.locale"
          :options="lang"
        ></b-form-select>
      </div>

      <div class="mt-3">
        <GlobalInput type="text" label="العنوان" v-model="fileGroup.title" />
      </div>
      <div class="mt-3">
        <GlobalInput type="text" label="الصورة" v-model="fileGroup.image" />
      </div>
      <div class="mt-3">
        <GlobalInput
          type="text"
          label="المسار"
          v-model="fileGroup.attachments"
        />
      </div>
      <div class="mt-3">
        <GlobalInput
          type="number"
          label="وقت الدراسة"
          v-model="fileGroup.study_time"
        />
      </div>

      <div class="mt-3">
        <div class="form-check p-0">
          <p>إختر إمكانية الوصول</p>
          <label class="form-check-label button-checkbox">
            <input
              type="radio"
              class="form-check-input visually-hidden"
              name="accessibility"
              id="free"
              checked
              value="free"
              v-model="fileGroup.accessibility"
            />
            <span class="button-label">مجانى</span>
          </label>
          <span class="mx-2"></span>
          <label class="form-check-label button-checkbox">
            <input
              type="radio"
              class="form-check-input visually-hidden"
              name="accessibility"
              id="paid"
              value="paid"
              v-model="fileGroup.accessibility"
            />
            <span class="button-label">غير مجانى</span>
          </label>
        </div>
      </div>
      <div class="d-flex mt-4 gap-2 align-items-center">
        <p>تفعيل</p>
        <label class="custom-switch-container">
          <input
            v-model="fileGroup.status"
            type="checkbox"
            class="custom-control-success"
            name="check-button"
            switch
            :unchecked-value="0"
            :value="0"
          />
          <span class="custom-switch-slider"> </span>
        </label>
      </div>
      <div class="mt-3">
        <p>نبذة مختصرة</p>
        <b-textarea v-model="fileGroup.summary" />
      </div>
      <div class="mt-3">
        <p>المحتوى:</p>
        <b-textarea v-model="fileGroup.content" />
      </div>
    </div>
    <div class="d-flex align-items-center gap-3">
      <b-button
        class="btn btn-coursestore-primary mt-4"
        @click="storeTextLessons()"
        >حفظ
        <small-spring-loading :is-loading="loadingStore"></small-spring-loading>
      </b-button>
      <b-button
        class="btn btn-coursestore-primary mt-4"
        @click="updateTextLesson()"
        >تعديل
        <small-spring-loading
          :is-loading="loadingUpdateStore"
        ></small-spring-loading>
      </b-button>
      <b-button
        class="btn btn-coursestore-primary mt-4"
        @click="deleteTextLesson()"
        >حذف
        <small-spring-loading
          :is-loading="loadingDeleteStore"
        ></small-spring-loading>
      </b-button>
    </div>
  </div>
</template>

<script>
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import upload_courses_request from "@/api/upload_courses_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import Vue from "vue";
export default {
  components: {
    GlobalInput,
    SmallSpringLoading,
  },
  props: {
    chapterId: { type: [Number, null] },
  },
  name: "text-lesson",
  data() {
    return {
      lang: ["ar"],

      fileGroup: {
        locale: "",
        image: "",
        title: "",
        study_time: Number,
        accessibility: "",
        attachments: "",
        status: 0,
        summary: "",
        content: "",
        chapterId: this.chapterId,
      },
      loadingStore: false,
      loadingUpdateStore: false,
      loadingDeleteStore: false,
      textLessonId: Number,
    };
  },
  methods: {
    formData() {
      const formData = new FormData();
      formData.append("webinar_id", this.$route.query.webinar_id);
      formData.append("chapter_id", this.fileGroup.chapterId);
      formData.append("status", this.fileGroup.status ? 1 : 0);
      formData.append("locale", this.fileGroup.locale);
      formData.append("study_time", this.fileGroup.study_time);
      formData.append("title", this.fileGroup.title);
      formData.append("image", this.fileGroup.image);
      formData.append("attachments", this.fileGroup.attachments);
      formData.append("summary", this.fileGroup.summary);
      formData.append("content", this.fileGroup.content);

      formData.append("accessibility", this.fileGroup.accessibility);

      return formData;
    },

    async storeTextLessons() {
      this.loadingStore = true;
      await upload_courses_request
        .storeTextLesson(this.formData())
        .then((res) => {
          this.textLessonId = res.data.data.id;
          console.log(this.textLessonId);
          Vue.swal.fire(res.data.message);
        });
      this.loadingStore = false;
    },

    async updateTextLesson() {
      if (this.textLessonId) {
        this.loadingUpdateStore = true;
        await upload_courses_request
          .updateTextLesson(this.textLessonId, this.formData())
          .then((res) => {
            Vue.swal.fire(res.data.message);
          })
          .catch(() => {
            Vue.swal.fire("خطاء فى التعديل");
          });
        this.loadingUpdateStore = false;
      } else {
        Vue.swal.fire("يرجى اضافة درس نصي");
      }
    },

    async deleteTextLesson() {
      if (this.textLessonId) {
        this.loadingDeleteStore = true;
        await upload_courses_request
          .deleteTextLesson(this.textLessonId)
          .then(() => {
            this.$swal({
              title: "هل تريد الحذف",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "تأكيد",
              cancelButtonText: "إلغاء",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
            });
          });
      }
      this.loadingDeleteStore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-lesson {
  background-color: #fff;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  .text-lesson-head {
    display: flex;
    gap: 5px;
    padding: 5px;
    p {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }
  }
  .chapter-content {
    max-width: 800px;
    input,
    select,
    b-textarea {
      width: 100%;
      border: 1px solid #ddd;
      padding: 5px;
      outline: none;
      border-radius: 4px;
    }
  }
  .title {
    display: block;
  }

  // radio button
  .button-checkbox .button-label {
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    padding: 5px 13px !important;
    cursor: pointer;
  }
  .button-checkbox input:checked + .button-label {
    background-color: #1ecad3;
    color: #fff;
  }

  .button-checkbox input:not(:checked) + .button-label {
    background-color: #fff;
    color: #000;
  }
}
</style>
