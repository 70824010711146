import axios from "axios";

export default {
  async fetchAll(status, limit = 10) {
    return await axios.get("/panel/notifications", {
      params: {
        status: status,
        limit: limit,
      },
    });
  },
  async delete(id) {
    return await axios.delete(`/panel/notifications/${id}`);
  },
  async deleteAll() {
    return await axios.delete(`/panel/notifications/delete/all`);
  },
};
