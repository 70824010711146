<template>
  <div class="container">
    <div class="mx-auto">
      <div class="text-center py-4">
        <img
          class="img-fluid"
          src="../../assets/images/404.png"
          alt="يرجى البحث عن الصفحة المطلوبة مرة أخرى"
        />
        <h2 class="mb-4">يرجى البحث عن الصفحة المطلوبة مرة أخرى</h2>
        <h6>اكتشف صفحات أخرى مميزة في الموقع</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotfoundView",
};
</script>

<style scoped></style>
