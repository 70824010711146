<template>
  <div>
    <div class="mx-auto">
      <small-spring-loading :is-loading="loading"></small-spring-loading>
    </div>
    <div
      v-if="Object.keys(onePage).length > 0"
      class="mt-5 background-page"
      :style="{
        'background-image': `url(${config.pages_back_ground.blog})`,
      }"
    >
      <h2 v-html="onePage.title" class="title"></h2>
    </div>
    <div class="container" v-if="Object.keys(onePage).length > 0">
      <h1 v-html="onePage.title" class="text-light-dark-mode"></h1>
      <div v-html="onePage.content" class="text-gray-dark-mode"></div>
    </div>
    <div v-if="notfound_message !== ''" class="text-center h3">
      {{ notfound_message }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";

export default {
  name: "PageComponent",
  components: { SmallSpringLoading },
  props: {
    link: { type: String, default: "" },
  },
  data() {
    return {
      onePage: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters("site", ["config"]),
    ...mapGetters("page", ["page", "notfound_message"]),
  },
  methods: {
    ...mapActions("page", ["getPage"]),
    async getOnePage() {
      await this.getPage(this.link).finally(() => {
        this.onePage = this.page[this.link];
        this.loading = false;
      });
    },
  },
  created() {
    this.getOnePage();
  },
  watch: {
    link(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.page[this.link] != null) {
          this.onePage = this.page[this.link];
        } else {
          this.getOnePage();
        }
      }
    },
  },
};
</script>

<style scoped>
.background-page {
  height: 250px;
  background-size: cover;
  position: relative;
  margin-bottom: 20px;
  box-shadow: rgb(0 0 0 / 15%) 0 5px 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-page .title {
  color: #fff;
  z-index: 2;
  font-size: 40px;
}
.background-page::after {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
