<template>
  <div class="scroll-table-mobile">
    <!-- <h6 class="head fs-5 mb-4 text-light-dark-mode"></h6> -->
    <div
      class="breadcrumb-item me-0 me-md-5 ms-0 py-3 d-flex align-items-center align-content-center"
    >
      <a
        href="#"
        class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
      >
        <h6 class="head fs-5 text-light-dark-mode mb-0">الإشتراكات</h6>
      </a>
      <img
        src="../../assets/icon/arrow-left.svg"
        alt="arrow-left"
        class="mx-2"
      />
    </div>
    <b-tabs card v-if="subscribed !== false">
      <b-tab title="تفاصيل الإشتراكات">
        <div class="mt-4">
          <div class="box">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center gap-2">
                <div class="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-calendar"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </div>
                <div class="box_info d-flex flex-column">
                  <p class="title">نوع الإشتراك</p>
                  <span class="data">{{ subscribed.title }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2">
                <div class="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-calendar"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </div>
                <div class="box_info d-flex flex-column">
                  <p class="title">مدة الإشتراك</p>
                  <span class="data">{{ subscribed.days }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2">
                <div class="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-calendar"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </div>
                <div class="box_info d-flex flex-column">
                  <p class="title">الأيام المتبقية</p>
                  <span class="data">{{ subscribed.days }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2">
                <div class="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-calendar"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </div>
                <div class="box_info d-flex flex-column">
                  <p class="title">تاريخ الإشتراك</p>
                  <span class="data">{{ subscribed.created_at }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2">
                <div class="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-calendar"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </div>
                <div class="box_info d-flex flex-column">
                  <p class="title">تاريخ الإنتهاء</p>
                  <span class="data">{{ subscribed.created_at }}</span>
                </div>
              </div>
              <div>
                <button class="cancel-btn">إلغاء الإشتراك</button>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div v-else>لا يوجد لديك اشتراك</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileNotifications",

  computed: {
    ...mapGetters("subscribe", ["subscribes", "subscribed"]),
  },
  methods: {
    ...mapActions("subscribe", ["getSubscribes"]),
  },
  created() {
    this.getSubscribes();
  },
};
</script>

<style scoped>
.box {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: 8px;
  padding: 30px;
}
.dark-theme .box {
  background-color: #1c1c1c;
}
@media (max-width: 768px) {
  .box div {
    flex-direction: column !important;
    align-items: start !important;
    justify-content: start;
    gap: 20px;
    text-align: center;
  }
}
.title {
  font-size: 15px;
  color: #410099;
  font-weight: bold;
  margin-bottom: 5px;
}
.dark-theme .title {
  color: #fff;
}
.data {
  font-size: 10px;
  color: #707070;
  font-weight: bold;
}
.cancel-btn {
  background-color: #f2320d;
  color: #fff;
  padding: 5px 30px;
  border-radius: 7px;
}
</style>
