<template>
  <div class="row d-flex justify-content-center">
    <div class="col-12">
      <div class="card card-bordered">
        <div class="card-header">
          <h4 class="card-title"><strong>محادثة</strong></h4>
          <leave-chat-button-component
            :is-join="isJoin"
          ></leave-chat-button-component>
        </div>

        <div
          class="ps-container ps-theme-default ps-active-y"
          id="chat-content"
          ref="chatContainer"
          style="overflow-y: scroll !important; height: 400px !important"
        >
          <div v-for="(item, index) in chat_messages" :key="index">
            <my-message-component
              v-if="item.from === 'me'"
              :item="item"
            ></my-message-component>
            <user-message-component
              :item="item"
              v-if="item.from === 'other'"
            ></user-message-component>
          </div>
        </div>
        <img
          v-if="isTyping"
          src="../../assets/icon/live/chat-bub.svg"
          alt="chat"
          class="mx-4"
          style="width: 24px"
        />
        <div class="publisher bt-1 border-light" v-if="isJoin">
          <img
            class="avatar avatar-xs"
            src="../../assets/avatar.png"
            alt="avatar"
          />
          <input
            class="publisher-input w-100"
            type="text"
            v-model="message"
            placeholder="النص هنا"
          />

          <a
            v-if="message !== ''"
            class="publisher-btn text-info"
            @click.stop="sendChannelMessage"
          >
            <img src="../../assets/icon/live/send.svg" alt="send" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RtmClient from "agora-rtm-sdk";
import MyMessageComponent from "@/components/Chat/MyMessageComponent.vue";
import UserMessageComponent from "@/components/Chat/UserMessageComponent.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import axios from "axios";
import LeaveChatButtonComponent from "@/components/Chat/Buttons/LeaveChatButtonComponent.vue";
export default {
  name: "ChatComponent",
  components: {
    LeaveChatButtonComponent,
    UserMessageComponent,
    MyMessageComponent,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  props: {
    session_id: { type: Number, default: 0 },
    reserve_meeting_id: { type: Number, default: 0 },
    webinar_id: { type: Number, default: 0 },
    accountName: { type: String, default: "" },
    channelName: { type: String, default: "" },
    appId: { type: String, default: "" },
    rtcToken: { type: String, default: "" },
    rtmToken: { type: String, default: "" },
    uid: { type: Number, default: 0 },
    isHost: { type: Boolean, default: false },
  },
  data() {
    return {
      isTyping: false,
      client: "",
      isLogin: false,
      isJoin: false,
      channel: "",
      isConnected: false,
      message: "",
      chat_messages: [],
    };
  },
  methods: {
    getAllMessage: async function () {
      await axios
        .get("panel/chat/", {
          params: {
            webinar_id: this.webinar_id,
            session_id: this.session_id,
            reserve_meeting_id: this.reserve_meeting_id,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              this.chat_messages.push({
                message: item.message,
                from: item.sender.id === this.user.id ? "me" : "other",
                user: item.sender.id,
                time: item.time,
                avatar: item.sender.avatar,
                full_name: item.sender.full_name,
              });
            });
          }
        })
        .finally(() => {
          this.scrollToBottom();
        });
    },
    sendMessage: async function (payload) {
      axios.post("panel/chat", payload).then((res) => {
        console.log("sendMessage", res.data);
      });
    },
    login: async function () {
      let that = this;
      try {
        await this.client
          .login({ uid: this.accountName, token: this.rtmToken })
          .then(async () => {
            that.isConnected = this.client.connectionState === "CONNECTED";
            that.isLogin = this.isConnected;
            console.log(`login response`);
            await that.joinChannel();
          })
          .catch(async (err) => {
            this.$swal.fire({
              icon: "error",
              title: err.message,
            });
            that.isLogin = false;
            // await that.client.logout();
          });
      } catch (err) {
        this.$swal.fire({
          icon: "error",
          title: err.message,
        });
        that.isLogin = false;
      }
    },
    logout: async function () {
      let that = this;
      await this.client.logout().then(() => {
        that.isLogin = false;
        that.isConnected = false;
        that.leaveChannel();
      });
    },
    joinChannel: async function () {
      let that = this;
      await this.channel.join().then(() => {
        that.isJoin = this.channel.joinState === "JOINED";
        console.log("console join", this.channel.channelId);
      });
    },
    leaveChannel: async function () {
      if (!this.channel || (this.channel && !this.isJoin)) return;
      let that = this;
      await this.channel.leave().then(async () => {
        console.log("leaveChannel", this.channelName);
        that.isJoin = false;
        await that.logout();
      });
    },
    sendChannelMessage: async function () {
      if (!this.channel || !this.isJoin) return;
      let that = this;

      await this.channel
        .sendMessage({ text: this.message, description: this.uid })
        .then(() => {
          console.log("sendChannelMessage", this.channelName, this.message);
          let now = moment();
          that.chat_messages.push({
            message: this.message,
            from: "me",
            user: this.uid,
            time: now.format("HH:mm:ss"),
          });
          this.sendMessage({
            webinar_id: this.webinar_id,
            session_id: this.session_id,
            reserve_meeting_id: this.reserve_meeting_id,
            message: this.message,
            to_id: this.uid,
          });
          this.message = "";
          this.scrollToBottom();
        });
    },
    sendPeerMessage: async function (peerId) {
      console.log("sendPeerMessage", this.message, peerId);
      await this.client.sendMessageToPeer(
        { text: this.message },
        peerId.toString()
      );
    },
    scrollToBottom() {
      setTimeout(() => {
        let objDiv = document.getElementById("chat-content");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 0);
    },
  },
  mounted() {
    let that = this;
    this.$root.$on("callEvent", function (payload) {
      console.log("callEvent", payload);
    });
    this.$root.$on("UserLeaveLive", function () {
      console.log("UserLeaveLive");
      that.leaveChannel();
    });
  },
  created() {
    this.getAllMessage();
    if (this.client !== null && this.client.connectionState === "CONNECTED") {
      this.logout();
    }
    if (this.appId !== "") {
      let that = this;

      this.client = RtmClient.createInstance(this.appId);

      const channel = this.client.createChannel(this.channelName);
      this.channel = channel;
      if (channel !== null) {
        this.login();
      }

      channel.on("ChannelMessage", async function (message, memberId) {
        console.log(`emit ChannelMessage`, "ChannelMessage", message, memberId);
        that.isTyping = true;
        let now = moment();
        let payload = {
          message: message.text,
          from: "other",
          user: memberId,
          time: now.format("HH:mm:ss"),
        };
        let user_id = memberId.replace(/^\D+/g, "");
        console.log("getUserAttributes", user_id);

        await axios
          .get("users/" + user_id + "/profile", {
            params: { less_data: "true" },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.data.user) {
                let data = response.data.data.user;
                payload.full_name = data.full_name;
                payload.avatar = data.avatar;
              }
            }
          })
          .finally(() => {
            that.chat_messages.push(payload);
            that.isTyping = false;
            that.scrollToBottom();
          });
      });
      // Display channel member stats
      channel.on("MemberJoined", function (memberId) {
        console.log(`emit MemberJoined`, "MemberJoined", memberId);
      });
      // Display channel member stats
      channel.on("MemberLeft", function (memberId) {
        console.log(`emit MemberLeft`, "MemberLeft", memberId);
      });

      this.client.on("AttributesUpdated", function (args) {
        console.log(`AttributesUpdated ${args}`);
        that.$root.$emit("callEvent", "AttributesUpdated");
      });
      this.client.on("MemberCountUpdated", function (args) {
        console.log(`MemberCountUpdated ${args}`);

        that.$root.$emit("callEvent", "MemberCountUpdated");
      });
      this.client.on("MetaDataUpdated", function (args) {
        console.log(`MetaDataUpdated ${args}`);
        that.$root.$emit("callEvent", "MetaDataUpdated");
      });
      this.client.on("MessageFromPeer", function (args) {
        console.log(`MessageFromPeer ${args}`);
        that.$root.$emit("callEvent", "MessageFromPeer");
      });
      this.client.on("ConnectionStateChanged", function (args) {
        console.log(`ConnectionStateChanged ${args}`);
        that.$root.$emit("callEvent", "ConnectionStateChanged");
      });
      this.client.on("RemoteInvitationReceived", function (args) {
        console.log(`RemoteInvitationReceived ${args}`);
        that.$root.$emit("callEvent", "RemoteInvitationReceived");
      });
      this.client.on("TokenExpired", function (args) {
        console.log(`TokenExpired ${args}`);
        that.$root.$emit("callEvent", "TokenExpired");
      });
      this.client.on("TokenPrivilegeWillExpire", function (args) {
        console.log(`TokenPrivilegeWillExpire ${args}`);
        that.$root.$emit("callEvent", "TokenPrivilegeWillExpire");
      });
      this.client.on("PeersOnlineStatusChanged", function (args) {
        console.log(`PeersOnlineStatusChanged ${args}`);
        that.$root.$emit("callEvent", "PeersOnlineStatusChanged");
      });
    }
  },
};
</script>

<style scoped>
.card-bordered {
  border: 1px solid #ebebeb;
}

.card {
  border: 0;
  border-radius: 0;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.card-header .card-title {
  padding: 0;
  border: none;
}

h4.card-title {
  font-size: 17px;
}

.card-header > *:last-child {
  margin-right: 0;
}

.card-header > * {
  margin-left: 8px;
  margin-right: 8px;
}

.card-title {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.ps-container {
  position: relative;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.border-light {
  border-color: #f1f2f3 !important;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 2px;
  background-color: #f9fafb;
}

.publisher > *:first-child {
  margin-left: 0;
}

.publisher > * {
  margin: 0 8px;
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none !important;
  background-color: transparent;
}
.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.text-info {
  color: rgb(30, 188, 198) !important;
}
</style>
