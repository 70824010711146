<template>
  <!-- payment modal -->
  <div>
    <VueModal
      v-model="doShowModal"
      @close="doShowModal = false"
      modalStyle="max-width:450px;min-height: 352px; border-radius:10px!important"
      modalClass="modal_popup fast-Pay"
    >
      <div id="payment">
        <div class="container">
          <form class="m-4 m-md-0">
            <small-spring-loading
              :is-loading="showSpinner"
            ></small-spring-loading>
            <div v-show="isShowCouponBox">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control input-dark-mode shadow-none ps-2"
                  placeholder="أدخل الكوبون"
                  v-model="code"
                />
                <div class="input-group-append ms-2">
                  <button
                    @click.prevent="doCouponValidate()"
                    class="btn align-items-center py-2 bg-secondary text-whitebtn-dark-mode"
                  >
                    <span
                      v-show="loadingValidCoupon"
                      class="spinner-grow spinner-grow-sm text-light"
                    ></span>
                    <span class="text-light"> أدخل الكوبون</span>
                  </button>
                  <button
                    v-show="isUsingCoupon"
                    @click.prevent="doCouponDelete()"
                    class="btn align-items-center mx-1 py-2 bg-danger text-white"
                  >
                    <span class="text-light"> حذف</span>
                  </button>
                </div>
              </div>
              <div
                class="text-danger py-1 my-2"
                v-show="validCouponErrorMessage"
              >
                {{ validCouponErrorMessage }}
              </div>
              <!-- <hr class="text-dark" /> -->
            </div>
            <div class="d-flex justify-content-between mt-3 mb-2">
              <p class="mb-0">السعر قبل الخصم</p>
              <p class="mb-0 margin-price">
                {{ priceDetails.sub_total }}
              </p>
            </div>
            <!-- <hr class="text-dark" /> -->
            <div class="d-flex justify-content-between mb-2">
              <p class="mb-0">خصم</p>
              <p class="mb-0 margin-price">
                {{ priceDetails.total_discount }}
              </p>
            </div>
            <!-- <hr class="text-dark" /> -->
            <div class="d-flex justify-content-between mb-2">
              <p class="mb-0">الضرائب (% {{ priceDetails.tax }} )</p>
              <p class="mb-0 margin-price">
                {{ priceDetails.tax_price }}
              </p>
            </div>
            <!-- <hr class="text-dark" /> -->
            <div class="d-flex justify-content-between mb-2">
              <p class="mb-0">الصافي</p>
              <p class="mb-0 margin-price">
                {{ priceDetails.total }} {{ priceDetails.currencyCode }}
              </p>
            </div>
            <!-- <hr class="text-dark" /> -->
            <div
              class="overflow-hidden"
              v-if="active_payment.length > 0 && priceDetails.total > 0"
            >
              <div
                class="text-center align-items-center payment-imgs rounded text-center"
              >
                <div
                  class="hover-primary p-2 m-1 rounded"
                  :key="index"
                  v-for="(payment, index) in active_payment"
                >
                  <div>
                    <img
                      :alt="payment.title"
                      style="width: 55px; cursor: pointer"
                      v-if="payment.title === 'Fawry'"
                      @click="showFawry(payment)"
                      :src="payment.image"
                    />
                    <img
                      v-else
                      @click.stop="
                        doPay(
                          payment.id,
                          payment.class_name,
                          payment.PaymentMethodId
                        )
                      "
                      class="paypal cursor-pointer"
                      :src="payment.image"
                      :alt="payment.title"
                      :title="payment.title"
                    />
                  </div>
                  <p class="pay-name">{{ payment.title }}</p>
                </div>
              </div>
            </div>
            <div v-show="isShowPayNowZeroBtn" class="overflow-hidden">
              <button
                @click.stop="doPay()"
                class="btn-coursestore btn w-100"
                type="button"
              >
                <img src="../../assets/icon/cs-mouse.svg" alt="click" />
                <span class="text-light">الدفع</span>
              </button>
            </div>

            <!-- <div
              v-if="isShow()"
              class="d-flex justify-content-between flex-column"
            >
              <div
                class="mt-4 hero-rec me-2 mb-3"
                v-for="(subscribe, index) in subscribes"
                :key="index"
              >
                <div
                  class="d-flex justify-content-between mt-3 fs-10 container"
                >
                  <h5 class="text-white mt-2 me-2">{{ subscribe.title }}</h5>
                  <div class="ms-auto d-flex">
                    <div class="me-1">
                      <div class="numerator text-white" v-if="config.currency">
                        {{ config.currency.name }}
                      </div>
                      <div class="denominator text-white">
                        {{ subscribe.AmountWithLabel.label }}
                      </div>
                    </div>
                    <h2 class="text-white mt-1">
                      {{ subscribe.AmountWithLabel.price }}
                    </h2>
                  </div>
                </div>
              </div>
            </div> -->
          </form>
        </div>
      </div>
    </VueModal>
    <VueModal
      v-model="showMyModal"
      modalStyle="max-width:500px;min-height: 400px; padding: 20px"
      modalClass="play_course_popup fawry-modal"
      title=" الدفع عن طريق فورى"
    >
      <div>
        <small-spring-loading :is-loading="showSpinner"></small-spring-loading>
        <b-form-radio
          class="mb-3"
          v-model="fawryType"
          name="credit-radios"
          value="invoice"
        >
          فورى</b-form-radio
        >
        <b-form-radio v-model="fawryType" name="fawry-radios" value="card"
          >البطاقة البنكية</b-form-radio
        >
        <form action="">
          <div v-if="fawryType === 'card'">
            <div class="mt-4"></div>
            <div class="mt-4">
              <GlobalInput
                type="text"
                label="رقم البطاقة"
                v-model="formattedCardNumber"
              />
            </div>
            <div class="mt-4">
              <GlobalInput
                type="number"
                label=" الرقم السري (CVV)"
                v-model="ccv"
              />
            </div>
            <div class="d-flex align-items-center justify-content-between mt-4">
              <div>
                <!-- <GlobalInput
                type="date"
                label="تاريخ الانتهاء"
                v-model="expiry_month"
              /> -->
                <div class="form-group">
                  <label>تاريخ الانتهاء</label>
                  <input class="form-control" v-cardformat:formatCardExpiry />
                </div>
              </div>
              <div>
                <label> السنة</label>
                <select v-model="expiry_year" id="cardYear">
                  <option v-for="year in yearOptions" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <b-button
              v-if="this.fawryPayment !== null"
              @click.stop="doPay(fawryPayment.id, fawryPayment.class_name, 0)"
              >ادفع
            </b-button>
          </div>
        </form>
      </div>
    </VueModal>
  </div>

  <!-- payment modal -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import VueModal from "@kouts/vue-modal";
import appConfig from "../../config";
import store from "@/store";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import cart_request from "@/api/cart_request";

export default {
  name: "FastPayModal",
  components: { SmallSpringLoading, VueModal, GlobalInput },
  props: {
    showModal: { type: Boolean, default: false },
    id: {
      type: Object,
      default: () => {
        return { webinar_id: null, bundle_id: null };
      },
    },
  },
  data() {
    return {
      selected: true,
      fawryType: "invoice",
      showMyModal: false,
      doShowModal: false,
      yearOptions: [],
      priceDetails: {
        sub_total: 0,
        total_discount: 0,
        tax: 0,
        tax_price: 0,
        total: 0,
        currencyCode: "",
        discount_id: 0,
      },
      code: "",
      data_webinar_id: null,
      loadingValidCoupon: false,
      validCouponErrorMessage: "",
      showSpinner: true,
      isUsingCoupon: false,
      isShowCouponBox: false,
      isShowPayNowZeroBtn: false,
      card_no: "",
      ccv: "",
      expiry_month: "",
      expiry_year: "",
      fawryPayment: null,
    };
  },
  created() {
    // Set up the year options
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.yearOptions.push(currentYear + i);
    }
  },
  computed: {
    ...mapGetters("site", ["active_payment", "config"]),
    ...mapGetters("subscribe", ["subscribes"]),
    formattedCardNumber: {
      get() {
        // Remove all non-digit characters from the card number
        let number = this.card_no.replace(/\D/g, "");
        // Add a hyphen between every four digits
        number = number.replace(/(\d{4})/g, "$1-");
        // Remove any trailing hyphen
        number = number.replace(/-$/, "");
        // Return the formatted card number
        return number;
      },
      set(value) {
        this.card_no = value.replace(/\D/g, "");
      },
    },
  },
  methods: {
    ...mapActions("cart", ["couponValidate", "getInfo", "getList"]),
    showFawry(payment) {
      this.fawryPayment = payment;
      this.showMyModal = true;
      this.doShowModal = false;
    },
    isShow: function () {
      return (
        this.config.home_sections !== undefined &&
        (this.config.home_sections.subscribes !== undefined ||
          this.config.home_sections.subscribes === "1")
      );
    },
    async doCouponValidate() {
      this.loadingValidCoupon = true;
      this.showSpinner = true;
      this.validCouponErrorMessage = "";
      let params = {
        coupon: this.code,
        webinar_id:
          this.data_webinar_id != null ? this.data_webinar_id.webinar_id : null,
        bundle_id:
          this.data_webinar_id != null ? this.data_webinar_id.bundle_id : null,
      };

      let response = await this.couponValidate(params).finally(() => {
        this.loadingValidCoupon = false;
        this.showSpinner = false;
        this.isShowCouponBox = true;
      });

      if (response.status === 200) {
        if (response.data.success === true) {
          //message
          this.priceDetails = response.data.data.amounts;
          this.isUsingCoupon = true;
        } else if (response.data.success === false) {
          if (response.data.status === "unauthorized") {
            store.commit("auth/SET_IS_LOGIN", false);
          }

          this.$swal.fire({
            icon: "error",
            title: response.data.message,
          });
          //message
          this.validCouponErrorMessage = response.data.message;
        }
        this.isShowPayNowZeroBtn =
          this.priceDetails.total <= 0 && this.priceDetails.total_discount > 0;
      }
    },
    async getPriceTotal() {
      this.showSpinner = true;
      let params = {
        webinar_id:
          this.data_webinar_id !== null
            ? this.data_webinar_id.webinar_id
            : null,
        bundle_id:
          this.data_webinar_id !== null ? this.data_webinar_id.bundle_id : null,
        reserve_meeting_id:
          this.data_webinar_id !== null
            ? this.data_webinar_id.reserve_meeting_id
            : null,
        ticket_id:
          this.data_webinar_id !== null ? this.data_webinar_id.ticket_id : null,
      };
      let response = await this.getInfo(params).finally(() => {
        this.showSpinner = false;
        this.isUsingCoupon = false;
        this.isShowCouponBox = true;
        this.getList();
      });
      if (response.status === 200) {
        if (response.data.success === true) {
          //message
          //this.doShowModal = true;
          this.priceDetails = response.data.data;
        } else if (response.data.success === false) {
          //message
          if (response.data.status === "unauthorized") {
            store.commit("auth/SET_IS_LOGIN", false);
          }
          if (response.data.status === "already_bought") {
            this.doShowModal = false;
          }
          this.$swal.fire({
            icon: "error",
            title: response.data.message,
          });
        }
        this.isShowPayNowZeroBtn =
          this.priceDetails.total <= 0 && this.priceDetails.total_discount > 0;
      } else {
        this.doShowModal = false;
        this.isShowPayNowZeroBtn = false;
      }
    },
    async doPay(id = "", class_name = "", method_id = 0) {
      this.showSpinner = true;
      await cart_request
        .checkout({
          gateway_id: id,
          webinar_id:
            this.data_webinar_id != null
              ? this.data_webinar_id.webinar_id
              : null,
          bundle_id:
            this.data_webinar_id != null
              ? this.data_webinar_id.bundle_id
              : null,
          PaymentMethodId: method_id,
          discount_id: this.priceDetails.discount_id,
          back_url: appConfig.VERIFY_URL + class_name,
          type: this.fawryType,
          card_no: this.card_no,
          expiry_month: this.expiry_month,
          expiry_year: this.expiry_year,
          ccv: this.ccv,
        })
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            if (response.data.status === "paid") {
              window.location.href =
                appConfig.VERIFY_URL + "?order_id=" + response.data.data.id;
            } else {
              window.location.href = response.data.data;
            }
          }
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    doCouponDelete() {
      this.getPriceTotal();
    },
    resetPriceDetails: function () {
      this.priceDetails = {
        discount_id: 0,
        sub_total: 0,
        total_discount: 0,
        tax: 0,
        tax_price: 0,
        total: 0,
        currencyCode: "",
      };
    },
  },
  mounted() {
    let _this = this;
    this.$root.$on("showFastPayModal", function (id) {
      _this.doShowModal = true;
      _this.data_webinar_id = id;

      _this.getPriceTotal();
    });
  },
  watch: {
    data_webinar_id(oldData, newData) {
      if (oldData !== newData) {
        this.resetPriceDetails();
      }
    },
  },
};
</script>

<style scoped lang="scss">
#cardYear {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}
.hero-rec:nth-child(odd),
.pe-1:nth-child(odd) .hero-rec {
  cursor: pointer;
  background-color: var(--hero-rec-1);
  padding: 1px;
  border-radius: 5px;
}

.hero-rec:nth-child(even),
.pe-1:nth-child(even) .hero-rec {
  cursor: pointer;
  background-color: var(--hero-rec-1);
  padding: 1px;
  border-radius: 5px;
}
.hero-rec:nth-child(odd):hover {
  background-color: var(--hero-rec-2) !important;
}
.hero-rec:nth-child(even):hover {
  background-color: var(--hero-rec-2) !important;
}
.hover-primary:hover {
  background-color: var(--hero-rec-1) !important;
}
.modal_popup {
  border-radius: var(--bs-border-radius) !important;
}
.payment-imgs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
}
.form-pay {
  padding: 10px;
  max-width: 450px !important;
  margin-top: 40px;
}
.pay-name {
  font-size: 9px;
  margin-bottom: 0;
}
.hover-primary:hover .pay-name {
  color: #ffff;
}
</style>
