<template>
  <div>
    <div class="mt-4" v-if="reservations">
      <GlobalTable
        :data.sync="reservations"
        :fields="reservationFields"
        :show-button="false"
      >
        <template #cell(user)="data">
          <img
            :src="data.item.instructor.avatar"
            class="user-avatar"
            alt="avatar"
          />
          <span>{{ data.item.instructor.full_name }}</span>
        </template>
        <template #cell(date)="data">
          <span>{{ convertDate(data.item.date) }}</span>
        </template>
        <template #cell(time)="data">
          <span>{{ data.item.time.start }}</span>
          <span>{{ data.item.time.end }}</span>
        </template>
        <template v-slot:endMeeting="objItem">
          <a
            v-if="objItem.itemObj.item.link !== null"
            :href="objItem.itemObj.item.link"
            class="join-link links-mode-color"
            >انضمام</a
          >
          <button
            class="links-mode-color"
            v-if="objItem.itemObj.item.session_type === 'jitsi'"
            @click="startLiveSession(objItem.itemObj.item.id)"
          >
            انضمام
          </button>
          <button @click.prevent="gotCalender(objItem.itemObj.item.id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: #818894"
            >
              <path
                d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM5 7h14v2H5V7z"
              ></path>
            </svg>
          </button>
          <button
            style="background-color: transparent"
            @click="showInstructorInfo(objItem.itemObj)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
              />
              <path
                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
              />
            </svg>
          </button>
          <img
            v-if="user !== null && user.role_name === 'user'"
            src="../../../assets/icon/editMeeting.svg"
            style="cursor: pointer"
            @click="showReserveMeeting(objItem.itemObj)"
          />
          <button
            v-if="user !== null && user.role_name === 'teacher'"
            class="links-mode-color"
            @click="finishMeeting(objItem.itemObj.item.meeting.id)"
          >
            إنهاء
          </button>
        </template>
      </GlobalTable>
      <VueModal
        v-model="showInstructorModal"
        modalStyle="max-width:450px;min-height: 50px; padding: 20px; border-radius: 10px"
        modalClass="play_course_popup d"
        title="معلومات الإتصال"
      >
        <div v-if="selectedCellInfo">
          <div class="text-center">
            <img
              :src="selectedCellInfo.item.instructor.avatar"
              class="student-avatar"
            />
            <span class="student-name">{{
              selectedCellInfo.item.instructor.full_name
            }}</span>
          </div>
          <div class="w-75 m-auto">
            <div class="d-flex align-items-center justify-content-between mt-4">
              <span class="text-left mr-15 flex-grow-1">الإيميل :</span>
              <span class="text-right">{{
                selectedCellInfo.item.instructor.email
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-4">
              <span class="text-left mr-15 flex-grow-1">الهاتف :</span>
              <span class="text-right">{{
                selectedCellInfo.item.instructor.mobile
              }}</span>
            </div>
          </div>
        </div>
      </VueModal>
      <VueModal
        v-model="updateReserveMeeting"
        modalStyle="max-width:450px;min-height: 50px; padding: 20px; border-radius: 10px"
        modalClass="play_course_popup d"
        title="تعديل موعد الاستشارة"
      >
        <div v-if="selectedCellReserveInfo">
          <!-- <GlobalInput
            type="number"
            label="عدد الطلبة"
            v-model="reserveUpdateMeeting.student_count"
            class="w-100 mb-2"
          /> -->
          <GlobalInput
            type="text"
            label="الوصف"
            v-model="reserveUpdateMeeting.description"
            class="w-100 mb-2"
          />
          <div class="col-md-6">
            <p>تاريخ</p>
          </div>
          <v-date-picker
            mode="date"
            v-model="date"
            locale="ar"
            :min-date="new Date()"
            is-expanded
            title-position="left"
            @dayclick="onDayClickHandler"
          />
          <div
            class="d-flex align-items-end flex-wrap"
            v-if="selectedEvents.length > 0"
          >
            <div
              class="form-check"
              v-for="meeting in selectedEvents"
              :key="meeting.id"
            >
              <label class="form-check-label button-checkbox">
                <input
                  type="radio"
                  class="form-check-input visually-hidden"
                  :name="meeting.id"
                  :id="meeting.id"
                  :value="meeting.id"
                  v-model="reserveUpdateMeeting.time_id"
                />
                <span class="button-label">{{ meeting.time }}</span>
              </label>

              <span class="mx-2"></span>
            </div>
            <!-- End Radio Button -->
          </div>
          <button
            @click="updateLastMeetingReserve"
            class="mt-4 btn-update-meeting"
          >
            <small-spring-loading
              :isLoading="loadingUpdateMeeting"
            ></small-spring-loading>
            تعديل
          </button>
        </div>
      </VueModal>
    </div>

    <div v-else class="text-center mt-4">
      <p class="text-light-dark-mode">لا يوجد طلبات جلسة !</p>
      <span class="text-light-dark-mode"
        >إعادة الاستشارت ستظهر فى هذه القائمة
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import meeting_request from "@/api/meeting_request";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import GlobalTable from "@/components/Helper/GlobalTable.vue";
import VueModal from "@kouts/vue-modal";
import moment from "moment";
export default {
  name: "reservation-tab",
  components: {
    GlobalTable,
    VueModal,
    GlobalInput,
    SmallSpringLoading,
  },
  data() {
    return {
      updateReserveMeeting: false,
      selectedCellInfo: null,
      showInstructorModal: false,
      loadingUpdateMeeting: false,
      reservationFields: [
        {
          key: "user",
          label: "المدرب",
          sortable: true,
        },
        {
          key: "meeting_type",
          label: "نوع الإستشارة",
          sortable: true,
        },
        {
          key: "day",
          label: "اليوم",
          sortable: true,
        },
        {
          key: "time",
          label: "الوقت",
          sortable: true,
        },
        {
          key: "date",
          label: "التاريخ",
          sortable: true,
        },
        {
          key: "user_paid_amount",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "student_count",
          label: "عدد الطلاب",
          sortable: true,
        },
        {
          key: "status",
          label: "الحالة",
          sortable: true,
        },
        {
          key: "endMeeting",
          label: "",
          sortable: true,
        },
      ],
      loading: false,
      date: "",

      selectedClickedDate: "",
      instructorTimes: [],
      selectedEvents: [],
      reservations: [],
      timing_group_by_day: [],
      selectedCellReserveInfo: null,
      reserveUpdateMeeting: {
        student_count: 0,
        date: new Date(),
        description: "",
        time_id: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),

    formatDateRereserveMeeting() {
      const year = this.reserveUpdateMeeting.date.getFullYear();
      const month = String(
        this.reserveUpdateMeeting.date.getMonth() + 1
      ).padStart(2, "0");
      const day = String(this.reserveUpdateMeeting.date.getDate()).padStart(
        2,
        "0"
      );
      return `${year}-${month}-${day}`;
    },
  },

  methods: {
    startLiveSession(id) {
      this.$router.push({
        name: "live",
        params: { id: id, type: "meeting" },
      });
    },
    convertDate(createdAt) {
      let date = new Date(createdAt * 1000);
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      // Format as "YYYY-MM-DD"
      return year + "-" + month + "-" + day;
    },
    async gotCalender(id) {
      await meeting_request.saveToCalendar(id).then((res) => {
        const calendarLink = res.data.data;
        window.open(calendarLink, "_blank");
      });
    },

    // getInstructorIdFromInfo(info) {
    //   // Assuming the instructor ID is stored in `info.item.user.id`
    //   return info.item.user.id;
    // },
    onDayClickHandler(day) {
      let currency_date = new Date(moment().format("YYYY-MM-DD").toLowerCase());
      let selectedDate = moment(day.date).format("YYYY-MM-DD").toLowerCase();

      if (new Date(selectedDate) >= currency_date) {
        const clickedDate = moment(day.date).format("dddd").toLowerCase();
        this.selectedClickedDate = selectedDate;
        this.instructorTimes.map((item) => {
          item.newDate = this.convertDate(+item.created_at);
        });
        this.selectedEvents = this.instructorTimes.filter((event) => {
          const eventDate = event.day_label;
          // const eventDate = this.convertDate(+event.created_at);
          return eventDate === clickedDate;
        });
        // Print each event separately
        this.selectedEvents.forEach((event) => {
          return event;
        });
      }
    },
    async showInstructorInfo(info) {
      this.selectedCellInfo = info;
      this.showInstructorModal = true;
    },
    async getInstructorTime(itemId) {
      try {
        await meeting_request
          .getInstructorMeetingList(itemId)
          .then((response) => {
            if (response.status === 200 && response.data.success === true) {
              this.instructorTimes = response.data.data.meeting_times;
            } else {
              console.error("Failed to fetch instructor meeting times.");
            }
          });
      } catch (error) {
        console.error("Error fetching instructor meeting times:", error);
      }
    },
    showReserveMeeting(info) {
      this.selectedCellReserveInfo = info;

      this.updateReserveMeeting = true;
      this.getInstructorTime(info.item.instructor.id);
    },
    async updateLastMeetingReserve() {
      if (!this.selectedCellReserveInfo) {
        console.error("selectedCellReserveInfo is null");
        return;
      }

      const payload = {
        time_id: this.reserveUpdateMeeting.time_id,
        date: this.selectedClickedDate,
        meeting_type: "online",
        student_count: this.reserveUpdateMeeting.student_count,
        description: this.reserveUpdateMeeting.description,
      };

      try {
        this.loadingUpdateMeeting = true;
        const response = await meeting_request.updateReservingMeetingTime(
          this.selectedCellReserveInfo.item.id,
          payload
        );
        if (response.data.success === true) {
          this.$swal({
            title: `${response.data.title}`,
            icon: "success",
            confirmButtonText: "موافق",
          });
        } else if (response.data.success === false) {
          this.$swal({
            title: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "موافق",
          });
        }
        this.loadingUpdateMeeting = false;
      } catch (error) {
        console.error("Error updating meeting:", error);
        this.loadingUpdateMeeting = false;
      }
    },

    async getReservation() {
      this.loading = true;

      await meeting_request.getReservations().then((res) => {
        this.reservations = res.data.data;
      });
      this.loading = false;
    },
  },
  async created() {
    this.getReservation();
    if (this.selectedCellReserveInfo !== null) {
      this.getInstructorTime();
    }
  },
};
</script>
<style lang="scss" scoped>
.links-mode-color {
  background: #410099;
  color: #fff;
  padding: 0px 10px;
  border-radius: 4px;
}
.student-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 5px;
}

.student-name {
  color: #171347;
  font-weight: 700;
  font-size: 1rem;
  display: block;
}
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
}
button {
  box-shadow: none;
}

form-check {
  margin-top: 10px;
  padding-right: 0;
}
.btn-update-meeting {
  background-color: #36037d;
  color: #fff;
  padding: 8px 30px;
  border-radius: 4px;
  margin-top: 20px;
}
.button-checkbox input:checked + .button-label {
  background-color: #410099;
  color: #fff;
}

.button-checkbox input:not(:checked) + .button-label {
  background-color: #fff;
  color: #000;
  border: 1px solid #410099;
}

.button-label,
.day {
  display: inline-block;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

label {
  color: #fff;
  margin-bottom: 10px;
}
.calender {
  z-index: 2222;
  button {
    background: transparent;
    box-shadow: none;
  }
}
</style>
