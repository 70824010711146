import search_request from "@/api/search_request";

export default {
  namespaced: true,
  state: () => ({
    result: [],
  }),
  getters: {
    result: (state) => {
      return state.result;
    },
  },
  mutations: {
    SET_RESULT(state, payload) {
      if (payload) {
        state.result = payload;
      }
    },
  },
  actions: {
    async Search({ commit }, keyword) {
      await search_request.Search(keyword).then((response) => {
        if (response.status === 200) {
          commit("SET_RESULT", response.data);
        }
      });
    },
  },
};
