import { render, staticRenderFns } from "./ProfileMyCoursesComponent.vue?vue&type=template&id=46d610f4&scoped=true&"
import script from "./ProfileMyCoursesComponent.vue?vue&type=script&lang=js&"
export * from "./ProfileMyCoursesComponent.vue?vue&type=script&lang=js&"
import style0 from "./ProfileMyCoursesComponent.vue?vue&type=style&index=0&id=46d610f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d610f4",
  null
  
)

export default component.exports