<template>
  <div></div>
</template>

<script>
import auth_request from "@/api/auth_request";

export default {
  name: "GoogleCallback",
  created() {
    this.handelAction();
  },
  methods: {
    handelAction: async function () {
      await auth_request.handelGoogleCallback().then(() => {
        this.$router.replace({ name: "home" });
      });
    },
  },
};
</script>

<style scoped></style>
