<template>
  <!-- Login Modal -->
  <div>
    <a
      type="button"
      class="text-decoration-none login-btn"
      @click.prevent="handelSHowModal()"
    >
      تسجيل
    </a>
  </div>
</template>

<script>
export default {
  name: "SigninComponent",
  components: {},
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    handelSHowModal() {
      this.$root.$emit("showAuthModel", true);
    },
  },
};
</script>

<style scoped>
.login-btn {
  color: #000;
  font-weight: 450;
}
.dark-theme .login-btn {
  color: #fff !important;
}
</style>
