import axios from "axios";

export default {
  async lastWatch(limit = 4, offset = 0) {
    return await axios.get("/panel/last-watch?", {
      params: {
        limit: limit,
        offset: offset,
      },
    });
  },
};
