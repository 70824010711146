import review_request from "@/api/review_request";

export default {
  namespaced: true,
  state: () => ({
    reviews: [],
    showLoadMore: false,
  }),
  getters: {
    reviews: (state) => {
      return state.reviews;
    },
    showLoadMore: (state) => {
      return state.showLoadMore;
    },
  },
  mutations: {
    SET_REVIEWS(state, payload) {
      if (payload) {
        payload.forEach((item) => {
          state.reviews.push(item);
        });
      }
    },
    RESET_REVIEWS: (state) => {
      state.reviews = [];
    },
    SET_SHOWLOADMORE(state, payload) {
      state.showLoadMore = payload;
    },
  },
  actions: {
    async getReviews({ commit, state }, payload) {
      let _limit = payload.limit;
      let _state = state;
      await review_request.getReviews(payload).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          if (response.data.data.length > 0) {
            commit("SET_REVIEWS", response.data.data);
            if (Object.keys(_state.reviews).length >= _limit) {
              commit("SET_SHOWLOADMORE", true);
            }
          } else {
            commit("SET_SHOWLOADMORE", false);
          }
        }
      });
    },
    async getBundleReviews({ commit, state }, payload) {
      let _limit = payload.limit;
      let _state = state;
      await review_request.getBundleReview(payload).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          if (response.data.data.length > 0) {
            commit("SET_REVIEWS", response.data.data);
            if (Object.keys(_state.reviews).length >= _limit) {
              commit("SET_SHOWLOADMORE", true);
            }
          } else {
            commit("SET_SHOWLOADMORE", false);
          }
        }
      });
    },
    reset_reviews({ commit }) {
      commit("RESET_REVIEWS");
      commit("SET_SHOWLOADMORE", false);
    },
  },
};
