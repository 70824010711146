export default [
  {
    path: "/bundles/:id",
    name: "Bundles",
    component: () => import("@/components/Courses/BundleDetails.vue"),
  },
  {
    path: "/bundleList",
    name: "bundleList",
    component: () => import("@/views/Bundles/BundleList.vue"),
  },
];
