<template>
  <!-- start user profile side -->
  <div class="position-relative">
    <div v-if="user != null" class="profile-avatar mx-auto text-center my-4">
      <img
        class="img-fluid mx-auto rounded-pill border-1 border-light"
        style="height: 200px"
        :src="user.avatar"
        alt="user"
        :srcset="user.avatar"
      />
      <h3 class="text-white py-3 fs-5">{{ user.full_name }}</h3>
    </div>
    <div class="profile-menu mt-3">
      <ul class="list-unstyled" style="margin-right: 20px">
        <li class="my-4">
          <!-- <router-link to=`/about?${dynamic_params}`>Go to About</router-link> -->
          <router-link :to="{ path: '/panel' }">
            <img src="../../assets/icon/profile-courses.svg" />
            <span class="ms-4">الرئيسية</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/purchases' }">
            <img src="../../assets/icon/profile-courses.svg" />
            <span class="ms-4">الكورسات</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/consultant' }">
            <img src="../../assets/icon/profile-consult.svg" />
            <span href="#" class="ms-4">الاستشارات</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/subscriptions' }">
            <img src="../../assets/icon/profile-consult.svg" />
            <span href="#" class="ms-4">الإشتراكات</span>
          </router-link>
        </li>
        <li class="my-4" v-if="user && user.role_name === 'teacher'">
          <router-link :to="{ path: '/panel/upload-courses' }">
            <img src="../../assets/icon/profile-consult.svg" />
            <span href="#" class="ms-4">إنشاء دورة مباشرة</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/notifications' }">
            <img src="../../assets/icon/profile-bell.svg" />
            <span class="ms-4"> الإشعارات</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/financial' }">
            <img src="../../assets/icon/profile-finiancial.svg" />
            <span class="ms-4"> الماليات</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/support' }">
            <img src="../../assets/icon/profile-finiancial.svg" />
            <span class="ms-4"> المساعدة والدعم</span>
          </router-link>
        </li>
        <li class="my-4">
          <router-link :to="{ path: '/panel/account-settings' }">
            <img src="../../assets/icon/profile-setting.svg" />
            <span class="ms-4">إعدادات الحساب</span>
          </router-link>
        </li>
        <li class="my-4">
          <img src="../../assets/icon/profile-logout.svg" />
          <a href="#" @click.prevent="doLogOut" class="ms-4">تسجيل الخروج</a>
        </li>
      </ul>
    </div>
    <!--<div class="profile-adv text-center mx-4">
      <a href="#" class="my-3">
        <img
          class="img-fluid"
          src="assets/images/profile/profile-adv.png"
          alt="app download"
          srcset="assets/images/profile/profile-adv.png"
        />
      </a>
    </div>-->
    <!--<div class="profile-apps text-center mx-4 my-4">
      <a href="#" class="my-3 d-flex">
        <img
          class="img-fluid"
          src="../../assets/icon/google-play.svg"
          alt="google play app"
          srcset="../../assets/icon/google-play.svg"
        />
      </a>
      <a href="#" class="my-3 d-flex">
        <img
          class="img-fluid"
          src="../../assets/icon/app-store.svg"
          alt="app store"
          srcset="../../assets/icon/app-store.svg"
        />
      </a>
    </div>-->
  </div>
  <!-- end user profile side -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileSideComponent",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    doLogOut() {
      this.logOut();
      // this.isLogin = false;
      this.$router.push({ path: "/" }).catch(() => {});
      window.location.reload();
      // if (this.$route.path !== "/panel") {
      //   this.$router.push({ name: "home" }).catch(() => {});
      // } else if (this.$route.path !== "/") {
      //   this.$router.push({ name: "home" }).catch(() => {});
      // }
    },
  },
};
</script>

<style scoped>
span {
  color: var(--bs-white) !important;
}
.router-link-exact-active span {
  color: var(--nav-active-1) !important;
}
</style>
