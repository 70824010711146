<template>
  <div>
    <div class="tabs-breadcrumb d-flex align-content-center border-bottom">
      <div>
        <div class="breadcrumb-item me-0 me-md-5 ms-0 py-3">
          <a
            href="#"
            class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
            >الماليات</a
          >
          <img src="../../assets/icon/arrow-left.svg" class="mx-2" />
        </div>
      </div>

      <div
        class="profile-tab flex-fill d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-content-center gap-4">
          <a
            href="#"
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'summary' }"
            @click.prevent="activeTabe = 'summary'"
            >ملخص الماليات</a
          >
          <a
            v-if="this.user !== null && user.role_name === 'teacher'"
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'sales' }"
            @click.prevent="activeTabe = 'sales'"
          >
            المبيعات
          </a>
          <a
            v-if="this.user !== null && user.role_name === 'teacher'"
            href="#"
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'payout' }"
            @click.prevent="activeTabe = 'payout'"
            >السحب</a
          >
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <div v-if="activeTabe === 'sales'">
        <sales-list-component></sales-list-component>
      </div>
      <div v-if="activeTabe === 'summary'">
        <summary-component></summary-component>
      </div>
      <div v-if="activeTabe === 'payout'">
        <payout-component></payout-component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SalesListComponent from "@/components/Profile/Financial/SalesListComponent";
import SummaryComponent from "@/components/Profile/Financial/SummaryComponent";
import PayoutComponent from "@/components/Profile/Financial/PayoutComponent";
export default {
  name: "UserProfileFinancialSales",
  components: { PayoutComponent, SummaryComponent, SalesListComponent },

  data() {
    return {
      activeTabe: "summary",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUser"]),
  },
};
</script>

<style scoped></style>
