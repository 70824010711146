import axios from "axios";

export default {
  async getComments(payload) {
    return await axios.post("/courses/comments/", {
      webinar_id: payload.webinar_id,
      limit: payload.limit,
      offset: payload.offset,
    });
  },
};
