<template>
  <div
    v-if="isLoading"
    class="spinner-grow spinner-grow-sm"
    :class="classes"
    role="status"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "SmallSpringLoading",
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    isLoading: true,
    width: String,
    height: String,
    classes: [],
  },
};
</script>

<style scoped></style>
