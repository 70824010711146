<template>
  <div>
    <div class="courses-with-title mt-30" v-if="my_purchases.length > 0">
      <div class="d-flex justify-content-between align-items-center mt-4 mb-1">
        <h1 class="h6 text-light-dark-mode" v-if="user.role_name === 'user'">
          كورساتى
        </h1>
        <h1 class="h6 text-light-dark-mode" v-else>مشترياتى</h1>
        <router-link
          :to="{ path: '/panel/purchases' }"
          class="fw-bold text-light-dark-mode text-decoration-none"
        >
          المزيد
        </router-link>
        <!-- <a href="#" class="fw-bold text-light-dark-mode text-decoration-none"
          ></a
        > -->
      </div>
      <div class="profile-content-course-item-list">
        <div v-for="(item, index) in my_purchases" :key="index">
          <router-link
            :to="{ name: 'course', params: { slug: item.slug } }"
            class="card-course bg-white my-1 d-flex justify-content-between align-items-center mb-3 my-2 p-3 rounded gap-3 text-decoration-none"
          >
            <img height="50" :src="item.image" alt="text" class="rounded-2" />
            <div class="flex-fill text-truncate name-light-dark-mode">
              <h1 class="fs-12 m-0 text-truncate fw-bold name-light-dark-mode">
                {{ item.title }}
              </h1>
              <span class="fs-10 name-light-dark-mode">{{
                item.teacher.full_name
              }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                style="fill: var(--bg-cs-primary)"
              >
                <path d="M7 6v12l10-6z"></path>
              </svg>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="mb-px">
      <h1 class="h6 text-light-dark-mode">كورساتي</h1>
      <p class="text-gray-dark-mode">لا يوجد لديك كورسات</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileMyCoursesComponent",
  computed: {
    ...mapGetters("webinars", ["my_purchases"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("webinars", ["getMyPurchases"]),

    async getAllData() {
      this.isShow = true;
      this.isLoading = true;
      await this.getMyPurchases({ limit: 4 }).finally(() => {
        this.isLoading = false;
      });
    },
  },
  created() {
    this.getAllData();
  },
};
</script>

<style scoped>
.dark-theme .text-light-dark-mode {
  color: #fff;
}
.dark-theme .name-light-dark-mode {
  color: #fff !important;
}
.dark-theme .card-course {
  background-color: #2f2f2f;
}
.card-course {
  box-shadow: rgb(0 0 0 / 15%) 0 5px 15px 0;
}
.dark-theme .card-course {
  box-shadow: rgb(255 255 255 / 15%) 0 5px 15px 0;
}
.dark-theme .bg-white {
  background-color: #1c1c1c !important;
  color: #fff;
}
.mb-px {
  margin-top: 30px !important;
}
.mt-30 {
  margin-top: 33px;
}
</style>
