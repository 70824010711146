<template>
  <footer>
    <newsletter-component></newsletter-component>
    <div class="container">
      <div class="content-footer" v-if="config.footer">
        <div class="row">
          <div class="col-lg-4">
            <div class="logo-box">
              <div class="logo-img logo-gray">
                <img
                  src="../../assets/Logo-gray.svg"
                  class="w-25"
                  draggable="false"
                />
              </div>
              <div class="logo-img dark-theme-logo">
                <img
                  src="../../assets/logo-white.svg"
                  class="w-25"
                  draggable="false"
                />
              </div>
              <p class="summery" v-html="config.footer.first_column.value"></p>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="info-links">
              <p class="title">{{ config.footer.second_column.title }}</p>
              <ul class="list-unstyled">
                <li v-html="config.footer.second_column.value"></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="info-links">
              <p class="title">{{ config.footer.third_column.title }}</p>
              <ul class="list-unstyled">
                <li v-html="config.footer.third_column.value"></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="info-links">
              <p class="title">{{ config.footer.forth_column.title }}</p>
              <ul class="list-unstyled">
                <li v-html="config.footer.forth_column.value"></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="info-links text-center">
              <p class="title">حمل التطبيق</p>
              <mobile-application-component></mobile-application-component>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-footer">
        <p class="copy-rights">
          <!-- <span
            style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
          >
            &copy;</span
          > -->
          <span style="font-family: Arial, Helvetica, sans-serif">©</span>
          2023 جميع الحقوق محفوظة. كورس ستور
        </p>
        <available-payments-component></available-payments-component>
        <social-media-component :device="'mobile'"></social-media-component>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import AvailablePaymentsComponent from "@/components/Footer/AvailablePaymentsComponent.vue";
import SocialMediaComponent from "@/components/Footer/SocialMediaComponent.vue";
import MobileApplicationComponent from "@/components/Footer/MobileApplicationComponent.vue";
import NewsletterComponent from "@/components/Footer/NewsletterComponent.vue";
export default {
  name: "footer-components",
  components: {
    AvailablePaymentsComponent,
    SocialMediaComponent,
    MobileApplicationComponent,
    NewsletterComponent,
  },
  computed: {
    ...mapGetters("site", ["config"]),
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: #f6f8fa;
  padding: 5rem 0 0;
  position: relative;
  margin-top: 7rem;
  color: #818894;
  font-size: 11px;
  // @media (max-width: 768px) {
  //   text-align: center;
  //   .info-links {
  //     li {
  //       p {
  //         text-align: center !important;
  //       }
  //     }
  //   }
  // }
  .title {
    font-weight: bold;
    margin-bottom: 3rem;
    font-size: 12px;
    @media (max-width: 768px) {
      margin-bottom: 5px;
      margin-top: 20px;
    }
  }
  .summery {
    width: 70%;
  }
  .logo-img {
    margin-bottom: 3rem;
    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }
  .info-links {
    li {
      margin-bottom: 0.8rem;
    }
  }
  .sub-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
}
// background-color: #2f2f2f !important;
.dark-theme footer {
  background-color: #000 !important;
  color: #ffffff !important;
}
.dark-theme-logo {
  display: none;
}
.dark-theme .dark-theme-logo {
  display: block;
}
.logo-gray {
  display: block;
}
.dark-theme .logo-gray {
  display: none;
}
</style>
