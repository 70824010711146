import subscribe_request from "@/api/subscribe_request";
import appConfig from "../../config";
export default {
  namespaced: true,
  state: () => ({
    count: 0,
    subscribes: [],
    subscribed: null,
    dayOfUse: 0,
    subscribePayments: [],
    loading: false,
  }),
  getters: {
    loading: (state) => {
      return state.loading;
    },
    count: (state) => {
      return state.count;
    },
    subscribes: (state) => {
      return state.subscribes;
    },
    subscribed: (state) => {
      return state.subscribed;
    },
    dayOfUse: (state) => {
      return state.dayOfUse;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    SET_SUBSCRIBES(state, payload) {
      state.subscribes = payload;
    },
    SET_SUBSCRIBED(state, payload) {
      state.subscribed = payload;
    },
    SET_DAYOFUSE(state, payload) {
      state.dayOfUse = payload;
    },
  },
  actions: {
    async getSubscribes({ commit }) {
      commit("SET_LOADING", true);
      await subscribe_request.fetchAll().then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_SUBSCRIBES", response.data.data.subscribes);
            commit("SET_COUNT", response.data.data.count);
            commit("SET_SUBSCRIBED", response.data.data.subscribed);
            commit("SET_DAYOFUSE", response.data.data.dayOfUse);
          }
        }
        commit("SET_LOADING", false);
      });
    },

    async checkPaymentSubscribe({ id, gateway_id, PaymentMethodId }) {
      await subscribe_request
        .checkSubscribe(id, gateway_id, PaymentMethodId)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            if (response.data.status === "paid") {
              window.location.href =
                appConfig.VERIFY_URL + "?order_id=" + response.data.data.id;
            } else {
              window.location.href = response.data.data;
            }
          }
        });
    },
  },
};
