<template>
  <div class="theme-icon">
    <a href="#" @click.prevent="changeTheme(this)">
      <svg
        v-if="!is_dart"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: #410098"
      >
        <path
          d="M12 11.807A9.002 9.002 0 0 1 10.049 2a9.942 9.942 0 0 0-5.12 2.735c-3.905 3.905-3.905 10.237 0 14.142 3.906 3.906 10.237 3.905 14.143 0a9.946 9.946 0 0 0 2.735-5.119A9.003 9.003 0 0 1 12 11.807z"
        ></path>
      </svg>
      <svg
        v-if="is_dart"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: #818894"
      >
        <path
          d="M12 11.807A9.002 9.002 0 0 1 10.049 2a9.942 9.942 0 0 0-5.12 2.735c-3.905 3.905-3.905 10.237 0 14.142 3.906 3.906 10.237 3.905 14.143 0a9.946 9.946 0 0 0 2.735-5.119A9.003 9.003 0 0 1 12 11.807z"
        ></path>
      </svg>

      <!-- <span class="sun" v-if="!is_dart">
        <img class="svg" src="../../assets/icon/cs-moon.svg" />
      </span>
      <span class="moon" v-if="is_dart">
        <img class="svg" src="../../assets/icon/cs-moon-light.svg" />
      </span> -->
    </a>
  </div>
</template>

<script>
export default {
  name: "ThemeComponent",
  data() {
    return {
      is_dart:
        localStorage.getItem("coursestore-theme") === "dark-theme"
          ? true
          : false,
    };
  },
  methods: {
    changeTheme: function () {
      this.is_dart = !this.is_dart;
      if (this.is_dart === true) {
        document.querySelector("html").classList.add("dark-theme");
        document.querySelector("html").classList.remove("light-theme");
        localStorage.setItem("coursestore-theme", "dark-theme");
      } else {
        localStorage.setItem("coursestore-theme", "light-theme");
        document.querySelector("html").classList.add("light-theme");
        document.querySelector("html").classList.remove("dark-theme");
      }
      this.$root.$emit("onSetTheme");
    },
  },
};
</script>
