import moment from "moment/moment";
export default {
  isMobile: () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  },
  formatDurations: (duration) => {
    const time = moment.utc(
      moment.duration(duration, "minutes").asMilliseconds()
    );
    //const second = time.seconds();
    const minutes = ("0" + time.minutes()).slice(-2);
    const hours = ("0" + time.hours()).slice(-2);

    if (!minutes) {
      return `${hours} H`;
    } else if (!hours) {
      return `${minutes} M`;
    }
    return `${hours} H : ${minutes} M`;
    /*if (!time.minutes()) {
      return `${hours} ${minutes}`; //time.format(`h : mm`);
    }*/
    //return; //time.format(`h : mm `);
  },
  formatDurationWithSecond: (duration) => {
    const time = moment.utc(
      moment.duration(duration, "seconds").asMilliseconds()
    );
    const second = ("0" + time.seconds()).slice(-2);
    const minutes = ("0" + time.minutes()).slice(-2);
    const hours = ("0" + time.hours()).slice(-2);

    return `${hours}:${minutes}:${second}`;
  },
  youtubeParser: function (url) {
    let regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  },
};
