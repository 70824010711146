import axios from "axios";
export default {
  async getSupportTickets() {
    return await axios.get("/panel/support/tickets");
  },
  async sendSupportTickets(data) {
    return await axios.post(`/panel/support`, data);
  },
  async getSupportDepartments() {
    return await axios.get(`/panel/support/departments`);
  },
};
