import axios from "axios";
export default {
  // Chapter
  async addCourse(payload) {
    return await axios.post("/panel/webinars/store", payload);
  },
  async updateCourse(id, payload) {
    return await axios.post(`/panel/webinars/${id}/update`, payload);
  },
  async createWebinar(payload) {
    return await axios.post("/panel/chapters/store", payload);
  },
  async deleteChapter(id) {
    return await axios.get(`/panel/chapters/${id}/delete`);
  },
  async getAllSession(id) {
    return await axios.get(`/panel/chapters/getAllByWebinarId/${id}`);
  },
  // Store TextLesson
  async storeTextLesson(payload) {
    return await axios.post("/panel/text-lesson/store", payload);
  },
  async updateTextLesson(id, payload) {
    return await axios.post(`/panel/text-lesson/${id}/update`, payload);
  },
  async deleteTextLesson(id) {
    return await axios.get(`/panel/text-lesson/${id}/delete`);
  },
  // Session
  async storeSession(payload) {
    return await axios.post("/panel/sessions/store", payload);
  },
  async updateSession(id, payload) {
    return await axios.post(`/panel/sessions/${id}/update`, payload);
  },
  async deleteSession(id) {
    return await axios.get(`/panel/sessions/${id}/delete`);
  },
  // File
  async storeFile(payload) {
    return await axios.post("/panel/files/store", payload);
  },
  async updateFile(id, payload) {
    return await axios.post(`/panel/files/${id}/update`, payload);
  },
  async deleteChapterFile(id) {
    return await axios.get(`/panel/files/${id}/delete`);
  },
  // Store FAQ
  async storeFaqs(payload) {
    return await axios.post("/panel/faqs/store", payload);
  },
  async updateFaqs(id, payload) {
    return await axios.post(`/panel/faqs/${id}/update`, payload);
  },
  async deleteFaqs(id) {
    return await axios.get(`/panel/faqs/${id}/delete`);
  },
  // Price Plans
  async storePricePlans(payload) {
    return await axios.post("/panel/tickets/store", payload);
  },
  async deletePlan(id) {
    return await axios.get(`/panel/tickets/${id}/delete`);
  },
  async getAllPricingPlan(id) {
    return await axios.get(`/panel/tickets/getAllByWebinarId/${id}`);
  },
  // Store quiz
  async storeQuiz(payload) {
    return await axios.post("/panel/webinar-quiz/store", payload);
  },
};
