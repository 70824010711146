import { render, staticRenderFns } from "./SalesListComponent.vue?vue&type=template&id=1dd38573&scoped=true&"
import script from "./SalesListComponent.vue?vue&type=script&lang=js&"
export * from "./SalesListComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd38573",
  null
  
)

export default component.exports