<template>
  <span class="position-relative">
    <a
      href="#"
      class="toggle-cart"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span
        class="badge text-white"
        v-bind:style="{
          backgroundColor: '#1EBCC6',
        }"
      >
        <small v-if="!is_do_get_data" class="text-white">{{
          list.items.length
        }}</small>
        <small
          v-else
          class="spinner-grow spinner-grow-sm text-light p-0 m-0"
        ></small>
      </span>
      <img class="svg" src="../../assets/icon/cs-cart-alt.svg" />
    </a>

    <div class="container position-relative">
      <div
        class="dropdown-menu pb-0 cart dropdown-width position-absolute"
        aria-labelledby="toggle-cart"
        style="max-width: 500px !important; width: 350px"
        v-show="list.items.length > 0"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="pt-1 px-3 cart-title-dropdown">العربة</h5>
          <small-spring-loading :is-loading="ajaxLoad"></small-spring-loading>
        </div>
        <div style="max-height: 300px; overflow: auto">
          <div v-for="(item, index) in list.items" :key="index">
            <div
              class="d-flex justify-content-between container"
              v-if="item.bundle !== null"
            >
              <div
                class="d-flex w-100 align-items-center gap-3 justify-content-between"
              >
                <img
                  width="150"
                  :src="item.bundle.image"
                  :alt="item.bundle.title"
                  class="rounded"
                />

                <div class="flex-fill align-content-center">
                  <div
                    class="w-100 d-flex align-content-center justify-content-between"
                  >
                    <h6 class="cart-title-dropdown">
                      {{ item.bundle.title | truncate(15, "...") }}
                    </h6>
                    <span
                      class="cursor-pointer"
                      @click.stop="deleteItem(item.id)"
                    >
                      <img src="../../assets/icon/cs-trash.svg" />
                    </span>
                  </div>
                  <span class="text-muted font-weight-bold"
                    >{{ item.price }} {{ config.currency.name }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between container"
              v-if="item.meeting"
            >
              <div
                class="d-flex w-100 align-items-center gap-3 justify-content-between"
              >
                <img
                  width="100"
                  height="100"
                  :src="item.meeting.instructor.avatar"
                  :alt="item.meeting.instructor.full_name"
                  class="rounded"
                />

                <div class="flex-fill align-content-center">
                  <div
                    class="w-100 d-flex align-content-center justify-content-between"
                  >
                    <h6 class="cart-title-dropdown">
                      {{ item.meeting.time.start }}
                      {{ item.meeting.time.end }}
                      <p class="m-0" style="font-size: 10px">
                        {{ convertDate(item.meeting.date) }}
                      </p>
                    </h6>
                    <span
                      class="cursor-pointer"
                      @click.stop="deleteItem(item.id)"
                    >
                      <img src="../../assets/icon/cs-trash.svg" />
                    </span>
                  </div>
                  <span
                    class="text-muted font-weight-bold"
                    :style="{
                      'text-decoration':
                        item.meeting.price > item.meeting.price_with_discount
                          ? 'line-through'
                          : 'none',
                      color:
                        item.meeting.price > item.meeting.price_with_discount
                          ? '#aaa'
                          : '#fff',
                      fontSize:
                        item.meeting.price > item.meeting.price_with_discount
                          ? '12px'
                          : '13px',
                    }"
                    >{{ item.price }} {{ config.currency.name }}
                  </span>
                  <div
                    v-if="item.meeting.price > item.meeting.price_with_discount"
                    class="price-night-mode"
                  >
                    {{ item.meeting.price_with_discount }}
                    {{ config.currency.name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between container"
              v-if="item.webinar"
            >
              <!-- {{ item.webinar.price_string }} -->
              <div
                class="d-flex w-100 align-items-center gap-3 justify-content-between"
              >
                <img
                  width="150"
                  :src="item.webinar.image"
                  :alt="item.webinar.title"
                  class="rounded"
                />

                <div class="flex-fill align-content-center">
                  <div
                    class="w-100 d-flex align-content-center justify-content-between"
                  >
                    <h6 class="cart-title-dropdown">
                      {{ item.webinar.title | truncate(15, "...") }}
                    </h6>
                    <span
                      class="cursor-pointer"
                      @click.stop="deleteItem(item.id)"
                    >
                      <img src="../../assets/icon/cs-trash.svg" />
                    </span>
                  </div>
                  <span
                    class="text-muted font-weight-bold"
                    :style="{
                      'text-decoration':
                        item.webinar.price > item.webinar.price_with_discount
                          ? 'line-through'
                          : 'none',
                      color:
                        item.webinar.price > item.webinar.price_with_discount
                          ? '#aaa'
                          : '#fff',
                      fontSize:
                        item.webinar.price > item.webinar.price_with_discount
                          ? '12px'
                          : '13px',
                    }"
                    >{{ item.price_after_discount }} {{ config.currency.name }}
                  </span>
                  <div
                    v-if="item.webinar.price > item.webinar.price_with_discount"
                    class="price-night-mode"
                  >
                    {{ item.price_after_discount }}
                    {{ config.currency.name }}
                  </div>
                </div>
              </div>
            </div>

            <hr class="" />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center shopCart">
          <button
            @click.stop="emptyCart()"
            class="text-light btn-coursestore rounded-bottom-right-1 text-light py-2 flex-fill text-decoration-none d-flex justify-content-center"
          >
            <img src="../../assets/icon/cs-trash.svg" />
            <span class="text-light">تفريغ العربة</span>
          </button>
          <button
            @click.stop="payNow()"
            class="text-light btn-coursestore-primary py-2 rounded-bottom-left-1 flex-fill text-decoration-none d-flex justify-content-center"
          >
            <img src="../../assets/icon/cs-mouse.svg" />
            <span class="text-light">الدفع</span>
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
export default {
  name: "NavCartPanel",
  data() {
    return {
      ajaxLoad: false,
    };
  },
  components: { SmallSpringLoading },
  computed: {
    ...mapGetters("cart", ["list", "is_do_get_data"]),
    ...mapGetters("site", ["config"]),
    ...mapGetters("auth", ["isLogin"]),
  },
  methods: {
    ...mapActions("cart", ["getList", "delete", "empty"]),
    convertDate(createdAt) {
      let date = new Date(createdAt * 1000);
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");

      // Format as "YYYY-MM-DD"
      return year + "-" + month + "-" + day;
    },
    async deleteItem(id) {
      await this.delete(id);
    },
    async emptyCart() {
      this.ajaxLoad = true;
      await this.empty().finally(() => {
        this.ajaxLoad = false;
      });
    },
    payNow() {
      if (this.isLogin) {
        this.$root.$emit("showFastPayModal", null);
      }
    },
  },
};
</script>

<style scoped>
.badge {
  position: absolute;
  top: -10px;
  left: -8px;
  border-radius: 50% !important;
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.badge small {
  display: block;
  margin: 2px 0;
}
.cart {
  top: 7px !important;
  left: 0 !important;
  right: -145px !important;
}

/* .toggle-cart::after {
  right: 60%;
} */
/* .dropdown-menu:after {
  right: 58%;
} */
@media (max-width: 768px) {
  /* .cart {
    right: 67%;
    top: 70px !important;
  }
  .dropdown-menu:after {
    right: 58% !important;
  } */
}

.rounded-top-left-1 {
  border-top-left-radius: 0.3rem;
}
.rounded-top-right-1 {
  border-top-right-radius: 0.3rem;
}
.rounded-bottom-left-1 {
  border-bottom-left-radius: 0.3rem;
}
.rounded-bottom-right-1 {
  border-bottom-right-radius: 0.3rem;
}
.shopCart button:hover {
  background-color: var(--bg-cs-secoundry);
}
.shopCart {
  font-size: 12px;
}
.cart {
  background-color: #fff !important;
}
.dark-theme .cart {
  background-color: #1c1d1f !important;
}
.dropdown-menu::after {
  background-color: var(--nav-dropdown-bg) !important;
}

@media only screen and (max-width: 600px) {
  .cart {
    max-width: 100%;
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    top: 55px !important;
  }
  .cart::after {
    right: 70% !important;
  }

  .position-relative,
  .nav-item .nav-link {
    position: inherit !important;
  }
  .toggle-cart {
    position: relative !important;
  }
  .fs-sm-10 {
    font-size: 0.3rem;
  }
}
.cart-title-dropdown {
  font-size: 13px;
}
.dark-theme .cart-title-dropdown {
  color: #fff;
}
</style>
