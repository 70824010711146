<template>
  <div class="course-chapters">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 class="mb-0">{{ chapter.title }}</h5>
        <span class="chapters-date">
          {{ convertDate(chapter.start_date) }}</span
        >
      </div>
      <div class="arrow-collapse">
        <!-- /collapse -->
        <button @click="toggleCollapse" class="collapses">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: #818894"
          >
            <path
              d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"
            ></path>
          </svg>
        </button>
        <!-- //Delete Chapter icon -->
        <button class="delete" @click="deleteChapters()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: #818894"
          >
            <path
              d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
            ></path>
            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
          </svg>
        </button>
      </div>
    </div>
    <!-- Add Data To Chapter New Lessons-->
    <div v-if="!isCollapsed">
      <chapter-file :chapterId="chapterId"></chapter-file>
      <text-lesson :chapterId="chapterId"></text-lesson>
      <new-sessions :chapterId="chapterId"></new-sessions>
    </div>
  </div>
</template>
<script>
import ChapterFile from "./NewFile/ChapterFile";
import TextLesson from "./TextLesson/ChapterTextLesson";
import NewSessions from "@/components/Profile/UploadCourses/NewSession/NewSession.vue";
import upload_courses_request from "@/api/upload_courses_request";
import Vue from "vue";
export default {
  name: "ChapterComponent",
  props: {
    chapter: { type: [Object, null, String] },
  },
  components: {
    ChapterFile,
    TextLesson,
    NewSessions,
  },
  data() {
    return {
      chapterId: this.chapter.id,
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    convertDate(createdAt) {
      let date = new Date(createdAt * 1000);
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");

      // Format as "YYYY-MM-DD"
      return year + "-" + month + "-" + day;
    },
    async deleteChapters() {
      await upload_courses_request.deleteChapter(this.chapterId).then((res) => {
        Vue.swal.fire(res.data.message);
        location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.course-chapters {
  background-color: #f4f6f9;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  .chapters-date {
    color: #818894;
    font-size: 12px;
  }
  .delete {
    inset: 0;
    background: transparent;
    box-shadow: none;
  }
  .collapses {
    background: transparent;
    box-shadow: none;
  }
  .rotate {
    transform: rotate(180deg);
  }
}
</style>
