<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="quiz">
      <div class="quiz-head">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style="fill: #818894"
        >
          <path
            d="M15.566 11.021A7.016 7.016 0 0 0 19 5V4h1V2H4v2h1v1a7.016 7.016 0 0 0 3.434 6.021c.354.208.566.545.566.9v.158c0 .354-.212.69-.566.9A7.016 7.016 0 0 0 5 19v1H4v2h16v-2h-1v-1a7.014 7.014 0 0 0-3.433-6.02c-.355-.21-.567-.547-.567-.901v-.158c0-.355.212-.692.566-.9zM17 19v1H7v-1a5.01 5.01 0 0 1 2.45-4.299A3.111 3.111 0 0 0 10.834 13h2.332c.23.691.704 1.3 1.385 1.702A5.008 5.008 0 0 1 17 19z"
          ></path>
        </svg>
        <p class="text-light-dark-mode">اختبار جديد</p>
      </div>
      <div class="chapter-content">
        <div class="input-groups">
          <p class="title text-light-dark-mode">اللغة</p>
          <b-form-select
            v-model="fileGroup.locale"
            :options="lang"
          ></b-form-select>
        </div>

        <div class="mt-3">
          <GlobalInput type="text" label="العنوان" v-model="fileGroup.title" />
        </div>

        <div class="mt-3">
          <GlobalInput type="number" label="الوقت " v-model="fileGroup.time" />
        </div>
        <div class="mt-3">
          <GlobalInput
            type="number"
            label=" عدد المحاولات "
            v-model="fileGroup.attempt"
          />
        </div>
        <div class="mt-3">
          <GlobalInput
            type="number"
            label=" درجة النجاح  "
            v-model="fileGroup.pass_mark"
          />
        </div>
        <div class="d-flex mt-4 gap-2 align-items-center">
          <p class="text-light-dark-mode">يحتوى على شهاة</p>
          <label class="custom-switch-container">
            <input
              v-model="fileGroup.certificate"
              type="checkbox"
              class="custom-control-success"
              name="check-button"
              switch
              :unchecked-value="0"
              :value="0"
            />
            <span class="custom-switch-slider"> </span>
          </label>
        </div>
        <div class="d-flex mt-4 gap-2 align-items-center">
          <p class="text-light-dark-mode">تفعيل</p>
          <label class="custom-switch-container">
            <input
              v-model="fileGroup.status"
              type="checkbox"
              class="custom-control-success"
              name="check-button"
              switch
              :unchecked-value="0"
              :value="0"
            />
            <span class="custom-switch-slider"> </span>
          </label>
        </div>
      </div>

      <div class="d-flex align-items-center gap-3">
        <b-button class="btn btn-coursestore-primary mt-4" @click="createQuiz()"
          >انشاء
          <small-spring-loading
            :is-loading="loadingStore"
          ></small-spring-loading>
        </b-button>
        <!-- <b-button class="btn btn-coursestore-primary mt-4" @click="updateFaqs()"
                >تعديل
                <small-spring-loading
                  :is-loading="loadingUpdateStore"
                ></small-spring-loading>
              </b-button> -->
        <!-- <b-button class="btn btn-coursestore-primary mt-4" @click="deleteFaqs()"
                >حذف
                <small-spring-loading
                  :is-loading="loadingDeleteStore"
                ></small-spring-loading>
              </b-button> -->
      </div>
    </div>

    <div class="quiz-content" v-if="Object.keys(quizContent).length > 0">
      <div v-for="(quizItem, index) in quizContent" :key="index">
        {{ quizItem }}
      </div>
    </div>
  </div>
</template>

<script>
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import upload_courses_request from "@/api/upload_courses_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
// import Vue from "vue";
export default {
  components: {
    GlobalInput,
    SmallSpringLoading,
  },
  props: {
    chapterId: { type: [Number, null] },
  },
  name: "new-quiz",
  data() {
    return {
      lang: ["ar"],

      fileGroup: {
        locale: "",
        title: "",
        time: 0,
        attempt: 0,
        pass_mark: 0,
        certificate: 0,
        status: 0,
      },
      loadingStore: false,
      loadingUpdateStore: false,
      loadingDeleteStore: false,
      quizId: Number,
      quizContent: {},
    };
  },
  computed: {
    startDateFormat() {
      const year = this.fileGroup.start_date.getFullYear();
      const month = String(this.fileGroup.start_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(this.fileGroup.start_date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    endDateFormat() {
      const year = this.fileGroup.end_date.getFullYear();
      const month = String(this.fileGroup.end_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(this.fileGroup.end_date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    formData() {
      const formData = new FormData();
      formData.append("webinar_id", this.$route.query.webinar_id);

      formData.append("locale", this.fileGroup.locale);
      formData.append("title", this.fileGroup.title);
      formData.append("time", this.fileGroup.time);
      formData.append("attempt", this.fileGroup.attempt);
      formData.append("pass_mark", this.fileGroup.pass_mark);
      formData.append("status", this.fileGroup.status ? 1 : 0);
      formData.append("certificate", this.fileGroup.certificate ? 1 : 0);

      return formData;
    },

    async createQuiz() {
      this.loadingStore = true;
      await upload_courses_request.storeQuiz(this.formData()).then((res) => {
        if (res.status === 200 && res.data.success === true) {
          this.quizContent[Object.keys(this.quizContent).length] =
            res.data.data;
          this.$swal({
            icon: "success",
            title: `${res.data.message}`,
          });
        }
      });
      this.loadingStore = false;
    },

    // async updateFaqs() {
    //   if (this.pricePlanId) {
    //     this.loadingUpdateStore = true;
    //     await upload_courses_request
    //       .updateFaqs(this.pricePlanId, this.formData())
    //       .then((res) => {
    //         this.$swal({
    //           icon: "success",
    //           title: `${res.data.message}`,
    //         });
    //       })
    //       .catch((error) => {
    //         this.$swal({
    //           icon: "error",
    //           title: "خطاء فى التعديل",
    //         });
    //       });
    //     this.loadingUpdateStore = false;
    //   } else {
    //     this.$swal({
    //       icon: "error",
    //       title: "يرجى اضافة درس نصي",
    //     });
    //   }
    // },

    // async deleteFaqs() {
    //   if (this.pricePlanId) {
    //     this.loadingDeleteStore = true;
    //     await upload_courses_request.deleteFaqs(this.pricePlanId).then((res) => {
    //       this.$swal({
    //         title: "هل تريد الحذف",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: "تأكيد",
    //         cancelButtonText: "إلغاء",
    //         customClass: {
    //           confirmButton: "btn btn-primary",
    //           cancelButton: "btn btn-outline-danger ml-1",
    //         },
    //       });
    //     });
    //   }
    //   this.loadingDeleteStore = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
.quiz {
  background-color: #fff;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 50%;
  .quiz-head {
    display: flex;
    gap: 5px;
    padding: 5px;
    p {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }
  }
  .chapter-content {
    max-width: 800px;
    input,
    select,
    b-textarea {
      width: 100%;
      border: 1px solid #ddd;
      padding: 5px;
      outline: none;
      border-radius: 4px;
    }
  }
  .title {
    display: block;
  }

  // radio button
  .button-checkbox .button-label {
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    padding: 5px 13px !important;
    cursor: pointer;
  }
  .button-checkbox input:checked + .button-label {
    background-color: #1ecad3;
    color: #fff;
  }

  .button-checkbox input:not(:checked) + .button-label {
    background-color: #fff;
    color: #000;
  }
}
.dark-theme .quiz {
  background-color: #2f2f2f;
}
.quiz-content {
  width: 50%;
}
</style>
