<template>
  <!-- Nav Section -->
  <nav
    class="main-nav navbar navbar-expand-lg navbar-light bg-white pt-4 w-100 position-fixed"
  >
    <div class="container">
      <div class="d-lg-none d-block w-100">
        <div class="mobile-nav">
          <div class="row">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <a
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <img src="../../assets/icon/cs-menu.svg" class="svg" />
                </a>
                <router-link class="navbar-brand-mobile mx-2" to="/">
                  <img
                    class="logo-mobile light-theme"
                    src="../../assets//images/main/logo-mobile.png"
                    alt="CourseStore"
                  />
                  <img
                    class="logo-footer me-3 dark-theme-image"
                    src="@/assets/images/icon-coursestore.png"
                    alt=""
                  />
                </router-link>
              </div>
            </div>
            <div class="col-9">
              <div class="d-flex align-items-center justify-content-end">
                <div
                  v-if="this.user !== null && userProgress.progress !== 100"
                  class="position-relative show"
                >
                  <circular-progress-bar
                    :value="userProgress.progress"
                    size="small"
                  ></circular-progress-bar>
                  <div class="menu bg-gray position-absolute">
                    <router-link
                      :to="{ name: 'account-settings' }"
                      class="d-block menu-content text-decoration-none"
                      >الرجاء إكمال البيانات</router-link
                    >
                  </div>
                </div>
                <navbar-categories></navbar-categories>
                <nav-search></nav-search>
                <!-- <span>|</span> -->
                <nav-cart-panel class="mx-1" v-if="isLogin"></nav-cart-panel>
                <nav-notification
                  class="mx-1"
                  v-if="isLogin"
                ></nav-notification>
                <theme-component class="mx-1"></theme-component>
                <signin-component
                  class="mx-2"
                  v-if="!isLogin"
                ></signin-component>
                <nav-user-panel class="mx-1"></nav-user-panel>
              </div>
            </div>
          </div>
          <div
            class="offcanvas offcanvas-top"
            tabindex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div class="offcanvas-header pb-0">
              <button
                class="btn btn-outline-light"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                id="closeMobileMenu"
              >
                <img
                  src="../../assets/icon/cs-dismise.svg"
                  class="svg"
                  alt="close"
                />
              </button>
            </div>
            <div class="offcanvas-body pt-0">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    :to="{ name: 'classes' }"
                  >
                    الكورسات
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    :to="{ path: '/instructors' }"
                  >
                    المدربين
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    :to="{ path: '/bundleList' }"
                  >
                    الباقات
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link mx-2 hover-bold"
                    aria-current="page"
                    :to="{ path: '/in-person-courses' }"
                  >
                    الكورسات الحضورية
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link mx-2 hover-bold"
                    aria-current="page"
                    :to="{ path: '/Consultations' }"
                  >
                    احجز استشارة
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link hover-bold live-color"
                    aria-current="page"
                    :to="{ name: 'CoursesLive' }"
                  >
                    لايف
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mobile-search d-none">
          <form class="me-auto mb-2 d-flex position-relative">
            <span
              class="fa fa-search position-absolute"
              style="right: 90%; z-index: 2"
            ></span>
            <div class="input-group">
              <span
                class="input-group-text pt-2 pb-0 bg-white text-dark"
                id="basic-addon1"
                onclick="closeSearch()"
                >×</span
              >
              <input
                class="form-control"
                type="search"
                placeholder="بحث"
                aria-label="Search"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="d-none d-lg-block w-100">
        <div class="row" id="navbarSupportedContent">
          <div class="col-8">
            <div class="d-flex align-items-center navigation-links">
              <router-link class="navbar-brand" to="/">
                <img
                  class="logo"
                  src="../../assets/logo.svg"
                  alt="CourseStore"
                />
              </router-link>
              <navbar-categories></navbar-categories>
              <router-link
                class="nav-link mx-2 hover-bold"
                aria-current="page"
                :to="{ name: 'classes' }"
              >
                الكورسات
              </router-link>
              <router-link
                class="nav-link mx-2 hover-bold"
                aria-current="page"
                :to="{ path: '/instructors' }"
              >
                المدربين
              </router-link>
              <router-link
                class="nav-link mx-2 hover-bold"
                aria-current="page"
                :to="{ path: '/bundleList' }"
              >
                الباقات
              </router-link>
              <router-link
                class="nav-link mx-2 hover-bold"
                aria-current="page"
                :to="{ path: '/in-person-courses' }"
              >
                الكورسات الحضورية
              </router-link>
              <router-link
                class="nav-link mx-2 hover-bold"
                aria-current="page"
                :to="{ path: '/Consultations' }"
              >
                احجز استشارة
              </router-link>
              <router-link
                class="nav-link hover-bold live-color"
                aria-current="page"
                :to="{ name: 'CoursesLive' }"
              >
                لايف
              </router-link>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex align-items-center gap-2 justify-content-end">
              <!-- <navbar-categories></navbar-categories> -->
              <div class="position-relative">
                <div
                  v-if="this.user !== null && userProgress.progress !== 100"
                  class="show"
                >
                  <circular-progress-bar
                    :value="userProgress.progress"
                    size="small"
                  ></circular-progress-bar>
                  <div class="menu position-absolute">
                    <router-link
                      :to="{ name: 'account-settings' }"
                      class="d-block menu-content text-decoration-none"
                      >الرجاء إكمال البيانات</router-link
                    >
                  </div>
                </div>
              </div>
              <become-instructor
                v-if="isLogin && this.user.role_name !== 'teacher'"
              ></become-instructor>
              <nav-search></nav-search>
              <nav-cart-panel v-if="isLogin"></nav-cart-panel>
              <nav-notification v-if="isLogin"></nav-notification>
              <theme-component></theme-component>
              <signin-component v-if="!isLogin"></signin-component>
              <nav-user-panel></nav-user-panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Nav Section -->
</template>

<script>
import { mapGetters } from "vuex";
import NavUserPanel from "@/views/NavBar/NavUserPanel";
import NavCartPanel from "@/views/NavBar/NavCartPanel";
import NavNotification from "@/views/NavBar/NavNotofication";
import SigninComponent from "@/views/NavBar/SigninComponent";
import ThemeComponent from "@/views/NavBar/ThemeComponent";
import NavbarCategories from "@/views/NavBar/NavbarCategories";
import NavSearch from "@/views/NavBar/NavSearch";
import BecomeInstructor from "@/views/NavBar/BecomeInstructor.vue";
import CircularProgressBar from "@/views/NavBar/UserProgress.vue";
export default {
  name: "NavBar",

  components: {
    NavSearch,
    NavbarCategories,
    ThemeComponent,
    NavUserPanel,
    SigninComponent,
    NavNotification,
    NavCartPanel,
    BecomeInstructor,
    CircularProgressBar,
  },
  computed: {
    ...mapGetters("auth", ["isLogin", "user", "userProgress"]),
  },
  watch: {
    $route() {
      document.getElementById("closeMobileMenu").click();
    },
  },
};
</script>

<style scoped lang="scss">
.navigation-links {
  .nav-link {
    color: #818894;
  }
  .router-link-exact-active {
    color: #000;
    font-weight: bold;
  }
}
.small {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.5rem;
}

.medium {
  width: 7rem;
  height: 7rem;
  font-size: 1.6rem;
}

.large {
  width: 9rem;
  height: 9rem;
  font-size: 1.8rem;
}

.progress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: inherit;
    width: inherit;
  }

  .progress__back {
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress__front {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);

    circle {
      fill: none;
      stroke-dasharray: 283;
    }
  }

  .progress__value {
    position: relative;
    font-size: inherit;
    pointer-events: none;
    z-index: 2;
  }
}

.menu {
  top: 70px;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  padding: 10px;
  max-width: 250px;
  width: auto;
  left: -50px;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05) !important;
}
.show {
  cursor: pointer;
  // margin-inline: 5px;
}

.menu-content {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.show:hover .menu {
  top: 45px !important;
  opacity: 1 !important;
  visibility: visible;
}
.logo-footer {
  width: 65px;
}
.dark-theme-image {
  display: none;
}
.light-theme {
  display: block;
}
.dark-theme .dark-theme-image {
  display: block;
  width: 40px;
}
.dark-theme .light-theme {
  display: none;
}
.live-color {
  color: red !important;
  font-weight: bold;
  position: relative;
  margin: 0 15px;
  &::before {
    position: absolute;
    bottom: 7px;
    right: -10px;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: red;
  }
}
</style>
