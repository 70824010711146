<template>
  <div>
    <small-spring-loading
      :is-loading="loadingCreateWebinar"
    ></small-spring-loading>
    <div v-if="hasWebinarId">
      <VueModal
        v-model="showModal"
        modalStyle="max-width:600px;height: 352px; padding:20px"
        modalClass="play_course_popup"
        title="New Section"
      >
        <div class="input-group">
          <p class="title">اللغة</p>
          <b-form-select v-model="form.locale" :options="lang"></b-form-select>
        </div>
        <GlobalInput type="text" label="العنوان" v-model="form.title" />
        <div class="d-flex mt-4 justify-content-between">
          <p>تفعيل</p>
          <label class="custom-switch-container">
            <input
              v-model="form.status"
              type="checkbox"
              class="custom-control-success"
              name="check-button"
              switch
              :unchecked-value="0"
              :value="0"
            />
            <span class="custom-switch-slider"> </span>
          </label>
        </div>
        <b-button class="btn btn-success" @click="createSession">حفظ</b-button>
      </VueModal>
      <b-button @click="showModalNewSection">New Section</b-button>
    </div>

    <div v-else class="no-section text-center">
      <h2>No sections!</h2>
      <p>
        By creating sections you can organize the course content in different
        chapters.
      </p>
    </div>
    <div class="mt-4" v-if="Object.keys(sectionsList).length > 0">
      <chapter-component
        v-for="(chapterItem, index) in sectionsList"
        :key="index"
        :chapter="chapterItem"
      ></chapter-component>
    </div>
  </div>
</template>

<script>
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import VueModal from "@kouts/vue-modal";
import upload_courses_request from "@/api/upload_courses_request";

import SmallSpringLoading from "@/components/Helper/SmallSpringLoading.vue";
import ChapterComponent from "@/components/Profile/UploadCourses/ChapterComponent.vue";

export default {
  name: "create-content",
  props: ["webinar_id"],
  components: {
    ChapterComponent,
    SmallSpringLoading,
    GlobalInput,
    VueModal,
  },
  data() {
    return {
      showModal: false,
      loadingCreateWebinar: false,
      lang: ["ar"],
      form: {
        title: "",
        locale: "",
        type: "session",
        status: 0,
      },
      sectionsList: {},
    };
  },
  computed: {
    hasWebinarId() {
      // Check if 'webinar_id' exists in the router query
      return "webinar_id" in this.$route.query;
    },
  },
  methods: {
    showModalNewSection() {
      this.showModal = true;
    },
    formData() {
      const formData = new FormData();
      formData.append("title", this.form.title);
      formData.append("status", this.form.status ? 1 : 0);
      formData.append("locale", this.form.locale);
      formData.append("type", this.form.type);
      formData.append("webinar_id", this.$route.query.webinar_id);
      return formData;
    },
    async createSession() {
      this.loadingCreateWebinar = true;
      await upload_courses_request
        .createWebinar(this.formData())
        .then((res) => {
          if (res.status === 200 && res.data.success === true) {
            this.sectionsList[Object.keys(this.sectionsList).length] =
              res.data.data;

            this.$swal.fire(res.data.message);
            this.showModal = false;
          }
        })
        .finally(() => {
          this.loadingCreateWebinar = false;
        });
      this.loadingCreateWebinar = false;
    },
    async getAllSession() {
      this.loadingCreateWebinar = true;
      if (this.webinar_id) {
        return await upload_courses_request.getAllSession(this.webinar_id);
      }
      this.loadingCreateWebinar = false;
    },
  },
  // created(){
  //   this.get
  // },
  mounted() {
    this.getAllSession()
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          this.sectionsList = res.data.data.chapters;
        }
      })
      .finally(() => {
        this.loadingCreateWebinar = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  margin-bottom: 20px;
  .custom-inputs {
    width: 100%;
    margin-top: 20px;
  }
  .title {
    margin-bottom: 0;
  }
  select {
    width: 100%;
    padding: 0.6rem;
    border-color: #4dbebd;
    outline: none;
    border-radius: 3px;
    border-radius: 10px;
  }
}
</style>
