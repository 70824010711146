import home_request from "@/api/home_request";
import store from "@/store";

export default {
  namespaced: true,
  state: () => ({
    hero_section: {},
    soon: {},
    instructors: {},
    takes: [],
    home_takes: [],
    take_offset: 0,
    take_count: 0,
    popular_courses: {},
    categories: {},
    webinars: {},
    offset: {},
  }),

  getters: {
    hero_section: (state) => {
      return state.hero_section;
    },
    soon: (state) => {
      return state.soon;
    },
    instructors: (state) => {
      return state.instructors;
    },
    takes: (state) => {
      return state.takes;
    },
    home_takes: (state) => {
      return state.home_takes;
    },
    take_offset: (state) => {
      return state.take_offset;
    },
    take_count: (state) => {
      return state.take_count;
    },
    popular_courses: (state) => {
      return state.popular_courses;
    },
    categories: (state) => {
      return state.categories;
    },
    webinars: (state) => {
      return state.webinars;
    },
    offset: (state) => {
      return state.offset;
    },
  },
  mutations: {
    SET_HERO_SECTIONS(state, payload) {
      if (payload != null) {
        state.hero_section = payload;
      }
    },
    SET_SOON(state, payload) {
      if (payload) {
        state.soon = payload;
      }
    },
    SET_INSTRUCTORS(state, payload) {
      if (payload) {
        state.instructors = payload;
      }
    },
    SET_TAKES(state, payload) {
      if (payload) {
        payload.forEach((element) => state.takes.push(element));
      }
    },
    SET_HOME_TAKES(state, payload) {
      if (payload) {
        state.home_takes = payload;
        //payload.forEach((element) => state.home_takes.push(element));
      }
    },
    SET_TAKE_OFFSET(state, payload) {
      if (payload) {
        state.take_offset = payload;
      }
    },
    SET_TAKE_COUNT(state, payload) {
      if (payload) {
        state.take_count = payload;
      }
    },
    SET_POPULAR_COURSE(state, payload) {
      if (payload) {
        state.popular_courses = payload;
      }
    },
    SET_CATEGORIES(state, payload) {
      if (payload) {
        state.categories = payload;
      }
    },
    SET_WEBINARS(state, payload) {
      if (payload) {
        if (!state.webinars[payload.key]) {
          state.webinars[payload.key] = [];
        }
        state.webinars[payload.key] = [];
        if (payload.loadedData) {
          payload.loadedData.forEach((element) => {
            state.webinars[payload.key].push(element);
          });
        }
      } else {
        state.webinars = {};
      }
    },
    SET_OFFSET(state, payload) {
      if (payload) {
        state.offset[payload.key] = payload.value;
      }
    },
  },
  actions: {
    async getHeroSection({ commit }) {
      await home_request.getHeroSections().then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_HERO_SECTIONS", response.data.data);
          } else {
            commit("SET_HERO_SECTIONS", null);
          }
        } else {
          commit("SET_HERO_SECTIONS", null);
        }
      });
    },
    async getSoon({ commit }) {
      await home_request.getSoon().then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_SOON", response.data.data);
          } else {
            commit("SET_SOON", null);
          }
        } else {
          commit("SET_SOON", null);
        }
      });
    },
    async getInstructors({ commit }) {
      await home_request.getInstructors().then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_INSTRUCTORS", response.data.data);
          } else {
            commit("SET_INSTRUCTORS", null);
          }
        } else {
          commit("SET_INSTRUCTORS", null);
        }
      });
    },
    async getTakes({ commit }) {
      await home_request
        .getTakes({
          offset: store.getters["home/take_offset"],
          limit: 10,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              commit("SET_TAKES", response.data.data.webinars);
              commit("SET_TAKE_COUNT", response.data.data.count);

              commit("SET_TAKE_OFFSET", store.getters["home/take_offset"] + 1);
            } else {
              commit("SET_TAKES", null);
            }
          } else {
            commit("SET_TAKES", null);
          }
        });
    },
    async getHomeTakes({ commit }) {
      await home_request
        .getTakes({
          offset: 0,
          limit: 10,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              commit("SET_HOME_TAKES", response.data.data.webinars);
            } else {
              commit("SET_HOME_TAKES", null);
            }
          } else {
            commit("SET_HOME_TAKES", null);
          }
        });
    },
    async getPopularCourses({ commit }) {
      await home_request.getPopularCourses().then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_POPULAR_COURSE", response.data.data);
          } else {
            commit("SET_POPULAR_COURSE", null);
          }
        } else {
          commit("SET_POPULAR_COURSE", null);
        }
      });
    },
    async getCategories({ commit }) {
      await home_request.getCategories().then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_CATEGORIES", response.data.data.categories);
          } else {
            commit("SET_CATEGORIES", null);
          }
        } else {
          commit("SET_CATEGORIES", null);
        }
      });
    },
    async getWebinars({ commit }, payload) {
      await home_request
        .getCategoryWebinar(payload.id, payload.offset, 10, payload.isLessData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              commit("SET_WEBINARS", {
                key: payload.id,
                loadedData: response.data.data.webinars,
              });
            } else {
              commit("SET_WEBINARS", null);
            }
          } else {
            commit("SET_WEBINARS", null);
          }
        });
    },
    async getAllWebinars({ commit }, offset = 0) {
      await home_request.getAllWebinar(offset, 10, true).then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_WEBINARS", {
              key: 0,
              loadedData: response.data.data,
            });
          } else {
            commit("SET_WEBINARS", null);
          }
        } else {
          commit("SET_WEBINARS", null);
        }
      });
    },
    resetData({ commit }) {
      commit("SET_WEBINARS", null);
      commit("SET_OFFSET", null);
    },
  },
};
