import axios from "axios";

export default {
  async fetchAllSales(_offset) {
    return await axios.get("panel/financial/sales/", {
      params: { offset: _offset },
    });
  },
  async fetchAllSummary(_offset) {
    return await axios.get("panel/financial/summary/", {
      params: { offset: _offset },
    });
  },
  async fetchAllPayout(_offset, _limit = 10) {
    return await axios.get("panel/financial/payout/", {
      params: { offset: _offset, limit: _limit },
    });
  },
};
