<template>
  <div>Update Profile</div>
</template>

<script>
export default {
  name: "UpdateProfileComponent",
};
</script>

<style scoped></style>
