<template>
  <div>
    <div v-if="is_meeting && isLogin">
      <router-link
        :to="{
          name: 'instructor',
          params: { id: this.id },
          query: { tab: 'consultation' },
        }"
      >
        <button class="btn-book" type="button">احجز</button>
      </router-link>
    </div>
    <div v-else>
      <button
        v-if="!local_is_follow || !isLogin"
        @click.prevent="Follow(id, !local_is_follow)"
        class="btn-follow btn btn-sm d-flex justify-content-center align-items-center px-2 py-1"
      >
        <div
          v-if="isLoading"
          class="spinner-grow spinner-grow-sm text-light"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="text-white btn-follow">متابعة</span>
      </button>

      <button
        v-else
        class="btn btn-sm bg-danger py-1 d-flex justify-content-center align-items-center px-2"
        @click.prevent="Follow(id, !local_is_follow)"
      >
        <div
          v-if="isLoading"
          class="spinner-grow spinner-grow-sm text-light"
          role="status"
        >
          <span class="visually-hidden">تحميل...</span>
        </div>

        <span class="text-white">إلغاء</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import instructor_request from "@/api/instractor_request";
import store from "@/store";
export default {
  name: "FollowButtonComponent",
  components: {},
  props: {
    id: Number,
    is_follow: Boolean,
    is_meeting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("auth", ["isLogin"]),
  },
  data() {
    return {
      isLoading: false,
      local_is_follow: this.is_follow,
    };
  },
  methods: {
    Follow: function (id, status) {
      let _this = this;
      this.isLoading = true;
      instructor_request
        .follow(id, status)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "unauthorized") {
              this.$root.$emit("onLogOut");
              this.$root.$emit("showAuthModel", true);
            } else if (response.data.success === true) {
              _this.local_is_follow = !_this.local_is_follow;
              this.$root.$emit("updateFollowInstructor", {
                count: response.data.data.count,
                id: id,
              });
            } else if (response.data.success === false) {
              if (response.data.status === "unauthorized") {
                store.commit("auth/SET_IS_LOGIN", false);
              }
              this.$swal({
                icon: "error",
                title: "انت غير متابع للمدرب",
              });
            }
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "حدث خطا لايمكن تنفيذ الامر",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$root.$on("onLogin", function () {
      this.$root.$emit("showAuthModel", false);
    });
  },
};
</script>

<style scoped>
.btn-follow {
  color: #fff !important;
  background-color: #430098 !important;
}
.dark-theme .btn-follow {
  background-color: #000 !important;
}
.btn-book {
  font-size: 12px;
  background-color: #47d4db;
  color: #fff;
  /* font-weight: bold; */
  padding: 5px 15px;
  border-radius: 3px;
}
</style>
