<template>
  <div
    style="direction: ltr"
    class="d-flex justify-content-end align-items-center"
  >
    <!-- <img
      width="22"
      src="../../assets/icon/cs-time.svg"
      alt="duration"
      style="visibility: visible"
    /> -->

    <div :class="[css_class, 'custom-class']">
      {{ duration | formatDurations }}
    </div>
  </div>
</template>

<script>
import filters from "@/helpers/filters_helper";

export default {
  name: "DurationComponent",
  props: {
    duration: { type: Number, default: 0 },
    css_class: { type: String, default: "text-white p-0 m-0 fs-12" },
  },
  filters: {
    formatDurations(value) {
      return filters.formatDurations(value);
    },
  },
};
</script>

<style scoped>
.custom-class {
  font-size: 12px;
  /* margin-inline: 15px; */
  color: #818894;
}
</style>
