<template>
  <div v-if="isLogin" class="nav-item dropdown position-relative position-none">
    <!-- web -->
    <div class="d-none d-lg-block mx-1">
      <a
        id="dropdownMenuButton1"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        class="d-flex justify-content-between align-items-center dropend"
        href="#"
      >
        <img
          v-if="user.avatar"
          :src="user.avatar"
          class="rounded-circle img-fluid mx-1"
          height="30"
          width="30"
        />
        <img
          v-else
          class="rounded-circle img-fluid"
          height="30"
          src="../../assets/avatar.png"
          width="30"
        />
        <span class="text-truncate user-nav-name">{{ user.full_name }}</span>
      </a>
      <ul
        aria-labelledby="dropdownMenuButton1"
        class="dropdown-menu bg-user-cpanel"
      >
        <li>
          <router-link
            href="#"
            class="w-100 px-2 d-flex justify-content-between align-items-center link-settings"
            to="/panel"
          >
            <span class="">لوحة التحكم</span>

            <img src="../../assets/icon/cs-cpanel.svg" class="svg" />
          </router-link>
        </li>

        <li class="dropdown-divider"></li>
        <li>
          <a
            class="w-100 px-2 d-flex justify-content-between align-items-center link-settings"
            href="#"
            @click.prevent="doLogOut"
          >
            <span class="">تسجيل خروج</span>
            <img src="../../assets/icon/cs-logout.svg" class="svg" />
          </a>
        </li>
      </ul>
    </div>
    <!--// web -->
    <!-- mobile -->
    <div class="d-block d-lg-none mx-2">
      <a
        id="dropdownMenuButton1"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        class="d-flex justify-content-between align-items-center"
        href="#"
      >
        <img
          v-if="user.avatar"
          :src="user.avatar"
          class="rounded-circle img-fluid"
          height="30"
          width="30"
        />
        <img
          v-else
          class="rounded-circle img-fluid"
          height="30"
          src="../../assets/avatar.png"
          width="30"
        />
      </a>
      <ul
        aria-labelledby="dropdownMenuButton1"
        class="dropdown-menu bg-user-cpanel user-cpanel"
      >
        <li>
          <span class="text-truncate mx-2 user-nav-name">{{
            user.full_name
          }}</span>
        </li>
        <li class="dropdown-divider"></li>
        <li>
          <router-link
            href="#"
            class="w-100 px-2 d-flex justify-content-between align-items-center link-settings"
            to="/panel"
          >
            <span class="">لوحة التحكم</span>

            <img src="../../assets/icon/cs-cpanel.svg" class="svg" />
          </router-link>
        </li>

        <li class="dropdown-divider"></li>
        <li>
          <a
            class="w-100 px-2 d-flex justify-content-between align-items-center link-settings"
            href="#"
            @click.prevent="doLogOut"
          >
            <span class="">تسجيل خروج</span>
            <img src="../../assets/icon/cs-logout.svg" class="svg" />
          </a>
        </li>
      </ul>
    </div>
    <!--// mobile -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavUserPanel",
  computed: {
    ...mapGetters("auth", ["isLogin", "user", "access_token"]),
  },
  methods: {
    ...mapActions("auth", ["logOut", "getUser"]),
    // doLogOut() {
    //   this.logOut();
    //   this.isLogin = false;

    //   if (this.$route.path === "/panel") {
    //     this.$router.push("/");
    //     window.location.reload();
    //   }
    //   window.location.reload();
    // },
    doLogOut() {
      this.logOut();
      // this.isLogin = false;
      this.$router.push({ path: "/" }).catch(() => {});
      window.location.reload();
      // if (this.$route.path !== "/panel") {
      //   this.$router.push({ name: "home" }).catch(() => {});
      // } else if (this.$route.path !== "/") {
      //   this.$router.push({ name: "home" }).catch(() => {});
      // }
    },
  },
  created() {
    this.getUser(this.access_token);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.user-nav-name {
  max-width: 120px;
  color: #000;
}
.dark-theme .user-nav-name {
  color: #fff !important;
}
.dark-theme .link-settings {
  color: #fff !important;
}
.link-settings::before {
  display: none !important;
}
.bg-user-cpanel {
  background-color: #fff;
}
.dark-theme .bg-user-cpanel {
  background-color: #2f2f2f;
  color: #fff;
}
.dropdown-menu::after {
  background-color: var(--nav-dropdown-bg) !important;
}
.dropdown-menu.bg-user-cpanel {
  top: 106% !important;
  right: auto !important;
  left: 17px !important;
  max-width: 222px !important;
}
@media only screen and (max-width: 991px) {
  .dropdown-menu.bg-user-cpanel {
    top: 106% !important;
    right: auto !important;
    left: 10px !important;
    width: 222px !important;
  }
  .user-cpanel[data-v-a2db7baa]::after {
    left: 1%;
    display: none;
  }
}
.dropdown-menu::after {
  background-color: var(--nav-dropdown-bg) !important;
}
@media only screen and (max-width: 600px) {
  .user-cpanel {
    max-width: 100%;
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
  .dropdown-menu.bg-user-cpanel {
    top: 75% !important;
    right: auto !important;
    left: 7px !important;
    width: 222px !important;
  }
  .user-cpanel[data-v-a2db7baa]::after {
    left: 1%;
  }
  .position-none {
    position: inherit !important;
  }
  .user-cpanel::after {
    left: 7%;
  }
}
</style>
