import instructor_request from "@/api/instractor_request";

export default {
  namespaced: true,
  state: () => ({
    instructor_list: [],
    links: 0,
    lastpage: 1,
    isLoadData: false,
    instructor_details: [],
    category: [],
  }),
  getters: {
    instructor_list: (state) => {
      return [].concat.apply([], state.instructor_list);
    },
    links: (state) => {
      return state.links;
    },
    lastpage: (state) => {
      return state.lastpage;
    },
    isLoadData: (state) => {
      return state.isLoadData;
    },
    instructor_details: (state) => {
      return state.instructor_details;
    },
    category: (state) => {
      return state.category;
    },
  },
  mutations: {
    SET_LIST: (state, payload) => {
      if (payload.length > 0) {
        state.instructor_list.push(payload);
      }
    },
    RESET_SET_LIST: (state, payload) => {
      state.instructor_list = payload;
    },
    SET_LINK: (state, payload) => {
      state.links = payload;
    },
    SET_LAST_PAGE: (state, payload) => {
      state.lastpage = payload;
    },
    SET_LOADING_DATA: (state, payload) => {
      state.isLoadData = payload;
    },
    SET_CATEGORY: (state, payload) => {
      if (payload) {
        state.category.push(payload);
      } else {
        state.category = [];
      }
    },
    SET_INSTRUCTOR_DETAILS: (state, payload) => {
      state.instructor_details = payload;
    },
  },
  actions: {
    async getAllInstructor({ commit }, p) {
      let pag = p.page ?? 1;
      let category = p.category ?? [];
      let last_page = p.last_page ?? 1;
      if (pag < last_page) {
        commit("SET_LOADING_DATA", true);
        await instructor_request.fetchAll(pag, category).then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_LIST", response.data.data.users);
            commit("SET_LAST_PAGE", response.data.data.links.last_page);
            if (pag < response.data.data.links.last_page)
              commit("SET_LINK", response.data.data.links.current_page);
          }
          commit("SET_LOADING_DATA", false);
        });
      }
    },
    async GetInstructor({ commit }, id) {
      commit("SET_LOADING_DATA", true);
      await instructor_request.GetInstructor(id).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          commit("SET_INSTRUCTOR_DETAILS", response.data.data.user);
          commit("SET_LOADING_DATA", false);
        } else {
          commit("SET_LOADING_DATA", false);
        }
      });
    },
  },
};
