<template>
  <div class="new-session">
    <div class="session-head">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: #818894"
      >
        <path
          d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"
        ></path>
      </svg>
      <p>إضافة دورة مسجلة</p>
    </div>
    <div class="session-content">
      <div class="input-groups">
        <p class="title">اللغة</p>
        <b-form-select
          v-model="fileGroup.locale"
          :options="lang"
        ></b-form-select>
      </div>

      <div class="mt-3">
        <GlobalInput type="text" label="العنوان" v-model="fileGroup.title" />
      </div>
      <div class="mt-3">
        <GlobalInput type="text" label="الرابط" v-model="fileGroup.link" />
      </div>
      <div class="mt-3">
        <GlobalInput
          type="text"
          label="moderator_secret"
          v-model="fileGroup.moderator_secret"
        />
      </div>
      <div class="mt-3">
        <GlobalInput type="number" label="المدة" v-model="fileGroup.duration" />
      </div>
      <div class="mt-3">
        <p>الوصف</p>
        <b-textarea v-model="fileGroup.description" />
      </div>

      <p>تاريخ البدء</p>
      <v-date-picker
        v-model="fileGroup.date"
        mode="date"
        is24hr
        :format="dateTimeFormat"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </v-date-picker>
      <div class="d-flex mt-4 gap-2 align-items-center">
        <p>تفعيل</p>
        <label class="custom-switch-container">
          <input
            v-model="fileGroup.status"
            type="checkbox"
            class="custom-control-success"
            name="check-button"
            switch
            :unchecked-value="0"
            :value="0"
          />
          <span class="custom-switch-slider"> </span>
        </label>
      </div>

      <div class="d-flex mt-4 gap-2 align-items-center">
        <p>agora_chat</p>
        <label class="custom-switch-container">
          <input
            v-model="fileGroup.agora_chat"
            type="checkbox"
            class="custom-control-success"
            name="check-button"
            switch
            :unchecked-value="0"
            :value="0"
          />
          <span class="custom-switch-slider"> </span>
        </label>
      </div>
      <div class="d-flex mt-4 gap-2 align-items-center">
        <p>agora_record</p>
        <label class="custom-switch-container">
          <input
            v-model="fileGroup.agora_record"
            type="checkbox"
            class="custom-control-success"
            name="check-button"
            switch
            :unchecked-value="0"
            :value="0"
          />
          <span class="custom-switch-slider"> </span>
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3">
      <b-button class="btn btn-coursestore-primary mt-4" @click="storeSession()"
        >حفظ
        <small-spring-loading :is-loading="loadingStore"></small-spring-loading>
      </b-button>
      <b-button
        class="btn btn-coursestore-primary mt-4"
        @click="updateSession()"
        >تعديل
        <small-spring-loading
          :is-loading="loadingUpdateStore"
        ></small-spring-loading>
      </b-button>
      <b-button
        class="btn btn-coursestore-primary mt-4"
        @click="deleteSession()"
        >حذف
        <small-spring-loading
          :is-loading="loadingDeleteStore"
        ></small-spring-loading>
      </b-button>
    </div>
  </div>
</template>

<script>
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import upload_courses_request from "@/api/upload_courses_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import Vue from "vue";
export default {
  components: {
    GlobalInput,
    SmallSpringLoading,
  },
  props: {
    chapterId: { type: [Number, null] },
  },
  name: "new-session",
  data() {
    return {
      lang: ["ar"],
      storage: ["local", "online"],
      file_type: [
        "pdf",
        "sound",
        "video",
        "image",
        "archive",
        "document",
        "project",
      ],
      fileGroup: {
        locale: "",
        title: "",
        description: "",
        status: 0,
        agora_chat: 0,
        agora_record: 0,
        duration: 0,
        date: new Date(),
        moderator_secret: "",
        session_api: "agora",
        link: "",
        chapterId: this.chapterId,
      },
      loadingStore: false,
      loadingUpdateStore: false,
      loadingDeleteStore: false,
      fileSessionId: Number,
    };
  },
  computed: {
    dateTimeFormat() {
      const year = this.fileGroup.date.getFullYear();
      const month = String(this.fileGroup.date.getMonth() + 1).padStart(2, "0");
      const day = String(this.fileGroup.date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    formData() {
      const formData = new FormData();
      formData.append("webinar_id", this.$route.query.webinar_id);
      formData.append("chapter_id", this.fileGroup.chapterId);
      formData.append("status", this.fileGroup.status ? 1 : 0);
      formData.append("agora_chat", this.fileGroup.agora_chat ? 1 : 0);
      formData.append("agora_record", this.fileGroup.agora_record ? 1 : 0);
      formData.append("locale", this.fileGroup.locale);
      formData.append("duration", this.fileGroup.duration);
      formData.append("date", this.dateTimeFormat);
      formData.append("moderator_secret", this.fileGroup.moderator_secret);
      formData.append("title", this.fileGroup.title);
      formData.append("link", this.fileGroup.link);
      formData.append("description", this.fileGroup.description);
      formData.append("session_api", this.fileGroup.session_api);

      return formData;
    },

    async storeSession() {
      this.loadingStore = true;
      await upload_courses_request.storeSession(this.formData()).then((res) => {
        this.fileSessionId = res.data.data.id;
        Vue.swal.fire(res.data.message);
      });
      this.loadingStore = false;
    },

    async updateSession() {
      if (this.fileSessionId) {
        this.loadingUpdateStore = true;
        await upload_courses_request
          .updateSession(this.fileSessionId, this.formData())
          .then((res) => {
            Vue.swal.fire(res.data.message);
          })
          .catch(() => {
            Vue.swal.fire("خطاء فى التعديل");
          });
        this.loadingUpdateStore = false;
      } else {
        Vue.swal.fire("يرجى اضافة session");
      }
    },
    async deleteSession() {
      if (this.fileSessionId) {
        this.loadingDeleteStore = true;
        await upload_courses_request
          .deleteSession(this.fileSessionId)
          .then(() => {
            this.$swal({
              title: "هل تريد الحذف",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "تأكيد",
              cancelButtonText: "إلغاء",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
            });
          });
      }
      this.loadingDeleteStore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-session {
  background-color: #fff;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  .session-head {
    display: flex;
    gap: 5px;
    padding: 5px;
    p {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }
  }
  .session-content {
    max-width: 800px;
    input,
    select,
    b-textarea {
      width: 100%;
      border: 1px solid #ddd;
      padding: 5px;
      outline: none;
      border-radius: 4px;
    }
  }
  .title {
    display: block;
  }

  // radio button
}
</style>
